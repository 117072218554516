<template>
    <div class="padding-container">
        <div class="box">
            <h5 class="fw-bold">Penugasan Driver</h5>
            <div class="d-flex align-items-center no-flex mt-4">
                <div class="me-4 m-mx-0 m-mt-2">
                    <div class="position-relative">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search-leads border-search"
                                placeholder="Cari CRM Specialist"
                                v-model="search"
                            />
                            <button
                                class="btn btn-primary px-4"
                                type="button"
                                @click="searchData()"
                            >
                                Cari
                            </button>
                        </div>
                        <span class="search-icon left">
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                </div>
                <div class="m-mx-0 m-mt-2 me-3">
                    <button
                        class="btn btn-outline-black btn-filter px-4"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
                <div class="ms-auto m-mx-0 m-mt-2">
                    <button
                        class="btn btn-primary btn-with-search px-4"
                        @click="assignModal = true"
                        :disabled="valueCheckBox.length == 0"
                    >
                        Tugaskan Driver
                    </button>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-md-4 mb-2">
                        <label class="fw-bold mb-1">Tanggal Request</label>
                        <date-picker
                            v-model="date"
                            type="date"
                            value-type="format"
                            class="w-100"
                            format="DD MMMM YYYY"
                            :editable="false"
                            placeholder="Pilih tanggal"
                        >
                        </date-picker>
                    </div>
                    <div class="col-md-4 mb-2">
                        <label class="fw-bold mb-1">CRM Specialist</label>
                        <multiselect
                            v-model="specialist"
                            :options="specialists"
                            label="name"
                            track-by="id"
                            placeholder="Pilih CRM specialist"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-4">
                        <label class="fw-bold mb-1">Tags</label>
                        <multiselect
                            v-model="tag"
                            :options="tags"
                            multiple
                            placeholder="Pilih tags"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-8 mb-2">
                        <label class="fw-bold mb-1">Rekomendasi Armada </label>
                        <div class="row">
                            <div class="col-md-6">
                                <multiselect
                                    v-model="fleet_recommend"
                                    :options="fleet_recommends"
                                    label="name"
                                    track-by="code"
                                    placeholder="Pilih rekomendasi armada"
                                    class="multi-form-custom"
                                    :show-labels="false"
                                >
                                </multiselect>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-6">
                                        <button
                                            class="btn btn-outline-yellow px-5 me-3"
                                            @click="clearFilter()"
                                            :disabled="
                                                date == '' &&
                                                fleet_recommend == null &&
                                                specialist == null &&
                                                tag == null
                                            "
                                        >
                                            Clear
                                        </button>
                                    </div>
                                    <div class="col-md-6">
                                        <button
                                            class="btn btn-green px-5 me-3"
                                            @click="filterDate()"
                                            :disabled="
                                                date == '' &&
                                                fleet_recommend == null &&
                                                specialist == null &&
                                                tag == null
                                            "
                                        >
                                            Filter
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center mt-4">
                <div>
                    Menampilkan
                    <span class="fw-bold">{{ lists.length }}</span>
                    Data
                </div>
            </div>
            <div class="mt-4" v-if="is_my_list_ready">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        v-model="selectAllLeads"
                                        id="allCheck"
                                    />
                                    <label
                                        class="form-check-label"
                                        for="allCheck"
                                    ></label>
                                </th>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Tanggal Request
                                </th>
                                <th class="text-center" scope="col">
                                    Nama Customer
                                </th>
                                <th class="text-center" scope="col">
                                    CRM Specialist
                                </th>
                                <th class="text-center" scope="col">
                                    Daerah Penjemputan
                                </th>
                                <th class="text-center" scope="col">
                                    Estimasi Berat Barang
                                </th>
                                <th class="text-center" scope="col">
                                    Deskripsi Barang
                                </th>
                                <th class="text-center" scope="col">Tags</th>
                                <th class="text-center" scope="col">
                                    Rekomendasi Armada
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in lists" :key="index">
                                <td class="text-center">
                                    <b-form-group v-slot="{ ariaDescribedby }">
                                        <b-form-checkbox-group
                                            id="checkbox-group-2"
                                            v-model="valueCheckBox"
                                            :aria-describedby="ariaDescribedby"
                                            name="flavour-2"
                                        >
                                            <b-form-checkbox
                                                :value="item"
                                            ></b-form-checkbox>
                                        </b-form-checkbox-group>
                                    </b-form-group>
                                </td>
                                <td class="text-center">
                                    {{ index + 1 }}
                                </td>
                                <td>{{ item.requested_at }}</td>
                                <td>{{ item.customer_name }}</td>
                                <td>
                                    {{
                                        item.specialist ? item.specialist : "-"
                                    }}
                                </td>
                                <td>
                                    {{ item.address ? item.address : "-" }}
                                </td>
                                <td class="text-center">{{ item.weight }}Kg</td>
                                <td>
                                    {{
                                        item.description
                                            ? item.description
                                            : "-"
                                    }}
                                </td>
                                <td>
                                    <span
                                        class="tag gray-new mb-0"
                                        v-for="(tag, index) in item.tags"
                                        :key="index"
                                    >
                                        {{ tag }}
                                    </span>
                                </td>
                                <td class="capitalize">
                                    {{
                                        item.recommendation_fleet
                                            .split("-")
                                            .join(" ")
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-if="lists.length == 0" class="text-center mt-section">
                    <img
                        src="../../assets/no-data-green.png"
                        width="100"
                        alt=""
                    />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada daftar penugasan driver
                    </div>
                </div>
            </div>
        </div>
        <!-- MODAL -->
        <div class="modal-vue">
            <div
                class="overlay"
                v-if="assignModal"
                @click="assignModal = false"
            ></div>
            <div class="modal-body-chat sm" v-if="assignModal">
                <div class="mb-3">
                    <h5 class="fw-bold">Pilih Driver</h5>
                </div>
                <div class="position-relative mt-18 mb-35">
                    <input
                        class="form-control form-control-lg pl-search w-100"
                        v-model="searchFleet"
                        type="text"
                        placeholder="Cari dan pilih driver"
                    />
                    <span class="search-icon left">
                        <i class="pi pi-search fa-beat fa-sm"></i>
                    </span>
                </div>
                <div
                    class="mb-2 mt-3"
                    v-bind:class="{
                        scroll: filteredOptions.length > 6,
                    }"
                >
                    <template v-if="filteredOptions.length > 0">
                        <div class="box p-2">
                            <div
                                v-for="(option, index) in filteredOptions"
                                :key="index"
                                class="radio-option fw-medium capitalize"
                            >
                                <label :for="option.driver_fleet_id">
                                    <input
                                        type="radio"
                                        :id="option.driver_fleet_id"
                                        :value="option.driver_fleet_id"
                                        v-model="selectedFleet"
                                    />
                                    <span class="text mt-1">
                                        <div class="ms-3">
                                            <span class="fw-bold">{{
                                                option.driver
                                            }}</span>
                                            |
                                            <span class="fw-normal">{{
                                                option.fleet
                                            }}</span>
                                            <div class="text-gray">
                                                {{ option.pickup_count }}
                                                Orderan
                                            </div>
                                        </div>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="text-center mt-4">
                            <img
                                src="../../assets/no-data-green.png"
                                width="60"
                                alt=""
                            />
                            <div class="mt-2 mb-4 text-gray">
                                Belum ada daftar driver
                            </div>
                        </div>
                    </template>
                </div>
                <div class="d-flex mt-4">
                    <div class="ms-auto">
                        <div class="d-flex">
                            <button
                                class="btn btn-primary btn-with-search px-4"
                                :disabled="selectedFleet == null"
                                @click="
                                    (modalAssignConfirmation = true),
                                        (assignModal = false)
                                "
                            >
                                Tugaskan Driver
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalAssignConfirmation"></div>
            <div class="modal-body-chat vsm" v-if="modalAssignConfirmation">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">Driver Akan Ditugaskan</h5>
                    <div class="text-gray-dashboard">
                        Pastikan data orderan dan driver yang dipilih sudah
                        benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalAssignConfirmation = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="storeAssign()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_assign"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_assign"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Driver Berhasil Ditugaskan
                    </h5>
                </div>
                <div class="mt-4">
                    <a
                        href="/assignment/driver"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
    components: {
        ListLoader,
        DatePicker,
        Multiselect,
    },
    name: "AssignmentDriver",
    data() {
        return {
            moment: moment,
            search: "",
            is_assign: false,
            is_my_list_ready: false,
            valueCheckBox: [],
            selectedFleet: null,
            searchFleet: "",
            isFilter: false,
            assignModal: false,
            modalSuccess: false,
            modalAssignConfirmation: false,
            lists: [],
            fleet: null,
            fleets: [],
            date: "",
            result_value: [],
            specialist: null,
            specialists: [],
            tag: null,
            tags: [],
            fleet_recommend: null,
            fleet_recommends: [],
        };
    },
    created() {
        this.getLists();
        this.getFleets();
        this.getFleetRecommend();
        this.getSpecialists();
        this.getTags();
    },
    methods: {
        filterDate() {
            this.getLists();
        },
        clearFilter() {
            this.date = "";
            this.fleet_recommend = null;
            this.specialist = null;
            this.tag = null;
            this.getLists();
        },
        searchData() {
            this.getLists();
            this.currentPage = 0;
        },
        getLists() {
            let payload = {
                search: this.search,
            };
            if (this.date) {
                payload.date_request = moment(this.date).format("YYYY-MM-DD");
            }
            if (this.fleet) {
                payload.fleet_type = this.fleet?.code;
            }
            if (this.tag) {
                payload.tags = this.tag;
            }
            if (this.specialist) {
                payload.specialist_id = this.specialist?.id;
            }
            if (this.fleet_recommend) {
                payload.fleet = this.fleet_recommend?.code;
            }
            this.is_my_list_ready = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/pickup/assignee`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.lists = res.data.data;
                    this.is_my_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_my_list_ready = false;
                });
        },
        getFleets() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/driver_fleet`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.fleets = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getFleetRecommend() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/fleet_type`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.fleet_recommends = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        storeAssign() {
            this.valueCheckBox.forEach((val) => {
                this.result_value.push(val.result_id);
            });
            let data = {
                driver_id: this.selectedFleet,
                result_id: this.result_value,
            };
            this.is_assign = true;
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/pickup/assignee`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.is_assign = false;
                    this.modalSuccess = true;
                    this.modalAssignConfirmation = false;
                    this.valueCheckBox.length = 0;
                    this.getLeadsData();
                })
                .catch((err) => {
                    this.is_assign = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getSpecialists() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/user`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.specialists = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getTags() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/tag`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.tags = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    computed: {
        filteredOptions() {
            if (this.searchFleet) {
                const regex = new RegExp(this.searchFleet, "i");
                return this.fleets.filter(
                    (option) =>
                        option.driver.match(regex) || option.fleet.match(regex)
                );
            }
            return this.fleets;
        },
        selectAllLeads: {
            get() {
                return this.valueCheckBox.length === this.lists.length;
            },
            set(value) {
                this.valueCheckBox = [];

                if (value) {
                    this.lists.forEach((select) => {
                        this.valueCheckBox.push(select);
                    });
                }
            },
        },
    },
};
</script>
<style lang="scss" scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.radio-option label {
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    cursor: pointer;
}
.radio-option label .text {
    font-size: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    color: #000;
    border-bottom: 1px solid #f3f4f6;
    padding-bottom: 15px;
}
.radio-option label input {
    display: none;
}
.radio-option label input + .text::before {
    font-family: FontAwesome;
    content: "\f1db";
    line-height: 1;
    display: inline-block;
    color: #f3f4f6;
    font-size: 25px;
}
.radio-option label input:checked + .text::before {
    font-family: FontAwesome;
    content: "\f192";
    color: #40bf76;
}
.scroll {
    height: 320px;
    overflow-y: scroll;
}
</style>
