<template>
    <div class="date-range-picker" ref="dateRangePicker">
        <button
            v-if="!showFilterButton"
            class="btn btn-outline-yellow d-flex align-items-center"
            @click="handleButtonClick"
            :class="{ disabled: disabled }"
            :disabled="disabled"
        >
            <img
                src="../assets/calendar-dashboard.svg"
                alt="calendar dashboard"
                class="me-2"
            />Filter Tanggal
        </button>
        <button
            v-else
            class="btn btn-filter-date d-flex align-items-center text-white"
            @click="handleButtonClick"
            :class="{ disabled: disabled }"
            :disabled="disabled"
        >
            <img
                src="../assets/calendar-active-dashboard.svg"
                alt="calendar active dashboard"
                class="me-2"
            />Filter Tanggal
        </button>
        <div v-if="isOpen" class="date-picker-popup card">
            <div class="card-body">
                <div class="calendar-container rounded">
                    <div>
                        <div
                            class="d-flex justify-content-between align-items-center mb-4"
                        >
                            <svg
                                @click="previousMonth()"
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="10"
                                viewBox="0 0 6 10"
                                fill="none"
                                class="cursor-pointer"
                            >
                                <path
                                    d="M4.83869 9.09438L0.775391 5.03605L4.83869 0.977722"
                                    stroke="#1F1E2C"
                                    stroke-width="1.3536"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <h6 class="current-month-year mb-0 fw-bold">
                                {{ currentViewTitle }}
                            </h6>
                            <svg
                                @click="nextMonth()"
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="10"
                                viewBox="0 0 6 10"
                                fill="none"
                                class="cursor-pointer"
                            >
                                <path
                                    d="M0.793213 9.09432L4.85651 5.03599L0.793213 0.977661"
                                    stroke="#1F1E2C"
                                    stroke-width="1.3536"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    </div>
                    <div class="calendar-grid">
                        <div class="calendar-header d-flex">
                            <div
                                v-for="day in [
                                    'Min',
                                    'Sen',
                                    'Sel',
                                    'Rab',
                                    'Kam',
                                    'Jum',
                                    'Sab',
                                ]"
                                :key="day"
                                class="calendar-cell text-center"
                            >
                                {{ day }}
                            </div>
                        </div>
                        <div class="calendar-body">
                            <div
                                class="d-flex"
                                v-for="(week, weekIndex) in calendarDays"
                                :key="weekIndex"
                            >
                                <div
                                    v-for="{
                                        date,
                                        isCurrentMonth,
                                        isSelected,
                                        isInRange,
                                    } in week"
                                    :key="date.toISOString()"
                                    class="calendar-cell text-center"
                                    :class="{
                                        'text-muted': !isCurrentMonth,
                                        selected: isSelected,
                                        'in-range': isInRange,
                                        disabled: !isCurrentMonth,
                                    }"
                                    @click="handleDateClick(date)"
                                >
                                    {{ date.getDate() }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3 justify-content-end">
                        <div class="col-5">
                            <button
                                class="btn btn-outline-yellow w-100"
                                @click="handleReset()"
                                :disabled="!isResetEnabled"
                            >
                                Reset
                            </button>
                        </div>
                        <div class="col-5">
                            <button
                                class="btn btn-green w-100"
                                @click="handleFilter()"
                                :disabled="!isFilterEnabled"
                            >
                                Filter
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "DateRangePicker",
    props: {
        value: {
            type: Object,
            default: () => ({
                from: null,
                to: null,
            }),
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        onFilter: {
            type: Function,
            default: null,
        },
        onReset: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            hasFilteredOnce: false,
            isOpen: false,
            currentDate: new Date(),
            selectedDateRange: {
                from: this.value.from,
                to: this.value.to,
            },
            tempDateRange: {
                from: this.value.from,
                to: this.value.to,
            },
            isFirstClick: true,
            currentMonth: new Date().getMonth(),
            currentYear: new Date().getFullYear(),
        };
    },
    watch: {
        value: {
            handler(newValue) {
                this.selectedDateRange = {
                    from: newValue.from,
                    to: newValue.to,
                };
                this.tempDateRange = {
                    from: newValue.from,
                    to: newValue.to,
                };
            },
            deep: true,
        },
    },
    mounted() {
        document.addEventListener("click", this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.handleClickOutside);
    },
    computed: {
        showFilterButton() {
            return (
                this.hasFilteredOnce &&
                this.selectedDateRange.from !== null &&
                this.selectedDateRange.to !== null
            );
        },
        calendarDays() {
            const days = this.getMonthDays(
                new Date(this.currentYear, this.currentMonth)
            );
            const weeks = [];
            for (let i = 0; i < days.length; i += 7) {
                weeks.push(days.slice(i, i + 7));
            }
            return weeks;
        },
        currentViewTitle() {
            return this.formatMonthYear(
                new Date(this.currentYear, this.currentMonth)
            );
        },
        isResetEnabled() {
            return (
                this.tempDateRange.from !== null ||
                this.tempDateRange.to !== null
            );
        },
        isFilterEnabled() {
            return (
                this.tempDateRange.from !== null &&
                this.tempDateRange.to !== null
            );
        },
    },
    methods: {
        handleButtonClick() {
            if (!this.disabled) {
                this.isOpen = !this.isOpen;
            }
        },
        handleClickOutside(event) {
            const dateRangePicker = this.$refs.dateRangePicker;
            if (
                dateRangePicker &&
                !dateRangePicker.contains(event.target) &&
                this.isOpen
            ) {
                this.isOpen = false;
                this.tempDateRange = { ...this.selectedDateRange };
            }
        },
        formatMonthYear(date) {
            const monthNames = [
                "Januari",
                "Februari",
                "Maret",
                "April",
                "Mei",
                "Juni",
                "Juli",
                "Agustus",
                "September",
                "Oktober",
                "November",
                "Desember",
            ];
            return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
        },
        getMonthDays(date) {
            const year = date.getFullYear();
            const month = date.getMonth();
            const firstDay = new Date(year, month, 1);
            const lastDay = new Date(year, month + 1, 0);
            const days = [];
            const startPadding = firstDay.getDay();
            for (let i = startPadding - 1; i >= 0; i--) {
                const prevDate = new Date(year, month, -i);
                days.push({
                    date: prevDate,
                    isCurrentMonth: false,
                    isSelected: this.isDateSelected(prevDate),
                    isInRange: this.isDateInRange(prevDate),
                });
            }
            for (let i = 1; i <= lastDay.getDate(); i++) {
                const currentDate = new Date(year, month, i);
                days.push({
                    date: currentDate,
                    isCurrentMonth: true,
                    isSelected: this.isDateSelected(currentDate),
                    isInRange: this.isDateInRange(currentDate),
                });
            }
            const remainingDays = 42 - days.length;
            for (let i = 1; i <= remainingDays; i++) {
                const nextDate = new Date(year, month + 1, i);
                days.push({
                    date: nextDate,
                    isCurrentMonth: false,
                    isSelected: this.isDateSelected(nextDate),
                    isInRange: this.isDateInRange(nextDate),
                });
            }
            return days;
        },
        isDateSelected(date) {
            return (
                this.tempDateRange.from?.toDateString() ===
                    date.toDateString() ||
                this.tempDateRange.to?.toDateString() === date.toDateString()
            );
        },
        isDateInRange(date) {
            if (!this.tempDateRange.from || !this.tempDateRange.to)
                return false;
            return (
                date >= this.tempDateRange.from && date <= this.tempDateRange.to
            );
        },
        handleDateClick(date) {
            if (this.isFirstClick) {
                this.tempDateRange = {
                    from: date,
                    to: date,
                };
                this.isFirstClick = false;
            } else {
                if (date < this.tempDateRange.from) {
                    this.tempDateRange = {
                        from: date,
                        to: this.tempDateRange.from,
                    };
                } else {
                    this.tempDateRange = {
                        from: this.tempDateRange.from,
                        to: date,
                    };
                }
                this.isFirstClick = true;
            }
        },
        handleFilter() {
            this.isOpen = false;
            this.hasFilteredOnce = true;
            this.selectedDateRange = { ...this.tempDateRange };
            this.$emit("input", this.selectedDateRange);
            if (this.onFilter && typeof this.onFilter === "function") {
                this.onFilter(this.selectedDateRange);
            }
        },

        handleReset() {
            const today = new Date();
            this.tempDateRange = { from: null, to: null };
            this.selectedDateRange = { from: null, to: null };
            this.currentMonth = today.getMonth();
            this.currentYear = today.getFullYear();
            this.isFirstClick = true;
            this.hasFilteredOnce = false;
            this.$emit("input", this.selectedDateRange);
            this.isOpen = false;
            if (this.onReset && typeof this.onReset === "function") {
                this.onReset(this.selectedDateRange);
            }
        },
        previousMonth() {
            if (this.currentMonth === 0) {
                this.currentMonth = 11;
                this.currentYear--;
            } else {
                this.currentMonth--;
            }
        },
        nextMonth() {
            if (this.currentMonth === 11) {
                this.currentMonth = 0;
                this.currentYear++;
            } else {
                this.currentMonth++;
            }
        },
    },
};
</script>
<style scoped>
.btn-filter-date {
    background-color: #40bf76;
}
.btn-filter-date:hover {
    background-color: #40bf76 !important;
}
.btn-filter-date:focus {
    background-color: #40bf76 !important;
}
.btn-outline-yellow {
    background-color: transparent !important;
}
.date-range-picker {
    position: relative;
}
.date-picker-popup {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1000;
    width: 28rem;
    margin-top: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
@media screen and (max-width: 768px) {
    .date-picker-popup {
        width: 24rem;
        max-width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .card-body {
        min-width: 24rem;
        padding-bottom: 1rem;
    }
}
.calendar-container {
    background: white;
    padding: 1rem;
}
.calendar-cell {
    width: calc(100% / 7);
    padding: 0.5rem;
    cursor: pointer;
}
.calendar-header .calendar-cell {
    font-weight: bold;
    cursor: default;
}
.calendar-cell.selected {
    background-color: #1291ff;
    color: white !important;
}
.calendar-cell.in-range {
    background-color: #cce5ff;
}
.calendar-cell.selected.in-range {
    background-color: #1291ff;
    color: #ffffff;
}
.calendar-cell.disabled {
    opacity: 0.5;
}
.btn-outline-yellow {
    color: #1291ff;
    background-color: transparent;
    border: 1px solid #1291ff;
}
.btn-outline-yellow:hover {
    color: #1291ff;
    background-color: transparent;
    border: 1px solid #1291ff;
}
.btn-green {
    background-color: #1291ff !important;
    color: white;
    border: 1px solid #1291ff !important;
}
.btn-green:disabled {
    background-color: #e0e6eb !important;
    border-color: #e0e6eb !important;
    color: #849bae !important;
}
.cursor-pointer {
    cursor: pointer;
}
</style>
