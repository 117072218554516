<template>
    <div class="line-chart-container">
        <div class="chart-wrapper">
            <div class="chart-area" ref="chartArea">
                <div class="total-sales" :style="{ fontSize: labelFontSize }">
                    Total Penjualan
                </div>
                <div class="y-axis">
                    <div
                        v-for="value in yAxisLabels"
                        :key="value"
                        class="y-label"
                        :style="{ fontSize: labelFontSize }"
                    >
                        {{ formatCurrency(value) }}
                    </div>
                </div>
                <svg :width="chartWidth" :height="chartHeight" class="chart">
                    <defs>
                        <linearGradient
                            id="areaGradient"
                            x1="0"
                            x2="0"
                            y1="0"
                            y2="1"
                        >
                            <stop
                                offset="0.101"
                                stop-color="#66B8FF"
                                stop-opacity="0.3"
                            />
                            <stop
                                offset="0.9384"
                                stop-color="#66B8FF"
                                stop-opacity="0"
                            />
                        </linearGradient>
                    </defs>
                    <g class="grid-lines">
                        <line
                            v-for="value in yAxisLabels"
                            :key="'grid-' + value"
                            :x1="padding.left"
                            :x2="chartWidth - padding.right"
                            :y1="getYPosition(value)"
                            :y2="getYPosition(value)"
                            stroke="#F3F5F7"
                            stroke-width="1"
                        />
                    </g>
                    <path :d="areaPath" fill="url(#areaGradient)" />
                    <path
                        :d="smoothPath"
                        fill="none"
                        stroke="#1291FF"
                        :stroke-width="strokeWidth"
                    />
                    <line
                        v-if="hoverLine.visible"
                        :x1="hoverLine.x"
                        :x2="hoverLine.x"
                        :y1="padding.top"
                        :y2="chartHeight - padding.bottom"
                        stroke="#1291FF"
                        :stroke-width="strokeWidth"
                    />
                    <g
                        v-for="(point, index) in dataPoints"
                        :key="'point-' + index"
                        @mouseover="showTooltip(point, $event)"
                        @mouseout="hideTooltip"
                    >
                        <circle
                            :cx="point.x"
                            :cy="point.y"
                            :r="pointRadius"
                            fill="#1291FF"
                            class="point-circle"
                        />
                        <image
                            :x="point.x - pointRadius * 3"
                            :y="point.y - pointRadius * 3"
                            :width="pointRadius * 6"
                            :height="pointRadius * 6"
                            href="../assets/circle-chart-dashboard.svg"
                            class="point-image"
                            preserveAspectRatio="xMidYMid meet"
                        />
                    </g>
                </svg>
                <div class="x-axis">
                    <div
                        v-for="day in shortDays"
                        :key="day"
                        class="x-label"
                        :style="{ fontSize: labelFontSize }"
                    >
                        {{ day }}
                    </div>
                </div>
                <div
                    v-if="tooltip.visible"
                    class="tooltips"
                    :style="tooltipPosition"
                >
                    <div class="tooltip-content">
                        <div
                            class="tooltip-day"
                            :style="{ fontSize: tooltipFontSize }"
                        >
                            {{ tooltip.day }}
                        </div>
                        <div
                            class="tooltip-value"
                            :style="{ fontSize: tooltipFontSize }"
                        >
                            {{ tooltip.value }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "LineChart",
    props: {
        shortDays: {
            type: Array,
            default: () => ["Sen", "Sel", "Rab", "Kam", "Jum", "Sab", "Min"],
        },
        fullDays: {
            type: Array,
            default: () => [
                "Senin",
                "Selasa",
                "Rabu",
                "Kamis",
                "Jumat",
                "Sabtu",
                "Minggu",
            ],
        },
        data: {
            type: Array,
            default: () => [
                600000, 600000, 1900000, 1250000, 1750000, 1500000, 3000000,
            ],
        },
    },
    data() {
        return {
            chartWidth: 0,
            chartHeight: 0,
            padding: {
                top: 20,
                right: 20,
                bottom: 40,
                left: 60,
            },
            minWidth: 300,
            minHeight: 200,
            isMobile: false,
            tooltip: {
                visible: false,
                x: 0,
                y: 0,
                day: "",
                value: "",
                mouseX: 0,
                mouseY: 0,
            },
            hoverLine: {
                visible: false,
                x: 0,
            },
        };
    },
    computed: {
        areaPath() {
            if (this.dataPoints.length < 2) return "";
            const tension = this.isMobile ? 0.2 : 0.3;
            let path = `M ${this.dataPoints[0].x},${this.dataPoints[0].y}`;
            for (let i = 0; i < this.dataPoints.length - 1; i++) {
                const current = this.dataPoints[i];
                const next = this.dataPoints[i + 1];
                const cp1x = current.x + (next.x - current.x) * tension;
                const cp1y = current.y;
                const cp2x = next.x - (next.x - current.x) * tension;
                const cp2y = next.y;
                path += ` C ${cp1x},${cp1y} ${cp2x},${cp2y} ${next.x},${next.y}`;
            }
            const lastPoint = this.dataPoints[this.dataPoints.length - 1];
            path += ` L ${lastPoint.x},${
                this.chartHeight - this.padding.bottom
            }`;
            path += ` L ${this.padding.left},${
                this.chartHeight - this.padding.bottom
            }`;
            path += " Z";
            return path;
        },
        labelFontSize() {
            return this.isMobile ? "0.7rem" : "0.8rem";
        },
        tooltipFontSize() {
            return this.isMobile ? "0.7rem" : "0.8rem";
        },
        strokeWidth() {
            return this.isMobile ? 1.5 : 2;
        },
        pointRadius() {
            return this.isMobile ? 4 : 5;
        },
        yAxisLabels() {
            if (this.data.every((value) => value === 0)) {
                return [1000000, 750000, 500000, 250000, 0];
            }

            const max = Math.max(...this.data);
            const roundedMax = Math.ceil(max / 1000000) * 1000000;
            const step = roundedMax / 4;
            const labels = Array.from(
                { length: 5 },
                (_, i) => roundedMax - i * step
            );
            return labels;
        },
        dataPoints() {
            const points = [];
            const xStep =
                (this.chartWidth - this.padding.left - this.padding.right) /
                (this.data.length - 1);
            this.data.forEach((value, index) => {
                points.push({
                    x: this.padding.left + xStep * index,
                    y: this.getYPosition(value),
                    value,
                    day: this.fullDays[index],
                });
            });
            return points;
        },
        smoothPath() {
            if (this.dataPoints.length < 2) return "";
            const tension = this.isMobile ? 0.2 : 0.3;
            let path = `M ${this.dataPoints[0].x},${this.dataPoints[0].y}`;
            for (let i = 0; i < this.dataPoints.length - 1; i++) {
                const current = this.dataPoints[i];
                const next = this.dataPoints[i + 1];
                const cp1x = current.x + (next.x - current.x) * tension;
                const cp1y = current.y;
                const cp2x = next.x - (next.x - current.x) * tension;
                const cp2y = next.y;
                path += ` C ${cp1x},${cp1y} ${cp2x},${cp2y} ${next.x},${next.y}`;
            }
            return path;
        },
        tooltipPosition() {
            const offset = this.isMobile ? 10 : 15;
            let x = this.tooltip.mouseX + offset;
            let y = this.tooltip.mouseY;
            const tooltipWidth = 120;
            if (x + tooltipWidth > this.chartWidth) {
                x = this.tooltip.mouseX - tooltipWidth - offset;
            }
            return {
                left: `${x}px`,
                top: `${y}px`,
            };
        },
    },
    methods: {
        formatCurrency(value) {
            if (value >= 1000000000) {
                return `${(value / 1000000000).toFixed(1)}m`;
            } else if (value >= 1000000) {
                return `${(value / 1000000).toFixed(1)}jt`;
            } else if (value >= 1000) {
                return `${(value / 1000).toFixed(0)}rb`;
            }
            return value.toString();
        },
        getYPosition(value) {
            const max = Math.max(...this.yAxisLabels);
            const min = Math.min(...this.yAxisLabels);
            const availableHeight =
                this.chartHeight - this.padding.top - this.padding.bottom;
            return (
                this.padding.top +
                ((max - value) / (max - min)) * availableHeight
            );
        },
        showTooltip(point, event) {
            this.tooltip.visible = true;
            this.tooltip.mouseX = event.offsetX;
            this.tooltip.mouseY = event.offsetY;
            this.tooltip.day = point.day;
            this.tooltip.value = `Rp ${point.value.toLocaleString()}`;
            this.hoverLine.visible = true;
            this.hoverLine.x = point.x;
        },
        hideTooltip() {
            this.tooltip.visible = false;
            this.hoverLine.visible = false;
        },
        checkMobile() {
            this.isMobile = window.innerWidth <= 768;
        },
        updateChartDimensions() {
            if (this.$refs.chartArea) {
                const container = this.$refs.chartArea;
                const containerWidth = container.offsetWidth;
                this.chartWidth = Math.max(
                    this.minWidth,
                    this.isMobile ? containerWidth * 0.9 : containerWidth
                );
                const aspectRatio = this.isMobile ? 1.2 : 2;
                this.chartHeight = Math.max(
                    this.minHeight,
                    this.isMobile ? this.chartWidth / aspectRatio : 300
                );
                if (this.isMobile) {
                    this.padding.left = 40;
                    this.padding.right = 10;
                    this.padding.bottom = 30;
                } else {
                    this.padding.left = 60;
                    this.padding.right = 20;
                    this.padding.bottom = 40;
                }
            }
        },
        handleResize() {
            this.checkMobile();
            this.updateChartDimensions();
        },
    },
    mounted() {
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
    },
};
</script>
<style scoped>
.line-chart-container {
    width: 100%;
    height: 100%;
}
.chart-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}
.chart-area {
    width: 100%;
    height: 100%;
    position: relative;
}
@media (max-width: 768px) {
    .line-chart-container {
        padding: 10px;
    }
    .chart-wrapper {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .chart-area {
        min-width: 300px;
    }
    .total-sales {
        left: -25px;
    }
    .chart-wrapper::-webkit-scrollbar {
        display: none;
    }
    .chart-wrapper {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}
@media (min-width: 769px) {
    .line-chart-container {
        padding: 21px 20px 20px 40px;
    }
    .chart-wrapper {
        overflow-x: visible;
    }
}
.total-sales {
    position: absolute;
    left: -35px;
    top: 40%;
    transform: rotate(-90deg) translateX(-50%);
    transform-origin: 0 0;
    white-space: nowrap;
    color: #a3b4c2;
}
.y-label {
    color: #a3b4c2;
    transform: translateY(-50%);
    position: relative;
    width: 100%;
    text-align: right;
    padding-right: 10px;
}
.y-axis {
    position: absolute;
    left: 0;
    top: 20px;
    bottom: 55px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.x-axis {
    position: absolute;
    left: 60px;
    right: 20px;
    bottom: 30px;
    display: flex;
    justify-content: space-between;
}
.x-label {
    color: #869aac;
    text-align: center;
}
.chart {
    display: block;
}
.tooltips {
    position: absolute;
    transform: translate(0, -50%);
    pointer-events: none;
    z-index: 100;
}
.tooltip-content {
    position: relative;
    background: white;
    padding: 6px 10px;
    border-radius: 4px;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}
.tooltip-content::before {
    content: "";
    position: absolute;
    left: -6px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid white;
}
.tooltip-day {
    color: #1291ff;
    margin-bottom: 2px;
}
.tooltip-value {
    color: #3e4e5c;
    white-space: nowrap;
}
.point-circle {
    transition: opacity 0.2s ease;
}
.point-image {
    opacity: 0;
    transition: opacity 0.2s ease;
    pointer-events: none;
}
g:hover .point-circle {
    opacity: 0;
}
g:hover .point-image {
    opacity: 1;
}
</style>
