<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="fw-bold">
            <router-link
                to="/order/cashier"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </router-link>
        </div>
        <div class="box-manifest mt-3">
            <h5 class="mb-0 fw-bold">Data Pengirim</h5>
            <div class="row mt-4">
                <div class="col-lg-6">
                    <label class="fw-bold mb-1">
                        Nama Pengirim
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nama pengirim"
                        v-model="req.sender_name"
                    />
                </div>
                <div class="col-lg-6">
                    <label class="fw-bold mb-1">
                        Nomor Handphone Pengirim
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span class="input-group-text">+62</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nomor handphone pengirim"
                            @input="validatePhoneSender"
                            v-model="req.sender_phone"
                        />
                    </div>
                </div>
                <div class="col-lg-6 mt-3">
                    <label class="fw-bold mb-1">
                        Kota/Kabupaten Asal
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="regency"
                        :options="regencies"
                        label="name"
                        track-by="id"
                        placeholder="Cari kota/kabupaten/kecamatan asal"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-12 mt-2">
                    <label class="fw-bold mb-1">
                        Alamat Pengirim
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <textarea
                        cols="30"
                        rows="2"
                        class="form-control"
                        placeholder="Masukkan alamat lengkap pengirim"
                        v-model="req.sender_address"
                    ></textarea>
                </div>
            </div>
        </div>
        <div class="box-manifest mt-3">
            <h5 class="mb-0 fw-bold">Data Penerima</h5>
            <div class="row mt-4">
                <div class="col-lg-6">
                    <label class="fw-bold mb-1">
                        Nama Penerima
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nama penerima"
                        v-model="req.receiver_name"
                    />
                </div>
                <div class="col-lg-6">
                    <label class="fw-bold mb-1">
                        Nomor Handphone Penerima
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span class="input-group-text">+62</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nomor handphone penerima"
                            @input="validatePhoneReceiver"
                            v-model="req.receiver_phone"
                        />
                    </div>
                </div>
                <div class="col-lg-6 mt-3">
                    <label class="fw-bold mb-1">
                        Provinsi
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="province"
                        :options="provinces"
                        label="name"
                        track-by="id"
                        placeholder="Cari provinsi"
                        class="multi-form-custom"
                        :show-labels="false"
                        @input="changeProvince()"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-6 mt-3">
                    <label class="fw-bold mb-1">
                        Kota/kabupaten
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="receiver_regency"
                        :options="receiver_regencies"
                        label="name"
                        track-by="id"
                        placeholder="Pilih kota/kabupaten"
                        class="multi-form-custom"
                        @input="changeRegency()"
                        :show-labels="false"
                        :disabled="!province"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-6 mt-2">
                    <label class="fw-bold mb-1">
                        Kecamatan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="district"
                        :options="districts"
                        label="name"
                        track-by="id"
                        placeholder="Pilih kecamatan"
                        class="multi-form-custom"
                        @input="changeDistrict()"
                        :show-labels="false"
                        :disabled="!receiver_regency"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-6 mt-2">
                    <label class="fw-bold mb-1">
                        Kelurahan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="sub_district"
                        :options="sub_districts"
                        label="name"
                        track-by="id"
                        placeholder="Pilih Kelurahan"
                        class="multi-form-custom"
                        :show-labels="false"
                        :disabled="!district"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-6 mt-2">
                    <label class="fw-bold mb-1">
                        Kode Pos
                        <span class="text-danger fw-bold">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan kode pos"
                        v-model="req.receiver_zipcode"
                        @input="validateZipcode()"
                    />
                </div>
                <div class="col-lg-12 mt-3">
                    <label class="fw-bold mb-1">
                        Alamat Penerima
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <textarea
                        cols="30"
                        rows="2"
                        class="form-control"
                        placeholder="Masukkan alamat lengkap penerima"
                        v-model="req.receiver_address"
                    ></textarea>
                </div>
            </div>
        </div>
        <div class="box-manifest mt-3">
            <h5 class="fw-bold mb-0">Daftar Barang</h5>
            <div class="table-responsive-custom">
                <table class="table table-bordered mt-3">
                    <thead class="table-light">
                        <tr>
                            <th class="fw-medium">Koli</th>
                            <th class="fw-medium">
                                Deskripsi<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Berat (Kg)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Panjang (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Lebar (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Tinggi (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Berat Volume (Kg)<span class="text-danger"
                                    >*</span
                                >
                            </th>
                            <th class="fw-medium">
                                Kubikasi (Kg3)<span class="text-danger">*</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody
                        class="padding-body"
                        v-for="(item, index) in items"
                        :key="index"
                    >
                        <tr>
                            <td class="custom">
                                <input
                                    type="text"
                                    v-model="item.item"
                                    placeholder="Koli"
                                    v-number-format="item.item"
                                    class="form-control w-koli"
                                    @input="calculateResult()"
                                />
                            </td>
                            <td class="custom">
                                <input
                                    type="text"
                                    class="form-control w-desc"
                                    placeholder="Deskripsi"
                                    v-model="item.description"
                                />
                            </td>
                            <td class="custom">
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="item.weight"
                                    v-bind:class="{
                                        green:
                                            Number(item.weight) >
                                            Number(item.volume),
                                    }"
                                    v-number-format="item.weight"
                                    placeholder="Berat"
                                />
                            </td>
                            <td class="custom">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="P"
                                    @input="calculateResult()"
                                    v-model="item.length"
                                    v-number-format="item.length"
                                />
                            </td>
                            <td class="custom">
                                <input
                                    type="text"
                                    v-model="item.width"
                                    class="form-control"
                                    placeholder="L"
                                    @input="calculateResult()"
                                    v-number-format="item.width"
                                />
                            </td>
                            <td class="custom">
                                <input
                                    type="text"
                                    v-model="item.height"
                                    class="form-control"
                                    placeholder="T"
                                    @input="calculateResult()"
                                    v-number-format="item.height"
                                />
                            </td>
                            <td class="custom">
                                <input
                                    type="text"
                                    v-model="item.volume"
                                    class="form-control"
                                    v-bind:class="{
                                        green:
                                            Number(item.volume) >
                                            Number(item.weight),
                                    }"
                                    disabled
                                />
                            </td>
                            <td class="custom">
                                <div class="d-flex align-items-center">
                                    <input
                                        type="text"
                                        v-model="item.cubic"
                                        class="form-control me-2"
                                        disabled
                                    />
                                    <a
                                        href="javascript:void(0)"
                                        @click="showModalDeleteItem(index)"
                                    >
                                        <img
                                            src="../assets/trash-yellow.png"
                                            width="16"
                                            lt=""
                                        />
                                    </a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="custom" colspan="8">
                                <div class="w-430">
                                    <div
                                        class="d-flex justify-content-between align-items-center"
                                    >
                                        <label class="fw-medium"
                                            >Perlindungan Barang</label
                                        >
                                        <button
                                            class="btn btn-add d-flex align-items-center"
                                            @click="openProtectionModal(index)"
                                            v-if="item.handling.length === 0"
                                        >
                                            <img
                                                src="../assets/plus.svg"
                                                alt="plus"
                                                class="me-2"
                                            />Tambah
                                        </button>
                                        <button
                                            class="btn btn-add d-flex align-items-center"
                                            @click="openProtectionModal(index)"
                                            v-else
                                        >
                                            <img
                                                src="../assets/pencil.svg"
                                                alt="pencil"
                                                class="me-2"
                                            />Edit
                                        </button>
                                    </div>
                                    <input
                                        type="text"
                                        class="form-control mt-3"
                                        placeholder="Belum ada perlindungan barang ..."
                                        readonly
                                        v-if="item.handling.length === 0"
                                    />
                                    <div v-else class="form-control mt-3">
                                        <div
                                            v-for="protection in item.handling"
                                            :key="protection.name"
                                            class="d-flex justify-content-between my-1"
                                        >
                                            <p class="mb-0">
                                                {{ protection.name }}
                                            </p>
                                            <p class="mb-0">
                                                {{ currency(protection.price) }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <button class="btn btn-green px-4">
                    <div class="d-flex align-items-center" @click="addItems()">
                        <i class="pi pi-plus me-1"></i>
                        Tambah Barang
                    </div>
                </button>
            </div>
            <hr class="mt-4" />
            <h5 class="fw-bold mb-0">Hasil</h5>
            <div class="table-responsive-custom">
                <table class="result table table-bordered mt-3">
                    <thead class="table-light">
                        <tr>
                            <th scope="col" class="fw-medium">
                                Berat Aktual (Kg)
                            </th>
                            <th scope="col" class="fw-medium">
                                Berat Volume (Kg)
                            </th>
                            <th scope="col" class="fw-medium">
                                Kubikasi (Kg3)
                            </th>
                            <th scope="col" class="fw-medium">
                                Charge Weight (Kg)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="form-box">
                                    {{ decimalFormat(totalWeight) }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box">
                                    {{ decimalFormat(totalVolume) }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box">
                                    {{ decimalFormat(totalCubic) }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box green">
                                    {{ decimalFormat(chargeWeight) }}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr />
            <label class="fw-bold mb-2">Catatan</label>
            <textarea
                class="form-control"
                v-model="req.note"
                placeholder="Masukkan catatan untuk barang"
            ></textarea>
        </div>
        <div class="box-manifest mt-3">
            <h5 class="mb-0 fw-bold">Pilih Jenis Layanan</h5>
            <label class="fw-bold mb-1 mt-3">
                Jenis Layanan
                <span class="text-danger fw-bold">*</span>
            </label>
            <select class="form-control form-select" v-model="req.service_type">
                <option value="" disabled>Pilih Jenis Layanan</option>
                <option value="pengiriman_barang">Pengiriman Barang</option>
                <option value="pengiriman_motor">Pengiriman Motor</option>
                <option value="pengiriman_mobil">Pengiriman Mobil</option>
                <option value="pengiriman_truk">Pengiriman Truk</option>
                <option value="sewa_container_20ft">
                    Sewa Kontainer 20 Feet
                </option>
                <option value="sewa_container_40ft">
                    Sewa Kontainer 40 Feet
                </option>
            </select>
        </div>
        <div class="box-manifest mt-3">
            <h5 class="fw-bold mb-4">
                Metode Pembayaran <span class="text-danger">*</span>
            </h5>
            <div class="row">
                <div
                    class="col-lg-6 cursor-pointer"
                    @click="selectedPayment = 'cash'"
                >
                    <div
                        class="box"
                        :class="{ selected: selectedPayment === 'cash' }"
                    >
                        <div class="d-flex align-items-center">
                            <input
                                type="radio"
                                class="me-3"
                                value="cash"
                                v-model="selectedPayment"
                            />
                            <img
                                src="../assets/money-active.svg"
                                alt="money"
                                class="me-3"
                                v-if="selectedPayment === 'cash'"
                            />
                            <img
                                v-else
                                src="../assets/money.svg"
                                alt="money"
                                class="me-3"
                            />
                            <div class="d-flex flex-column">
                                <p class="fw-bold mb-0">Tunai</p>
                                <p class="text-gray mb-0">Pembayaran tunai</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="col-lg-6 cursor-pointer"
                    @click="selectedPayment = 'va'"
                >
                    <div
                        class="box"
                        :class="{ selected: selectedPayment === 'va' }"
                    >
                        <div class="d-flex align-items-center">
                            <input
                                type="radio"
                                class="me-3"
                                value="va"
                                v-model="selectedPayment"
                            />
                            <img
                                src="../assets//va-active.svg"
                                alt="va active"
                                class="me-3"
                                v-if="selectedPayment === 'va'"
                            />
                            <img
                                src="../assets/va.svg"
                                alt="va"
                                class="me-3"
                                v-else
                            />
                            <div class="d-flex flex-column">
                                <p class="fw-bold mb-0">Virtual Account</p>
                                <p class="text-gray mb-0">
                                    Pembayaran VA dari banyak pilihan bank
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="col-lg-12 position-relative mt-2"
                    v-if="selectedPayment === 'va'"
                >
                    <label class="fw-bold mb-1 mt-3">
                        Bank
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div
                        class="form-control cursor-pointer"
                        :class="{ 'mb-4': !isDropdownOpen }"
                        @click="isDropdownOpen = !isDropdownOpen"
                    >
                        <div
                            class="d-flex justify-content-between align-items-center w-100"
                        >
                            <div class="d-flex align-items-center">
                                <img
                                    v-if="selectedBank"
                                    :src="selectedBank.asset"
                                    :alt="selectedBank.asset"
                                    width="50"
                                    class="me-2"
                                />
                                <p
                                    class="mb-0"
                                    :class="{
                                        'text-gray': !selectedBank,
                                    }"
                                >
                                    {{
                                        selectedBank
                                            ? selectedBank.label
                                            : "Pilih bank pembayaran"
                                    }}
                                </p>
                            </div>
                            <img
                                src="../assets/arrow-down-2.svg"
                                alt="arrow down 2"
                                :class="{ 'rotate-arrow': isDropdownOpen }"
                            />
                        </div>
                    </div>
                    <div
                        v-if="isDropdownOpen"
                        class="form-control my-2 position-absolute z-3"
                    >
                        <div
                            v-for="(channel, index) in channels"
                            :key="index"
                            class="d-flex justify-content-center flex-column py-2 cursor-pointer"
                            :class="{
                                'border-bottom': index !== channels.length - 1,
                            }"
                            @click="selectBank(channel)"
                        >
                            <div class="d-flex align-items-center">
                                <img
                                    :src="channel.asset"
                                    :alt="channel.asset"
                                    width="50"
                                    class="me-2"
                                />
                                {{ channel.label }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4" v-if="suggestion">
            <div class="col-lg-6"></div>
            <div class="col-lg-6">
                <div class="box selected">
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <div class="d-flex align-items-center">
                            <img
                                src="../assets/information.svg"
                                alt="information"
                                class="me-2"
                            />
                            <h6 class="mb-0">Informasi Harga Pengiriman</h6>
                        </div>
                        <h5 class="mb-0 text-suggestion">
                            {{ currency(suggestion) }}/Kg
                        </h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-6"></div>
            <div class="col-lg-6">
                <div class="box-manifest">
                    <h5 class="mb-0 fw-bold">Total Biaya Pengiriman</h5>
                    <div class="d-flex align-items-center mt-3">
                        <div>Biaya Pengiriman</div>
                        <div class="ms-auto fw-bold d-flex align-items-center">
                            <a
                                v-if="!isDisabled"
                                href="javascript:void(0)"
                                @click="is_edit_service = !is_edit_service"
                                class="me-1"
                                v-bind:class="{
                                    'text-gray': this.is_edit_service == false,
                                    'text-primary':
                                        this.is_edit_service == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            <template v-if="!is_edit_service">
                                {{ currency(service_price) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="service_price"
                                    v-bind="format_number"
                                    v-autofocus
                                    @input="updateWidth('service')"
                                    :style="{
                                        width: moneyInputWidths.service + 'px',
                                    }"
                                    class="form-control form-edit"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-2">
                        <div>Biaya Packing</div>
                        <div class="ms-auto fw-bold d-flex align-items-center">
                            {{ currency(packing_price) }}
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-2">
                        <div>Biaya Jasa Tambahan</div>
                        <div class="ms-auto fw-bold d-flex align-items-center">
                            <a
                                v-if="!isDisabled"
                                href="javascript:void(0)"
                                @click="
                                    is_edit_additional = !is_edit_additional
                                "
                                class="me-1"
                                v-bind:class="{
                                    'text-gray':
                                        this.is_edit_additional == false,
                                    'text-primary':
                                        this.is_edit_additional == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            <template v-if="!is_edit_additional">
                                {{ currency(additional_price) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="additional_price"
                                    v-bind="format_number"
                                    v-autofocus
                                    @input="updateWidth('additional')"
                                    :style="{
                                        width:
                                            moneyInputWidths.additional + 'px',
                                    }"
                                    class="form-control form-edit"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-2">
                        <div class="text-yellow">Diskon</div>
                        <div
                            class="ms-auto fw-bold text-yellow d-flex align-items-center"
                        >
                            <a
                                v-if="!isDisabled"
                                href="javascript:void(0)"
                                @click="is_edit_discount = !is_edit_discount"
                                class="me-1"
                                v-bind:class="{
                                    'text-gray': this.is_edit_discount == false,
                                    'text-primary':
                                        this.is_edit_discount == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            -<template v-if="!is_edit_discount">
                                {{ currency(discount_price) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="discount_price"
                                    v-bind="format_number"
                                    v-autofocus
                                    @input="updateWidth('discount')"
                                    :style="{
                                        width: moneyInputWidths.discount + 'px',
                                    }"
                                    class="form-control form-edit yellow"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <hr />
                    <div class="d-flex align-items-center mt-2">
                        <div class="text-green fw-bold">Total Pembayaran</div>
                        <div class="ms-auto fw-bold text-green">
                            {{
                                currency(
                                    service_price +
                                        packing_price +
                                        additional_price -
                                        discount_price
                                )
                            }}
                        </div>
                    </div>
                    <div class="mt-3">
                        <button
                            class="btn btn-primary w-100"
                            @click="modalSuccessConfirmation = true"
                            :disabled="isCreateOrderDisabled"
                        >
                            Buat Pesanan
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalDelete"></div>
            <div class="modal-body-chat vsm" v-if="modalDelete">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">Data Ini Akan Dihapus</h5>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin menghapus data ukur-timbang
                        barang ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalDelete = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            @click="deleteItem()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                        >
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessConfirmation"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessConfirmation">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">Pesanan Akan Dibuat</h5>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin membuat pesanan ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalSuccessConfirmation = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            @click="storeData()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_save"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_save"
                            ></span>
                            Simpan
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">Pesanan Berhasil Dibuat</h5>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/order/cashier"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalProtection">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <h5 class="mb-3">Pilih Perlindungan Barang</h5>
                <div
                    v-for="protection in protectionOptions"
                    :key="protection.name"
                    class="my-3"
                >
                    <div
                        class="d-flex justify-content-between align-items-center mb-3"
                    >
                        <div class="d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="me-3 cursor-pointer custom-checkbox"
                                :value="protection"
                                :checked="
                                    selectedModalProtections.some(
                                        (p) => p.name === protection.name
                                    )
                                "
                                @change="toggleProtection(protection)"
                                :id="protection.name"
                            />
                            <label
                                :for="protection.name"
                                class="cursor-pointer"
                            >
                                {{ protection.name }}
                            </label>
                        </div>
                        {{
                            currency(
                                calculatePackingCost(protection.type, {
                                    P: Number(
                                        items[currentItemIndex].length.replace(
                                            ".",
                                            ""
                                        )
                                    ),
                                    L: Number(
                                        items[currentItemIndex].width.replace(
                                            ".",
                                            ""
                                        )
                                    ),
                                    T: Number(
                                        items[currentItemIndex].height.replace(
                                            ".",
                                            ""
                                        )
                                    ),
                                    K: Number(
                                        items[currentItemIndex].item.replace(
                                            ".",
                                            ""
                                        )
                                    ),
                                })
                            )
                        }}
                    </div>
                    <hr />
                </div>
                <div class="d-flex justify-content-end">
                    <button
                        class="btn btn-primary px-5"
                        @click="saveProtections()"
                    >
                        Simpan
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccessVa">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">Pesanan Berhasil dibuat</h5>
                    <div class="text-gray-dashboard">
                        Segera lakukan pembayaran melalui virtual account
                    </div>
                </div>
                <div class="mt-4">
                    <router-link
                        :to="'/order/cashier/va/' + receiptCode"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Api from "../api/Api";
import { Money } from "v-money";
import helpers from "@/helpers/index.js";

export default {
    components: {
        Multiselect,
        Money,
    },
    data() {
        return {
            selectedPayment: null,
            selectedBank: null,
            isDropdownOpen: false,
            channels: [],
            modalSuccessVa: false,
            receiptCode: null,
            suggestion: null,
            protectionOptions: [
                {
                    name: "Wrapping Plastic",
                    type: "wrappingPlastic",
                    value: "wrapping_plastic",
                    price: 5000,
                },
                {
                    name: "Pallet",
                    type: "pallet",
                    value: "pallet",
                    price: 60000,
                },
                {
                    name: "Bubble Wrap",
                    type: "bubbleWrap",
                    value: "bubble_wrap",
                    price: 10000,
                },
                {
                    name: "Kardus",
                    type: "kardus",
                    value: "kardus",
                    price: 10000,
                },
                {
                    name: "Kayu",
                    type: "kayu",
                    value: "kayu",
                    price: 60000,
                },
                {
                    name: "Karung Kecil",
                    type: "karungKecil",
                    value: "karung_kecil",
                    price: 7500,
                },
                {
                    name: "Karung Sedang",
                    type: "karungSedang",
                    value: "karung_sedang",
                    price: 10000,
                },
                {
                    name: "Karung Besar",
                    type: "karungBesar",
                    value: "karung_besar",
                    price: 12500,
                },
            ],
            selectedModalProtections: [],
            modalProtection: false,
            currentItemIndex: null,
            selectedProtections: [],
            moneyInputWidths: {
                service: 30,
                packing: 30,
                additional: 30,
                discount: 30,
            },
            regencies: [],
            regency: null,
            province: null,
            provinces: [],
            receiver_regency: null,
            receiver_regencies: [],
            district: null,
            districts: [],
            sub_district: null,
            sub_districts: [],
            req: {
                sender_name: "",
                sender_phone: "",
                sender_regency: null,
                sender_address: "",
                receiver_name: "",
                receiver_phone: "",
                receiver_zipcode: "",
                receiver_address: "",
                service_type: "",
                note: "",
            },
            items: [
                {
                    item: "",
                    description: "",
                    weight: "",
                    length: "",
                    width: "",
                    height: "",
                    volume: 0,
                    cubic: 0,
                    handling: [],
                },
            ],
            format_number: {
                decimal: ",",
                thousands: ".",
                prefix: "Rp",
                suffix: "",
                precision: 0,
                masked: false,
                maxLength: 13,
                minimumValue: 0,
            },
            delete_value: null,
            modalDelete: false,
            is_save: false,
            modalSuccessConfirmation: false,
            modalSuccess: false,
            service_price: 0,
            additional_price: 0,
            discount_price: 0,
            total_price: 0,
            is_edit_service: false,
            is_edit_picking: false,
            is_edit_additional: false,
            is_edit_discount: false,
        };
    },
    watch: {
        "receiver_regency.id": function (newId) {
            if (newId) {
                this.getDistrict();
                this.checkAndCallGetSuggestion();
            }
        },
        "regency.id": function (newId) {
            if (newId) {
                this.checkAndCallGetSuggestion();
            }
        },
        "req.service_type": function () {
            this.checkAndCallGetSuggestion();
        },
        items: {
            deep: true,
            handler(items) {
                items.forEach((item) => {
                    if (
                        item.description ||
                        item.weight > 0 ||
                        item.length > 0 ||
                        item.width > 0 ||
                        item.height > 0 ||
                        item.handling.length > 0
                    ) {
                        if (item.item === "") {
                            item.item = "1";
                        }
                    }
                });
            },
        },
    },
    created() {
        this.getRegency();
        this.getProvince();
        this.getChannels();
    },
    directives: {
        autofocus: {
            inserted: (el) => {
                el.focus();
            },
        },
    },
    computed: {
        isDisabled() {
            const isSenderInfoComplete =
                this.req.sender_name &&
                this.req.sender_phone &&
                this.regency &&
                this.req.sender_address;

            const isReceiverInfoComplete =
                this.req.receiver_name &&
                this.req.receiver_phone &&
                this.province &&
                this.receiver_regency &&
                this.district &&
                this.sub_district &&
                this.req.receiver_zipcode &&
                this.req.receiver_address;

            const isServiceTypeInfoComplete = this.req.service_type;

            const areItemsComplete =
                this.items.length > 0 &&
                this.items.every(
                    (item) =>
                        item.description !== "" &&
                        item.weight !== "" &&
                        item.length !== "" &&
                        item.width !== "" &&
                        item.height !== "" &&
                        item.volume !== ""
                );
            const isPaymentInfoComplete =
                this.selectedPayment &&
                (this.selectedPayment !== "va" || this.selectedBank);
            return !(
                isSenderInfoComplete &&
                isReceiverInfoComplete &&
                areItemsComplete &&
                isPaymentInfoComplete &&
                isServiceTypeInfoComplete
            );
        },
        isCreateOrderDisabled() {
            return this.isDisabled || !this.service_price;
        },
        packing_price() {
            return this.items.reduce((total, item) => {
                const itemHandlingPrice = item.handling.reduce(
                    (sum, handling) => sum + (handling.price || 0),
                    0
                );
                return total + itemHandlingPrice;
            }, 0);
        },
        totalWeight() {
            return this.items.reduce(
                (total, item) =>
                    total +
                    Number(String(item.item).replace(/\./g, "")) *
                        Math.round(
                            Number(String(item.weight).replace(/\./g, ""))
                        ),
                0
            );
        },
        totalVolume() {
            return this.items.reduce(
                (total, item) =>
                    total +
                    Number(String(item.item).replace(/\./g, "")) *
                        Math.round(
                            Number(String(item.volume).replace(/\./g, ""))
                        ),
                0
            );
        },
        totalCubic() {
            return this.items.reduce(
                (total, item) =>
                    total +
                    Number(String(item.item).replace(/\./g, "")) *
                        Math.round(
                            Number(String(item.cubic).replace(/\./g, ""))
                        ),
                0
            );
        },
        chargeWeight() {
            return Math.max(this.totalWeight, this.totalVolume);
        },
    },
    methods: {
        selectBank(channel) {
            this.selectedBank = channel;
            this.isDropdownOpen = false;
        },
        getChannels() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/payment/channel`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.channels = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        checkAndCallGetSuggestion() {
            if (
                this.regency?.id &&
                this.receiver_regency?.id &&
                this.req.service_type
            ) {
                this.getSuggestion();
            }
        },
        getSuggestion() {
            var payload = {
                origin_regency_id: this.regency?.id,
                destination_regency_id: this.receiver_regency?.id,
                service_type: this.req?.service_type,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/order/get_suggestion`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.suggestion = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        changeProvince() {
            this.receiver_regency = null;
            this.district = null;
            this.sub_district = null;
            this.getRegency2();
        },
        changeRegency() {
            this.district = null;
            this.sub_district = null;
            this.getDistrict();
        },
        changeDistrict() {
            this.sub_district = null;
            this.getSubDistrict();
        },
        updateWidth(type) {
            const values = {
                service: this.service_price,
                packing: this.packing_price,
                additional: this.additional_price,
                discount: this.discount_price,
            };

            const valueLength = String(values[type]).length;
            this.moneyInputWidths[type] = 30 + (valueLength - 1) * 10;
        },
        validateZipcode() {
            this.req.receiver_zipcode = this.req.receiver_zipcode.replace(
                /[^0-9]/g,
                ""
            );
        },
        validatePhoneSender() {
            if (!this.req.sender_phone.startsWith("8")) {
                this.req.sender_phone =
                    "8" + this.req.sender_phone.replace(/[^0-9]/g, "");
            } else {
                this.req.sender_phone = this.req.sender_phone.replace(
                    /[^0-9]/g,
                    ""
                );
            }
        },
        validatePhoneReceiver() {
            if (!this.req.receiver_phone.startsWith("8")) {
                this.req.receiver_phone =
                    "8" + this.req.receiver_phone.replace(/[^0-9]/g, "");
            } else {
                this.req.receiver_phone = this.req.receiver_phone.replace(
                    /[^0-9]/g,
                    ""
                );
            }
        },
        getRegency() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.regencies = data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getRegency2() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/regency?province_id=${this.province?.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.receiver_regencies = data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getProvince() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/province`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.provinces = data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getDistrict() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/district?regency_id=${this.receiver_regency?.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.districts = data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getSubDistrict() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/subdistrict?district_id=${this.district?.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.sub_districts = data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        addItems() {
            this.items.push({
                item: "",
                description: "",
                weight: "",
                length: "",
                width: "",
                height: "",
                volume: 0,
                cubic: 0,
                handling: [],
            });
        },
        openProtectionModal(index) {
            this.currentItemIndex = index;
            this.selectedModalProtections = this.items[index].handling.map(
                (p) => ({ ...p })
            );
            this.modalProtection = true;
        },
        saveProtections() {
            if (this.currentItemIndex !== null) {
                const currentItem = this.items[this.currentItemIndex];
                const length = Number(currentItem.length.replace(".", ""));
                const width = Number(currentItem.width.replace(".", ""));
                const height = Number(currentItem.height.replace(".", ""));
                const qty = Number(currentItem.item.replace(".", ""));

                const protectionsWithPrices = this.selectedModalProtections.map(
                    (protection) => {
                        const protectionDetails = this.protectionOptions.find(
                            (p) => p.name === protection.name
                        );
                        const price = helpers.calculatePackingCost(
                            protectionDetails.type,
                            {
                                P: length,
                                L: width,
                                T: height,
                                K: qty,
                            }
                        );
                        return {
                            ...protectionDetails,
                            price: price,
                        };
                    }
                );

                this.items[this.currentItemIndex].handling =
                    protectionsWithPrices;
            }
            this.modalProtection = false;
            this.selectedModalProtections = [];
        },
        toggleProtection(protection) {
            const existingIndex = this.selectedModalProtections.findIndex(
                (p) => p.name === protection.name
            );

            if (existingIndex !== -1) {
                this.selectedModalProtections.splice(existingIndex, 1);
            } else {
                this.selectedModalProtections.push(protection);
            }
        },
        showModalDeleteItem(index) {
            this.modalDelete = true;
            this.delete_value = index;
        },
        deleteItem() {
            this.items.splice(this.delete_value, 1);
            this.modalDelete = false;
        },
        calculateResult() {
            this.items.forEach((val) => {
                const length = Number(val.length.replace(".", "")) || "";
                const width = Number(val.width.replace(".", "")) || "";
                const height = Number(val.height.replace(".", "")) || "";
                const qty = Number(val.item.replace(".", "")) || "";

                if (length !== 0 && width !== 0 && height !== 0) {
                    val.volume = Math.round(
                        (length * width * height) / 4000
                    ).toLocaleString("id-ID");
                    val.cubic = Math.round(
                        (length * width * height) / 1000000
                    ).toLocaleString("id-ID");

                    val.handling = val.handling.map((protection) => {
                        const newPrice = helpers.calculatePackingCost(
                            protection.type,
                            {
                                P: length,
                                L: width,
                                T: height,
                                K: qty,
                            }
                        );
                        return {
                            ...protection,
                            price: newPrice,
                        };
                    });
                } else {
                    val.volume = "0";
                    val.cubic = "0";
                }
            });
        },
        storeData() {
            this.is_save = true;
            const itemsData = this.items.map((item) => ({
                qty: item.item,
                description: item.description,
                weight: item.weight,
                length: item.length,
                width: item.width,
                height: item.height,
                weight_volume: item.volume,
                cubic: item.cubic,
                protection:
                    item.handling.length > 0
                        ? item.handling.map((h) => ({
                              packing: h.value,
                              price: Math.round(h.price),
                          }))
                        : null,
            }));
            var data = {
                sender_name: this.req.sender_name,
                sender_phone: this.req.sender_phone,
                sender_address: this.req.sender_address,
                origin_regency_id: this.regency?.id,
                receiver_name: this.req.receiver_name,
                receiver_phone: this.req.receiver_phone,
                destination_province_id: this.province?.id,
                destination_regency_id: this.receiver_regency?.id,
                destination_district_id: this.district?.id,
                destination_subdistrict_id: this.sub_district?.id,
                receiver_zipcode: this.req.receiver_zipcode,
                receiver_address: this.req.receiver_address,
                service_type: this.req.service_type,
                service_price: this.service_price,
                packing_price: this.packing_price,
                additional_price: this.additional_price,
                discount_price: this.discount_price,
                payment_method: this.selectedPayment,
                note: this.req.note,
                items: itemsData,
                charge_weight: this.chargeWeight,
            };
            if (this.selectedPayment === "va") {
                data.va = this.selectedBank?.va;
            }

            Api.post(`${process.env.VUE_APP_SERVICE_URL}/order/create`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.is_save = false;
                    if (this.selectedPayment === "cash") {
                        this.modalSuccess = true;
                    } else {
                        this.receiptCode = res.data.data.receipt_code;
                        this.modalSuccessVa = true;
                    }
                    this.modalSuccessConfirmation = false;
                })
                .catch((err) => {
                    this.is_save = false;
                    this.modalSuccessConfirmation = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #c1cdd7;
    background-color: white;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
}

.custom-checkbox:checked {
    border: 1px solid #329a5f;
    background-color: #d9f2e4;
}

.custom-checkbox:checked::after {
    content: "✔";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #329a5f;
    font-size: 14px;
}
::v-deep .v-money.form-control {
    background-color: #f9fafb;
}
hr {
    color: #c1cdd7;
}
input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #a3b4c2;
    border-radius: 50%;
    background-color: transparent;
    position: relative;
    cursor: pointer;
}

input[type="radio"]:checked {
    border: 2px solid #329a5f;
    background-color: transparent;
}

input[type="radio"]:checked::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    background-color: #329a5f;
    border-radius: 50%;
}
.rotate-arrow {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}
textarea {
    resize: none;
}
.box.selected {
    border: 1px solid #329a5f;
    background-color: #d9f2e4;
}
.box.selected p {
    color: #329a5f !important;
}
.text-suggestion {
    color: #329a5f !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
hr.modal-hr {
    color: #c1cdd7 !important;
    margin: 0;
}
.modal-vue .modal-body-chat.vsm {
    width: 450px;
}
.btn-add {
    color: #40bf76 !important;
    background-color: transparent !important;
    border: 1px solid #40bf76 !important;
}
::v-deep .multiselect--disabled .multiselect__current,
::v-deep .multiselect--disabled .multiselect__select,
::v-deep .multiselect--disabled .multiselect__tags,
::v-deep .multiselect--disabled .multiselect__placeholder {
    background: #f3f5f7 !important;
    color: #495057 !important;
}
.w-30 {
    width: 30px;
}
.w-120 {
    width: 120px;
}
.table > :not(caption) > * > * {
    border: unset;
}
.table-bordered > :not(caption) > * {
    border: unset;
}
.table > :not(caption) > * > * {
    background-color: unset;
}
tr {
    border-radius: 10px !important;
}
tr td.custom {
    background-color: #ebf6ff !important;
}
.w-handling {
    width: 300px;
}
table tbody tr:first-child td:first-child {
    border-top-left-radius: 6px;
}

table tbody tr:first-child td:last-child {
    border-top-right-radius: 6px;
}

table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
}

table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
}
.padding-body::after {
    height: 10px;
    display: table-row;
    content: "";
}
.result.table > :not(caption) > * > * {
    padding: 0.2rem 0.5rem;
}
.form-control.green {
    background: #d9f2e4;
    border: 1px solid #8cd9ad !important;
    color: #329a5f !important;
}
.form-control.green[disabled] {
    background: #d9f2e4;
    border: 1px solid #8cd9ad !important;
    color: #329a5f !important;
}
.w-desc {
    width: 160px;
}
.w-koli {
    width: 60px;
}
.radius-select {
    border-radius: 10px;
    padding: 8px 12px;
}
.w-430 {
    width: 430px;
}
.scroll-select {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}
.dropdown-menu {
    padding: 5px 10px 0px 10px;
}
.form-check-input:checked {
    background-color: #329a5f;
    border: #329a5f;
}
div::-webkit-scrollbar {
    display: none;
}
.form-edit {
    border: unset !important;
    text-align: right;
    padding: 0px;
    font-weight: 600 !important;
    color: #000 !important;
    border-radius: unset !important;
}
.form-edit.yellow {
    color: #ff8600 !important;
}
</style>
