<template>
    <div class="padding-container">
        <div class="content-load" v-if="is_loading_data"></div>
        <div id="loader" v-if="is_loading_data"></div>
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="goBackListDriver()"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-10 mt-4">
                <div class="box">
                    <div class="d-flex align-items-center">
                        <h5 class="mb-3 fw-bold">Detail Order</h5>
                        <div class="ms-auto">
                            <span
                                class="tag mb-0 pb-0 px-3 radius"
                                v-bind:class="{
                                    'yellow-cro': detail.is_calculate == false,
                                    'green-cro': detail.is_calculate == true,
                                }"
                            >
                                <template v-if="detail.is_calculate == true">
                                    Sudah Diukur
                                </template>
                                <template v-else> Belum Diukur </template>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <table class="w-100 ps-0 fw-bold" cellpadding="10">
                                <tr class="border-bottom">
                                    <td class="ps-0 w-120 fw-medium">
                                        Nama Pengirim
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            detail.customer_name
                                                ? detail.customer_name
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                                <tr class="border-bottom">
                                    <td class="ps-0 w-120 fw-medium">
                                        Kota Asal
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            detail.origin ? detail.origin : "-"
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-0 w-120 fw-medium">
                                        CRM Specialist
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            detail.specialist
                                                ? detail.specialist
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-md-6">
                            <table class="w-100 ps-0 fw-bold" cellpadding="10">
                                <tr class="border-bottom">
                                    <td class="ps-0 w-120 fw-medium">Muatan</td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            detail.description
                                                ? detail.description
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                                <tr class="border-bottom">
                                    <td class="ps-0 w-120 fw-medium">
                                        Kota Tujuan
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            detail.destination
                                                ? detail.destination
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-0 w-120 fw-medium">
                                        Tanggal Deal
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            detail.deal_time
                                                ? detail.deal_time
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box mt-4" v-if="!detail.is_calculate">
            <h5 class="fw-bold mb-0">Hasil Ukur - Timbang Barang</h5>
            <div class="table-responsive-custom">
                <table class="not-read-only table table-bordered mt-3">
                    <thead class="table-light">
                        <tr>
                            <th class="fw-medium">Koli</th>
                            <th class="fw-medium">
                                Deskripsi<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Berat (Kg)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Panjang (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Lebar (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Tinggi (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Berat Volume (Kg)<span class="text-danger"
                                    >*</span
                                >
                            </th>
                            <th class="fw-medium">
                                Kubikasi (Kg3)<span class="text-danger">*</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody
                        class="padding-body"
                        v-for="(item, index) in items"
                        :key="index"
                    >
                        <tr>
                            <td class="custom">
                                <input
                                    type="text"
                                    v-model="item.item"
                                    placeholder="Koli"
                                    v-number-format="item.item"
                                    class="form-control w-koli"
                                    @input="calculateResult()"
                                />
                            </td>
                            <td class="custom">
                                <input
                                    type="text"
                                    class="form-control w-desc"
                                    placeholder="Deskripsi"
                                    v-model="item.description"
                                />
                            </td>
                            <td class="custom">
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="item.weight"
                                    v-bind:class="{
                                        green:
                                            Number(item.weight) >
                                            Number(item.volume),
                                    }"
                                    v-number-format="item.weight"
                                    placeholder="Berat"
                                />
                            </td>
                            <td class="custom">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="P"
                                    @input="calculateResult()"
                                    v-model="item.length"
                                    v-number-format="item.length"
                                />
                            </td>
                            <td class="custom">
                                <input
                                    type="text"
                                    v-model="item.width"
                                    class="form-control"
                                    placeholder="L"
                                    @input="calculateResult()"
                                    v-number-format="item.width"
                                />
                            </td>
                            <td class="custom">
                                <input
                                    type="text"
                                    v-model="item.height"
                                    class="form-control"
                                    placeholder="T"
                                    @input="calculateResult()"
                                    v-number-format="item.height"
                                />
                            </td>
                            <td class="custom">
                                <input
                                    type="text"
                                    v-model="item.volume"
                                    class="form-control"
                                    v-bind:class="{
                                        green:
                                            Number(item.volume) >
                                            Number(item.weight),
                                    }"
                                    disabled
                                />
                            </td>
                            <td class="custom">
                                <div class="d-flex align-items-center">
                                    <input
                                        type="text"
                                        v-model="item.cubic"
                                        class="form-control me-2"
                                        disabled
                                    />
                                    <a
                                        href="javascript:void(0)"
                                        @click="showModalDeleteItem(index)"
                                    >
                                        <img
                                            src="../assets/trash-yellow.png"
                                            width="16"
                                            lt=""
                                        />
                                    </a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="custom" colspan="8">
                                <div class="w-430">
                                    <div
                                        class="d-flex justify-content-between align-items-center"
                                    >
                                        <label class="fw-medium"
                                            >Perlindungan Barang</label
                                        >
                                        <button
                                            class="btn btn-add d-flex align-items-center"
                                            @click="openProtectionModal(index)"
                                            v-if="item.handling.length === 0"
                                        >
                                            <img
                                                src="../assets/plus.svg"
                                                alt="plus"
                                                class="me-2"
                                            />Tambah
                                        </button>
                                        <button
                                            class="btn btn-add d-flex align-items-center"
                                            @click="openProtectionModal(index)"
                                            v-else
                                        >
                                            <img
                                                src="../assets/pencil.svg"
                                                alt="pencil"
                                                class="me-2"
                                            />Edit
                                        </button>
                                    </div>
                                    <input
                                        type="text"
                                        class="form-control mt-3"
                                        placeholder="Belum ada perlindungan barang ..."
                                        readonly
                                        v-if="item.handling.length === 0"
                                    />
                                    <div v-else class="form-control mt-3">
                                        <div
                                            v-for="protection in item.handling"
                                            :key="protection.name"
                                            class="d-flex justify-content-between my-1"
                                        >
                                            <p class="mb-0">
                                                {{ protection.name }}
                                            </p>
                                            <p class="mb-0">
                                                {{ currency(protection.price) }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <button class="btn btn-green px-4">
                    <div class="d-flex align-items-center" @click="addItems()">
                        <i class="pi pi-plus me-1"></i>
                        Tambah Barang
                    </div>
                </button>
            </div>
            <hr class="mt-4" />
            <h5 class="fw-bold mb-0">Hasil</h5>
            <div class="table-responsive-custom">
                <table class="result not-read-only table table-bordered mt-3">
                    <thead class="table-light">
                        <tr>
                            <th scope="col" class="fw-medium">
                                Berat Aktual (Kg)
                            </th>
                            <th scope="col" class="fw-medium">
                                Berat Volume (Kg)
                            </th>
                            <th scope="col" class="fw-medium">
                                Kubikasi (Kg3)
                            </th>
                            <th scope="col" class="fw-medium">
                                Charge Weight (Kg)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="form-box">
                                    {{ decimalFormat(totalWeight) }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box">
                                    {{ decimalFormat(totalVolume) }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box">
                                    {{ decimalFormat(totalCubic) }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box green">
                                    {{ decimalFormat(chargeWeight) }}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr />
            <label class="fw-bold mb-2">Catatan</label>
            <textarea
                class="form-control"
                v-model="detail.note"
                placeholder="Masukkan catatan untuk barang"
            ></textarea>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <button
                        class="btn btn-primary px-5 btn-lg"
                        @click="modalSuccessConfirmation = true"
                        :disabled="isDisabled"
                    >
                        Simpan Data
                    </button>
                </div>
            </div>
        </div>
        <div class="box mt-4" v-else>
            <h5 class="fw-bold mb-0">Hasil Ukur - Timbang Barang</h5>
            <div class="table-responsive-custom">
                <table class="table table-bordered mt-4">
                    <thead class="table-light">
                        <tr>
                            <th class="text-center" scope="col">No</th>
                            <th class="text-center" scope="col">Jumlah Koli</th>
                            <th class="text-center" scope="col">Deskripsi</th>
                            <th class="text-center" scope="col">
                                Dimensi Barang (Cm)
                            </th>
                            <th class="text-center" scope="col">Berat (Kg)</th>
                            <th class="text-center" scope="col">
                                Berat Volume (Kg)
                            </th>
                            <th class="text-center" scope="col">
                                Kubikasi (Kg3)
                            </th>
                            <th class="text-center" scope="col">
                                Perlindungan
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-center">{{ item.qty }}</td>
                            <td>{{ item.description }}</td>
                            <td class="text-center">
                                {{ item.width }} Cm X {{ item.length }} Cm X
                                {{ item.height }} Cm
                            </td>
                            <td
                                :class="{
                                    'bc-green':
                                        customRound(item.weight) >
                                        customRound(item.weight_volume),
                                    'text-center': true,
                                }"
                            >
                                {{ customRound(item.weight) }} Kg
                            </td>
                            <td
                                :class="{
                                    'bc-green':
                                        customRound(item.weight_volume) >
                                        customRound(item.weight),
                                    'text-center': true,
                                }"
                            >
                                {{ customRound(item.weight_volume) }} Kg
                            </td>
                            <td class="text-center">
                                {{ customRound(item.cubic) }} Kg3
                            </td>
                            <td class="capitalize">
                                <div
                                    v-for="(handling, index) in item.handling"
                                    :key="index"
                                >
                                    <span class="tag gray-new radius pb-0">
                                        {{ handling.name }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td class="p-footer" colspan="4">
                                <div class="pull-right fw-bold">Total</div>
                            </td>
                            <td class="text-center fw-bold p-footer">
                                {{ customRound(totalWeightDefault) }} Kg
                            </td>
                            <td class="text-center fw-bold p-footer">
                                {{ customRound(totalVolumeDefault) }} Kg
                            </td>
                            <td class="text-center fw-bold p-footer">
                                {{ customRound(totalCubicDefault) }} Kg3
                            </td>
                            <td class="p-footer"></td>
                        </tr>
                        <tr>
                            <td class="p-footer" colspan="4">
                                <div class="pull-right fw-bold">
                                    Charge Weight (Kg)
                                </div>
                            </td>
                            <td
                                class="text-center bc-green p-footer"
                                colspan="4"
                            >
                                {{ customRound(chargeWeightDefault) }} Kg
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr />
            <label class="fw-bold mb-2">Catatan</label>
            <textarea
                class="form-control"
                v-model="detail.note"
                disabled
            ></textarea>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalDelete"></div>
            <div class="modal-body-chat vsm" v-if="modalDelete">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">Data Ini Akan Dihapus</h5>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin menghapus data ukur-timbang
                        barang ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalDelete = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="deleteItem()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                        >
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessConfirmation"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessConfirmation">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Data Ukur-Timbang Barang Akan Disimpan
                    </h5>
                    <div class="text-gray-dashboard">
                        Pastikan data orderan dan driver yang dipilih sudah
                        benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalSuccessConfirmation = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="storeData()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_save"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_save"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Data Ukur-Timbang Barang Berhasil Disimpan
                    </h5>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="goBackListDriver()"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </a>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalProtection">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <h5 class="mb-3">Pilih Perlindungan Barang</h5>
                <div
                    v-for="protection in protectionOptions"
                    :key="protection.name"
                    class="my-3"
                >
                    <div
                        class="d-flex justify-content-between align-items-center mb-3"
                    >
                        <div class="d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="me-3 cursor-pointer custom-checkbox"
                                :value="protection"
                                :checked="
                                    selectedModalProtections.some(
                                        (p) => p.name === protection.name
                                    )
                                "
                                @change="toggleProtection(protection)"
                                :id="protection.name"
                            />
                            <label
                                :for="protection.name"
                                class="cursor-pointer"
                            >
                                {{ protection.name }}
                            </label>
                        </div>
                        {{
                            currency(
                                calculatePackingCost(protection.type, {
                                    P: Number(
                                        items[currentItemIndex].length.replace(
                                            ".",
                                            ""
                                        )
                                    ),
                                    L: Number(
                                        items[currentItemIndex].width.replace(
                                            ".",
                                            ""
                                        )
                                    ),
                                    T: Number(
                                        items[currentItemIndex].height.replace(
                                            ".",
                                            ""
                                        )
                                    ),
                                    K: Number(
                                        items[currentItemIndex].item.replace(
                                            ".",
                                            ""
                                        )
                                    ),
                                })
                            )
                        }}
                    </div>
                    <hr />
                </div>
                <div class="d-flex justify-content-end">
                    <button
                        class="btn btn-primary px-5"
                        @click="saveProtections()"
                    >
                        Simpan
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../api/Api";
import helpers from "@/helpers/index.js";

export default {
    data() {
        return {
            items: [
                {
                    item: "",
                    description: "",
                    weight: "",
                    length: "",
                    width: "",
                    height: "",
                    volume: 0,
                    cubic: 0,
                    weight_volume: "",
                    handling: [],
                },
            ],
            protectionOptions: [
                {
                    name: "Wrapping Plastic",
                    type: "wrappingPlastic",
                    value: "wrapping_plastic",
                    price: 5000,
                },
                {
                    name: "Pallet",
                    type: "pallet",
                    value: "pallet",
                    price: 60000,
                },
                {
                    name: "Bubble Wrap",
                    type: "bubbleWrap",
                    value: "bubble_wrap",
                    price: 10000,
                },
                {
                    name: "Kardus",
                    type: "kardus",
                    value: "kardus",
                    price: 10000,
                },
                {
                    name: "Kayu",
                    type: "kayu",
                    value: "kayu",
                    price: 60000,
                },
                {
                    name: "Karung Kecil",
                    type: "karungKecil",
                    value: "karung_kecil",
                    price: 7500,
                },
                {
                    name: "Karung Sedang",
                    type: "karungSedang",
                    value: "karung_sedang",
                    price: 10000,
                },
                {
                    name: "Karung Besar",
                    type: "karungBesar",
                    value: "karung_besar",
                    price: 12500,
                },
            ],
            selectedModalProtections: [],
            modalProtection: false,
            currentItemIndex: null,
            selectedProtections: [],
            delete_value: null,
            modalDelete: false,
            detail: {},
            is_loading_data: false,
            is_save: false,
            modalSuccessConfirmation: false,
            modalSuccess: false,
        };
    },
    watch: {
        items: {
            deep: true,
            handler(items) {
                items.forEach((item) => {
                    if (
                        item.description ||
                        item.weight > 0 ||
                        item.length > 0 ||
                        item.width > 0 ||
                        item.height > 0 ||
                        item.handling.length > 0
                    ) {
                        if (item.item === "") {
                            item.item = "1";
                        }
                    }
                });
            },
        },
    },
    computed: {
        totalWeightDefault() {
            return this.items.reduce(
                (total, item) =>
                    total +
                    Math.round(Number(String(item.weight).replace(/\./g, ""))),
                0
            );
        },
        totalVolumeDefault() {
            return this.items.reduce(
                (total, item) =>
                    total +
                    Math.round(
                        Number(String(item.weight_volume).replace(/\./g, ""))
                    ),
                0
            );
        },
        totalCubicDefault() {
            return this.items.reduce(
                (total, item) =>
                    total +
                    Math.round(Number(String(item.cubic).replace(/\./g, ""))),
                0
            );
        },
        chargeWeightDefault() {
            return this.items.reduce((total, item) => {
                return (
                    total +
                    Math.max(
                        Math.round(
                            Number(String(item.weight).replace(/\./g, ""))
                        ),
                        Math.round(
                            Number(
                                String(item.weight_volume).replace(/\./g, "")
                            )
                        )
                    )
                );
            }, 0);
        },
        isDisabled() {
            const areItemsComplete =
                this.items.length > 0 &&
                this.items.every(
                    (item) =>
                        item.description !== "" &&
                        item.weight !== "" &&
                        item.length !== "" &&
                        item.width !== "" &&
                        item.height !== "" &&
                        item.volume !== ""
                );
            return !areItemsComplete;
        },
        packing_price() {
            return this.items.reduce((total, item) => {
                const itemHandlingPrice = item.handling.reduce(
                    (sum, handling) => sum + (handling.price || 0),
                    0
                );
                return total + itemHandlingPrice;
            }, 0);
        },
        totalWeight() {
            return this.items.reduce(
                (total, item) =>
                    total +
                    Number(String(item.item).replace(/\./g, "")) *
                        Math.round(
                            Number(String(item.weight).replace(/\./g, ""))
                        ),
                0
            );
        },
        totalVolume() {
            return this.items.reduce(
                (total, item) =>
                    total +
                    Number(String(item.item).replace(/\./g, "")) *
                        Math.round(
                            Number(String(item.volume).replace(/\./g, ""))
                        ),
                0
            );
        },
        totalCubic() {
            return this.items.reduce(
                (total, item) =>
                    total +
                    Number(String(item.item).replace(/\./g, "")) *
                        Math.round(
                            Number(String(item.cubic).replace(/\./g, ""))
                        ),
                0
            );
        },
        chargeWeight() {
            return Math.max(this.totalWeight, this.totalVolume);
        },
    },
    created() {
        this.getDetail();
    },
    methods: {
        getDetail() {
            const param = this.$route.query;
            this.is_loading_data = true;
            var data = {
                delivery_assignation_id: param.delivery_assignation_id,
            };
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/calculate/item/detail`,
                {
                    params: data,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    const data = res?.data?.data;
                    this.detail.customer_name = data?.customer_name;
                    this.detail.origin = data?.origin;
                    this.detail.specialist = data?.specialist;
                    this.detail.description = data?.description;
                    this.detail.destination = data?.destination;
                    this.detail.deal_time = data?.deal_time;
                    this.detail.is_calculate = data?.is_calculate;
                    this.detail.note = data?.note;
                    this.items =
                        data?.items?.length > 0
                            ? data.items.map((item) => ({
                                  item: String(item.qty).replace(/\./g, ""),
                                  description: item.description,
                                  weight_volume: String(
                                      item.weight_volume
                                  ).replace(/\./g, ""),
                                  weight: String(item.weight).replace(
                                      /\./g,
                                      ""
                                  ),
                                  length: String(item.length).replace(
                                      /\./g,
                                      ""
                                  ),
                                  width: String(item.width).replace(/\./g, ""),
                                  height: String(item.height).replace(
                                      /\./g,
                                      ""
                                  ),
                                  volume: item.weight_volume
                                      ? String(item.weight_volume).replace(
                                            /\./g,
                                            ""
                                        )
                                      : "0",
                                  cubic: item.cubic
                                      ? String(item.cubic).replace(/\./g, "")
                                      : "0",
                                  handling:
                                      item.packing?.length > 0
                                          ? item.packing.map((h) => ({
                                                name: h.packing
                                                    .replace(/_/g, " ")
                                                    .replace(/\b\w/g, (char) =>
                                                        char.toUpperCase()
                                                    ),
                                                price: h.price,
                                                type: this.protectionOptions.find(
                                                    (p) => p.value === h.packing
                                                )?.type,
                                            }))
                                          : [],
                              }))
                            : [
                                  {
                                      item: "",
                                      description: "",
                                      weight: "",
                                      length: "",
                                      width: "",
                                      height: "",
                                      volume: 0,
                                      cubic: 0,
                                      weight_volume: "",
                                      handling: [],
                                  },
                              ];

                    this.is_loading_data = false;

                    this.calculateResult();
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.is_loading_data = false;
                });
        },
        goBackListDriver() {
            this.$router.push({
                name: "tally-driver",
                query: {
                    driver_fleet_id: this.$route.query.driver_fleet_id,
                    date_offload: this.$route.query.date_offload,
                },
            });
        },
        addItems() {
            this.items.push({
                item: "",
                description: "",
                weight: "",
                length: "",
                width: "",
                height: "",
                volume: 0,
                cubic: 0,
                weight_volume: "",
                handling: [],
            });
        },
        openProtectionModal(index) {
            this.currentItemIndex = index;
            this.selectedModalProtections = this.items[index].handling.map(
                (p) => ({ ...p })
            );
            this.modalProtection = true;
        },
        saveProtections() {
            if (this.currentItemIndex !== null) {
                const currentItem = this.items[this.currentItemIndex];
                const length = Number(currentItem.length.replace(".", ""));
                const width = Number(currentItem.width.replace(".", ""));
                const height = Number(currentItem.height.replace(".", ""));
                const qty = Number(currentItem.item.replace(".", ""));

                const protectionsWithPrices = this.selectedModalProtections.map(
                    (protection) => {
                        const protectionDetails = this.protectionOptions.find(
                            (p) => p.name === protection.name
                        );
                        const price = helpers.calculatePackingCost(
                            protectionDetails.type,
                            {
                                P: length,
                                L: width,
                                T: height,
                                K: qty,
                            }
                        );
                        return {
                            ...protectionDetails,
                            price: price,
                        };
                    }
                );

                this.items[this.currentItemIndex].handling =
                    protectionsWithPrices;
            }
            this.modalProtection = false;
            this.selectedModalProtections = [];
        },
        toggleProtection(protection) {
            const existingIndex = this.selectedModalProtections.findIndex(
                (p) => p.name === protection.name
            );

            if (existingIndex !== -1) {
                this.selectedModalProtections.splice(existingIndex, 1);
            } else {
                this.selectedModalProtections.push(protection);
            }
        },
        showModalDeleteItem(index) {
            this.modalDelete = true;
            this.delete_value = index;
        },
        deleteItem() {
            this.items.splice(this.delete_value, 1);
            this.modalDelete = false;
        },
        calculateResult() {
            this.items.forEach((val) => {
                const length = Number(val.length.replace(".", "")) || "";
                const width = Number(val.width.replace(".", "")) || "";
                const height = Number(val.height.replace(".", "")) || "";
                const qty = Number(val.item.replace(".", "")) || "";

                if (length !== 0 && width !== 0 && height !== 0) {
                    val.volume = Math.round(
                        (length * width * height) / 4000
                    ).toLocaleString("id-ID");
                    val.cubic = Math.round(
                        (length * width * height) / 1000000
                    ).toLocaleString("id-ID");

                    val.handling = val.handling.map((protection) => {
                        const newPrice = helpers.calculatePackingCost(
                            protection.type,
                            {
                                P: length,
                                L: width,
                                T: height,
                                K: qty,
                            }
                        );
                        return {
                            ...protection,
                            price: newPrice,
                        };
                    });
                } else {
                    val.volume = "0";
                    val.cubic = "0";
                }
            });
        },
        storeData() {
            this.is_save = true;
            const itemsData = this.items.map((item) => ({
                charge_weight: this.chargeWeight,
                note: this.detail.note,
                delivery_assignation_id:
                    this.$route.query.delivery_assignation_id,
                qty: item.item,
                description: item.description,
                weight: item.weight,
                length: item.length,
                width: item.width,
                height: item.height,
                weight_volume: item.volume,
                cubic: item.cubic,
                protection:
                    item.handling.length > 0
                        ? item.handling.map((h) => ({
                              packing: h.name
                                  .toLowerCase()
                                  .replace(/\s+/g, "_"),
                              price: Math.round(h.price),
                          }))
                        : null,
            }));

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/calculate/submit`,
                itemsData,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.is_save = false;
                    this.modalSuccess = true;
                    this.modalSuccessConfirmation = false;
                })
                .catch((err) => {
                    this.is_save = false;
                    this.modalSuccessConfirmation = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style scoped>
.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #c1cdd7;
    background-color: white;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
}

.custom-checkbox:checked {
    border: 1px solid #329a5f;
    background-color: #d9f2e4;
}

.custom-checkbox:checked::after {
    content: "✔";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #329a5f;
    font-size: 14px;
}
.w-430 {
    width: 430px;
}
textarea {
    resize: none;
}
hr.modal-hr {
    color: #c1cdd7 !important;
    margin: 0;
}
.modal-vue .modal-body-chat.vsm {
    width: 450px;
}
.btn-add {
    color: #40bf76 !important;
    background-color: transparent !important;
    border: 1px solid #40bf76 !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.w-30 {
    width: 30px;
}
.w-120 {
    width: 120px;
}
.not-read-only.table > :not(caption) > * > * {
    border: unset;
}
.not-read-only.table-bordered > :not(caption) > * {
    border: unset;
}
.not-read-only.table > :not(caption) > * > * {
    background-color: unset;
}
tr {
    border-radius: 10px !important;
}
tr td.custom {
    background-color: #ebf6ff !important;
}
.w-handling {
    width: 300px;
}
table tbody tr:first-child td:first-child {
    border-top-left-radius: 6px;
}

table tbody tr:first-child td:last-child {
    border-top-right-radius: 6px;
}

table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
}

table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
}
.padding-body::after {
    height: 10px;
    display: table-row;
    content: "";
}
.result.table > :not(caption) > * > * {
    padding: 0.2rem 0.5rem;
}
.form-control.green {
    background: #d9f2e4;
    border: 1px solid #8cd9ad !important;
    color: #329a5f !important;
}
.form-control.green[disabled] {
    background: #d9f2e4;
    border: 1px solid #8cd9ad !important;
    color: #329a5f !important;
}
.w-desc {
    width: 160px;
}
.w-koli {
    width: 60px;
}
.radius-select {
    border-radius: 10px;
    padding: 8px 12px;
}
.w-300 {
    width: 300px;
}
.scroll-select {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}
.dropdown-menu {
    padding: 5px 10px 0px 10px;
}
.form-check-input:checked {
    background-color: #329a5f;
    border: #329a5f;
}
div::-webkit-scrollbar {
    display: none;
}
td.bc-green {
    background-color: #d9f2e4;
    color: #329a5f;
    font-weight: 600 !important;
}
</style>
