<template>
    <div class="padding-container">
        <div class="content-load" v-if="detail_loading"></div>
        <div id="loader" v-if="detail_loading"></div>
        <div class="fw-bold">
            <router-link to="/walk-in" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </router-link>
        </div>
        <div class="box-manifest mt-3">
            <h5 class="mb-0 fw-bold">Data Customer</h5>
            <div class="row mt-4">
                <div class="col-lg-6">
                    <label class="fw-bold mb-1">
                        Nama Customer
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nama pengirim"
                        v-model="req.sender_name"
                    />
                </div>
                <div class="col-lg-6">
                    <label class="fw-bold mb-1">
                        Nomor Handphone Pengirim
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span class="input-group-text">+62</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nomor pengirim"
                            @input="validatePhoneSender"
                            v-model="req.sender_phone"
                        />
                    </div>
                </div>
                <div class="col-lg-6 mt-3">
                    <label class="fw-bold mb-1">
                        Kota/Kabupaten Asal
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="req.originRegency"
                        :options="regencies"
                        label="name"
                        track-by="id"
                        placeholder="Cari kota/kabupaten asal"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-6 mt-3">
                    <label class="fw-bold mb-1">
                        Kota/Kabupaten Tujuan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="req.destRegency"
                        :options="regencies"
                        label="name"
                        track-by="id"
                        placeholder="Cari kota/kabupaten tujuan"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
            </div>
        </div>
        <div class="box-manifest mt-3">
            <h5 class="fw-bold mb-0">Daftar Barang</h5>
            <div class="table-responsive-custom">
                <table class="table table-bordered mt-3">
                    <thead class="table-light">
                        <tr>
                            <th class="fw-medium">Koli</th>
                            <th class="fw-medium">
                                Deskripsi<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Berat (Kg)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Panjang (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Lebar (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Tinggi (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Berat Volume (Kg)<span class="text-danger"
                                    >*</span
                                >
                            </th>
                            <th class="fw-medium">
                                Kubikasi (Kg3)<span class="text-danger">*</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody
                        class="padding-body"
                        v-for="(item, index) in items"
                        :key="index"
                    >
                        <tr>
                            <td class="custom">
                                <input
                                    type="text"
                                    v-model="item.item"
                                    placeholder="Koli"
                                    v-number-format="item.item"
                                    class="form-control w-koli"
                                    @input="calculateResult()"
                                />
                            </td>
                            <td class="custom">
                                <input
                                    type="text"
                                    class="form-control w-desc"
                                    placeholder="Deskripsi"
                                    v-model="item.description"
                                />
                            </td>
                            <td class="custom">
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="item.weight"
                                    v-bind:class="{
                                        green:
                                            Number(item.weight) >
                                            Number(item.volume),
                                    }"
                                    v-number-format="item.weight"
                                    placeholder="Berat"
                                />
                            </td>
                            <td class="custom">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="P"
                                    @input="calculateResult()"
                                    v-model="item.length"
                                    v-number-format="item.length"
                                />
                            </td>
                            <td class="custom">
                                <input
                                    type="text"
                                    v-model="item.width"
                                    class="form-control"
                                    placeholder="L"
                                    @input="calculateResult()"
                                    v-number-format="item.width"
                                />
                            </td>
                            <td class="custom">
                                <input
                                    type="text"
                                    v-model="item.height"
                                    class="form-control"
                                    placeholder="T"
                                    @input="calculateResult()"
                                    v-number-format="item.height"
                                />
                            </td>
                            <td class="custom">
                                <input
                                    type="text"
                                    v-model="item.volume"
                                    class="form-control"
                                    v-bind:class="{
                                        green:
                                            Number(item.volume) >
                                            Number(item.weight),
                                    }"
                                    disabled
                                />
                            </td>
                            <td class="custom">
                                <div class="d-flex align-items-center">
                                    <input
                                        type="text"
                                        v-model="item.cubic"
                                        class="form-control me-2"
                                        disabled
                                    />
                                    <a
                                        href="javascript:void(0)"
                                        @click="showModalDeleteItem(index)"
                                    >
                                        <img
                                            src="../assets/trash-yellow.png"
                                            width="16"
                                            lt=""
                                        />
                                    </a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="custom" colspan="8">
                                <div class="w-430">
                                    <div
                                        class="d-flex justify-content-between align-items-center"
                                    >
                                        <label class="fw-medium"
                                            >Perlindungan Barang</label
                                        >
                                        <button
                                            class="btn btn-add d-flex align-items-center"
                                            @click="openProtectionModal(index)"
                                            v-if="item.handling.length === 0"
                                        >
                                            <img
                                                src="../assets/plus.svg"
                                                alt="plus"
                                                class="me-2"
                                            />Tambah
                                        </button>
                                        <button
                                            class="btn btn-add d-flex align-items-center"
                                            @click="openProtectionModal(index)"
                                            v-else
                                        >
                                            <img
                                                src="../assets/pencil.svg"
                                                alt="pencil"
                                                class="me-2"
                                            />Edit
                                        </button>
                                    </div>
                                    <input
                                        type="text"
                                        class="form-control mt-3"
                                        placeholder="Belum ada perlindungan barang ..."
                                        readonly
                                        v-if="item.handling.length === 0"
                                    />
                                    <div v-else class="form-control mt-3">
                                        <div
                                            v-for="protection in item.handling"
                                            :key="protection.name"
                                            class="d-flex justify-content-between my-1"
                                        >
                                            <p class="mb-0">
                                                {{ protection.name }}
                                            </p>
                                            <p class="mb-0">
                                                {{ currency(protection.price) }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <button class="btn btn-green px-4">
                    <div class="d-flex align-items-center" @click="addItems()">
                        <i class="pi pi-plus me-1"></i>
                        Tambah Barang
                    </div>
                </button>
            </div>
            <hr class="mt-4" />
            <h5 class="fw-bold mb-0">Hasil</h5>
            <div class="table-responsive-custom">
                <table class="result table table-bordered mt-3">
                    <thead class="table-light">
                        <tr>
                            <th scope="col" class="fw-medium">
                                Berat Aktual (Kg)
                            </th>
                            <th scope="col" class="fw-medium">
                                Berat Volume (Kg)
                            </th>
                            <th scope="col" class="fw-medium">
                                Kubikasi (Kg3)
                            </th>
                            <th scope="col" class="fw-medium">
                                Charge Weight (Kg)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="form-box">
                                    {{ decimalFormat(totalWeight) }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box">
                                    {{ decimalFormat(totalVolume) }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box">
                                    {{ decimalFormat(totalCubic) }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box green">
                                    {{ decimalFormat(chargeWeight) }}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr />
            <label class="fw-bold mb-2">Catatan</label>
            <textarea
                class="form-control"
                v-model="req.note"
                placeholder="Masukkan catatan untuk barang"
            ></textarea>
            <div class="d-flex justify-content-end">
                <button
                    class="btn btn-primary px-5 mt-4"
                    @click="modalSuccessConfirmation = true"
                    :disabled="isDisabled"
                >
                    Simpan Perubahan
                </button>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalDelete"></div>
            <div class="modal-body-chat vsm" v-if="modalDelete">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">Data Ini Akan Dihapus</h5>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin menghapus data ukur-timbang
                        barang ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalDelete = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            @click="deleteItem()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                        >
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessConfirmation"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessConfirmation">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">Pesanan Akan Dibuat</h5>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin membuat pesanan ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalSuccessConfirmation = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            @click="storeData()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_save"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_save"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">Pesanan Berhasil Dibuat</h5>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/walk-in"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalProtection">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <h5 class="mb-3">Pilih Perlindungan Barang</h5>
                <div
                    v-for="protection in protectionOptions"
                    :key="protection.name"
                    class="my-3"
                >
                    <div
                        class="d-flex justify-content-between align-items-center mb-3"
                    >
                        <div class="d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="me-3 cursor-pointer custom-checkbox"
                                :value="protection"
                                :checked="
                                    selectedModalProtections.some(
                                        (p) => p.name === protection.name
                                    )
                                "
                                @change="toggleProtection(protection)"
                                :id="protection.name"
                            />
                            <label
                                :for="protection.name"
                                class="cursor-pointer"
                            >
                                {{ protection.name }}
                            </label>
                        </div>
                        {{
                            currency(
                                calculatePackingCost(protection.type, {
                                    P: Number(
                                        items[currentItemIndex].length.replace(
                                            ".",
                                            ""
                                        )
                                    ),
                                    L: Number(
                                        items[currentItemIndex].width.replace(
                                            ".",
                                            ""
                                        )
                                    ),
                                    T: Number(
                                        items[currentItemIndex].height.replace(
                                            ".",
                                            ""
                                        )
                                    ),
                                    K: Number(
                                        items[currentItemIndex].item.replace(
                                            ".",
                                            ""
                                        )
                                    ),
                                })
                            )
                        }}
                    </div>
                    <hr />
                </div>
                <div class="d-flex justify-content-end">
                    <button
                        class="btn btn-primary px-5"
                        @click="saveProtections()"
                    >
                        Simpan
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Api from "../api/Api";
import helpers from "@/helpers/index.js";

export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            detail_loading: false,
            protectionOptions: [
                {
                    name: "Wrapping Plastic",
                    type: "wrappingPlastic",
                    value: "wrapping_plastic",
                    price: 5000,
                },
                {
                    name: "Pallet",
                    type: "pallet",
                    value: "pallet",
                    price: 60000,
                },
                {
                    name: "Bubble Wrap",
                    type: "bubbleWrap",
                    value: "bubble_wrap",
                    price: 10000,
                },
                {
                    name: "Kardus",
                    type: "kardus",
                    value: "kardus",
                    price: 10000,
                },
                {
                    name: "Kayu",
                    type: "kayu",
                    value: "kayu",
                    price: 60000,
                },
                {
                    name: "Karung Kecil",
                    type: "karungKecil",
                    value: "karung_kecil",
                    price: 7500,
                },
                {
                    name: "Karung Sedang",
                    type: "karungSedang",
                    value: "karung_sedang",
                    price: 10000,
                },
                {
                    name: "Karung Besar",
                    type: "karungBesar",
                    value: "karung_besar",
                    price: 12500,
                },
            ],
            selectedModalProtections: [],
            modalProtection: false,
            currentItemIndex: null,
            selectedProtections: [],
            items: [
                {
                    item: "",
                    description: "",
                    weight: "",
                    length: "",
                    width: "",
                    height: "",
                    volume: 0,
                    cubic: 0,
                    handling: [],
                },
            ],
            regencies: [],
            req: {
                sender_name: "",
                sender_phone: "",
                originRegency: null,
                destRegency: null,
                note: "",
            },
            delete_value: null,
            modalDelete: false,
            is_save: false,
            modalSuccessConfirmation: false,
            modalSuccess: false,
        };
    },
    watch: {
        items: {
            deep: true,
            handler(items) {
                items.forEach((item) => {
                    if (
                        item.description ||
                        item.weight > 0 ||
                        item.length > 0 ||
                        item.width > 0 ||
                        item.height > 0 ||
                        item.handling.length > 0
                    ) {
                        if (item.item === "") {
                            item.item = "1";
                        }
                    }
                });
            },
        },
    },
    created() {
        this.getRegency()
            .then(() => {
                this.getOrderDetail();
            })
            .catch((err) => {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: err.response.data.message,
                });
            });
    },
    directives: {
        autofocus: {
            inserted: (el) => {
                el.focus();
            },
        },
    },
    computed: {
        isDisabled() {
            const isSenderInfoComplete =
                this.req.sender_name &&
                this.req.sender_phone &&
                this.req.originRegency &&
                this.req.destRegency;

            const areItemsComplete =
                this.items.length > 0 &&
                this.items.every(
                    (item) =>
                        item.description !== "" &&
                        item.weight !== "" &&
                        item.length !== "" &&
                        item.width !== "" &&
                        item.height !== "" &&
                        item.volume !== ""
                );
            return !(isSenderInfoComplete && areItemsComplete);
        },
        packing_price() {
            return this.items.reduce((total, item) => {
                const itemHandlingPrice = item.handling.reduce(
                    (sum, handling) => sum + (handling.price || 0),
                    0
                );
                return total + itemHandlingPrice;
            }, 0);
        },
        totalWeight() {
            return this.items.reduce(
                (total, item) =>
                    total +
                    Number(String(item.item).replace(/\./g, "")) *
                        Math.round(
                            Number(String(item.weight).replace(/\./g, ""))
                        ),
                0
            );
        },
        totalVolume() {
            return this.items.reduce(
                (total, item) =>
                    total +
                    Number(String(item.item).replace(/\./g, "")) *
                        Math.round(
                            Number(String(item.volume).replace(/\./g, ""))
                        ),
                0
            );
        },
        totalCubic() {
            return this.items.reduce(
                (total, item) =>
                    total +
                    Number(String(item.item).replace(/\./g, "")) *
                        Math.round(
                            Number(String(item.cubic).replace(/\./g, ""))
                        ),
                0
            );
        },
        chargeWeight() {
            return Math.max(this.totalWeight, this.totalVolume);
        },
    },
    methods: {
        getOrderDetail() {
            this.detail_loading = true;
            const orderId = this.$route.query.order_id;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/order/walkin/detail?order_id=${orderId}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    const data = res?.data?.data;
                    this.req.note = data?.note;
                    this.req.sender_name = data?.sender_name;
                    this.req.sender_phone = data?.sender_phone;
                    this.req.originRegency = this.regencies.find(
                        (r) => r.id === data?.origin_regency_id
                    );
                    this.req.destRegency = data?.dest_regency_id
                        ? this.regencies.find(
                              (r) => r.id === data?.dest_regency_id
                          )
                        : null;
                    this.items =
                        data?.items?.length > 0
                            ? data.items.map((item) => ({
                                  item: String(item.qty).replace(/\./g, ""),
                                  description: item.description,
                                  weight: String(item.weight).replace(
                                      /\./g,
                                      ""
                                  ),
                                  length: String(item.length).replace(
                                      /\./g,
                                      ""
                                  ),
                                  width: String(item.width).replace(/\./g, ""),
                                  height: String(item.height).replace(
                                      /\./g,
                                      ""
                                  ),
                                  volume: item.weight_volume
                                      ? String(item.weight_volume).replace(
                                            /\./g,
                                            ""
                                        )
                                      : "0",
                                  cubic: item.cubic
                                      ? String(item.cubic).replace(/\./g, "")
                                      : "0",
                                  handling:
                                      item.packing?.length > 0
                                          ? item.packing.map((h) => ({
                                                name: h.packing
                                                    .replace(/_/g, " ")
                                                    .replace(/\b\w/g, (char) =>
                                                        char.toUpperCase()
                                                    ),
                                                price: h.price,
                                                type: this.protectionOptions.find(
                                                    (p) => p.value === h.packing
                                                )?.type,
                                            }))
                                          : [],
                              }))
                            : [
                                  {
                                      item: "",
                                      description: "",
                                      weight: "",
                                      length: "",
                                      width: "",
                                      height: "",
                                      volume: 0,
                                      cubic: 0,
                                      handling: [],
                                  },
                              ];

                    this.detail_loading = false;

                    this.calculateResult();
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.detail_loading = false;
                });
        },
        validatePhoneSender() {
            if (!this.req.sender_phone.startsWith("8")) {
                this.req.sender_phone =
                    "8" + this.req.sender_phone.replace(/[^0-9]/g, "");
            } else {
                this.req.sender_phone = this.req.sender_phone.replace(
                    /[^0-9]/g,
                    ""
                );
            }
        },
        getRegency() {
            return Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.regencies = data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    throw err;
                });
        },
        addItems() {
            this.items.push({
                item: "",
                description: "",
                weight: "",
                length: "",
                width: "",
                height: "",
                volume: 0,
                cubic: 0,
                handling: [],
            });
        },
        openProtectionModal(index) {
            this.currentItemIndex = index;
            this.selectedModalProtections = this.items[index].handling.map(
                (p) => ({ ...p })
            );
            this.modalProtection = true;
        },
        saveProtections() {
            if (this.currentItemIndex !== null) {
                const currentItem = this.items[this.currentItemIndex];
                const length = Number(currentItem.length.replace(".", ""));
                const width = Number(currentItem.width.replace(".", ""));
                const height = Number(currentItem.height.replace(".", ""));
                const qty = Number(currentItem.item.replace(".", ""));

                const protectionsWithPrices = this.selectedModalProtections.map(
                    (protection) => {
                        const protectionDetails = this.protectionOptions.find(
                            (p) => p.name === protection.name
                        );
                        const price = helpers.calculatePackingCost(
                            protectionDetails.type,
                            {
                                P: length,
                                L: width,
                                T: height,
                                K: qty,
                            }
                        );
                        return {
                            ...protectionDetails,
                            price: price,
                        };
                    }
                );

                this.items[this.currentItemIndex].handling =
                    protectionsWithPrices;
            }
            this.modalProtection = false;
            this.selectedModalProtections = [];
        },
        toggleProtection(protection) {
            const existingIndex = this.selectedModalProtections.findIndex(
                (p) => p.name === protection.name
            );

            if (existingIndex !== -1) {
                this.selectedModalProtections.splice(existingIndex, 1);
            } else {
                this.selectedModalProtections.push(protection);
            }
        },
        showModalDeleteItem(index) {
            this.modalDelete = true;
            this.delete_value = index;
        },
        deleteItem() {
            this.items.splice(this.delete_value, 1);
            this.modalDelete = false;
        },
        calculateResult() {
            this.items.forEach((val) => {
                const length = Number(val.length.replace(".", "")) || "";
                const width = Number(val.width.replace(".", "")) || "";
                const height = Number(val.height.replace(".", "")) || "";
                const qty = Number(val.item.replace(".", "")) || "";

                if (length !== 0 && width !== 0 && height !== 0) {
                    val.volume = Math.round(
                        (length * width * height) / 4000
                    ).toLocaleString("id-ID");
                    val.cubic = Math.round(
                        (length * width * height) / 1000000
                    ).toLocaleString("id-ID");

                    val.handling = val.handling.map((protection) => {
                        const newPrice = helpers.calculatePackingCost(
                            protection.type,
                            {
                                P: length,
                                L: width,
                                T: height,
                                K: qty,
                            }
                        );
                        return {
                            ...protection,
                            price: newPrice,
                        };
                    });
                } else {
                    val.volume = "0";
                    val.cubic = "0";
                }
            });
        },
        storeData() {
            this.is_save = true;
            const itemsData = this.items.map((item) => ({
                qty: item.item,
                description: item.description,
                weight: item.weight,
                length: item.length,
                width: item.width,
                height: item.height,
                weight_volume: item.volume,
                cubic: item.cubic,
                protection:
                    item.handling.length > 0
                        ? item.handling.map((h) => ({
                              packing: h.name
                                  .toLowerCase()
                                  .replace(/\s+/g, "_"),
                              price: Math.round(h.price),
                          }))
                        : null,
            }));
            var data = {
                order_id: this.$route.query.order_id,
                sender_name: this.req.sender_name,
                sender_phone: this.req.sender_phone,
                origin_regency_id: this.req.originRegency?.id,
                destination_regency_id: this.req.destRegency?.id,
                items: itemsData,
                charge_weight: this.chargeWeight,
                note: this.req.note,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/order/walkin/update`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.is_save = false;
                    this.modalSuccess = true;
                    this.modalSuccessConfirmation = false;
                })
                .catch((err) => {
                    this.is_save = false;
                    this.modalSuccessConfirmation = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #c1cdd7;
    background-color: white;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
}

.custom-checkbox:checked {
    border: 1px solid #329a5f;
    background-color: #d9f2e4;
}

.custom-checkbox:checked::after {
    content: "✔";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #329a5f;
    font-size: 14px;
}
.w-430 {
    width: 430px;
}
textarea {
    resize: none;
}
hr.modal-hr {
    color: #c1cdd7 !important;
    margin: 0;
}
.modal-vue .modal-body-chat.vsm {
    width: 450px;
}
.btn-add {
    color: #40bf76 !important;
    background-color: transparent !important;
    border: 1px solid #40bf76 !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.w-30 {
    width: 30px;
}
.w-120 {
    width: 120px;
}
.table > :not(caption) > * > * {
    border: unset;
}
.table-bordered > :not(caption) > * {
    border: unset;
}
.table > :not(caption) > * > * {
    background-color: unset;
}
tr {
    border-radius: 10px !important;
}
tr td.custom {
    background-color: #ebf6ff !important;
}
.w-handling {
    width: 300px;
}
table tbody tr:first-child td:first-child {
    border-top-left-radius: 6px;
}

table tbody tr:first-child td:last-child {
    border-top-right-radius: 6px;
}

table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
}

table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
}
.padding-body::after {
    height: 10px;
    display: table-row;
    content: "";
}
.result.table > :not(caption) > * > * {
    padding: 0.2rem 0.5rem;
}
.form-control.green {
    background: #d9f2e4;
    border: 1px solid #8cd9ad !important;
    color: #329a5f !important;
}
.form-control.green[disabled] {
    background: #d9f2e4;
    border: 1px solid #8cd9ad !important;
    color: #329a5f !important;
}
.w-desc {
    width: 160px;
}
.w-koli {
    width: 60px;
}
.radius-select {
    border-radius: 10px;
    padding: 8px 12px;
}
.w-300 {
    width: 300px;
}
.scroll-select {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}
.dropdown-menu {
    padding: 5px 10px 0px 10px;
}
.form-check-input:checked {
    background-color: #329a5f;
    border: #329a5f;
}
div::-webkit-scrollbar {
    display: none;
}
.form-edit {
    border: unset !important;
    text-align: right;
    padding: 0px;
    font-weight: 600 !important;
    color: #000 !important;
    border-radius: unset !important;
}
.form-edit.yellow {
    color: #ff8600 !important;
}
</style>
