<template>
    <div class="padding-container">
        <div class="box-manifest">
            <h5 class="fw-bold">Daftar Route Management & Lead Time</h5>
            <div
                class="d-flex align-items-center justify-content-between mt-4 flex-column flex-lg-row"
            >
                <div class="mb-3 mb-lg-0 width-m-100">
                    <div class="position-relative">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search border-search"
                                placeholder="Cari kota asal/tujuan"
                                v-model="search"
                                @keyup.enter="searchRoute()"
                            />
                            <button
                                class="btn btn-primary px-4"
                                type="button"
                                @click="searchRoute()"
                            >
                                Cari
                            </button>
                        </div>
                        <span class="search-icon left">
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                </div>
                <router-link
                    to="/route-and-lead/add"
                    class="btn btn-primary px-3 mt-3 mt-lg-0 width-m-100"
                >
                    <i class="pi pi-plus me-2"></i>
                    Tambah Data
                </router-link>
            </div>
            <div class="row mt-4 align-items-center">
                <div
                    class="col-12 col-lg-6 mb-3 mb-lg-0 d-flex justify-content-lg-start justify-content-center"
                >
                    <div>
                        Menampilkan
                        <span class="fw-bold"
                            >{{ pageStart }}-{{ pageEnd }}</span
                        >
                        Data dari
                        <span class="fw-bold">{{ lists.total_data }}</span>
                        Data
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div
                        class="d-flex align-items-center justify-content-lg-end justify-content-center"
                    >
                        <div class="me-2">Baris per halaman</div>
                        <select
                            class="form-select outline-yellow"
                            style="width: auto"
                            v-model="limit"
                            @change="onLimitChange"
                        >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="isLoader">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Kota Asal
                                </th>
                                <th class="text-center" scope="col">
                                    Kota Tujuan
                                </th>
                                <th class="text-center" scope="col">
                                    Jenis Layanan
                                </th>
                                <th class="text-center" scope="col">
                                    Lead Time
                                </th>
                                <th class="text-center" scope="col">
                                    Harga Pengiriman
                                </th>
                                <th class="text-center" scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(route, index) in lists.list_data"
                                :key="index"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td>{{ route.regency }}</td>
                                <td>{{ route.dooring }}</td>
                                <td>
                                    <p
                                        class="rounded-pill service text-center mb-0 px-3"
                                    >
                                        {{
                                            route.service_type
                                                .replace(/_/g, " ")
                                                .replace(/\b\w/g, (char) =>
                                                    char.toUpperCase()
                                                )
                                        }}
                                    </p>
                                </td>
                                <td>{{ route.leadtime }} hari</td>
                                <td class="text-end">
                                    {{ currency(route.service_price) }}
                                </td>
                                <td class="text-center">
                                    <router-link
                                        :to="'/route-and-lead/' + route.id"
                                    >
                                        <img
                                            src="../assets/dashboard/edit-icon.png"
                                            width="20"
                                        />
                                    </router-link>
                                    <div
                                        class="ms-2 d-inline-block cursor-pointer"
                                        @click="openModalConfirm(route.id)"
                                    >
                                        <img
                                            src="../assets/dashboard/trash-icon.png"
                                            width="20"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    class="text-center mt-5"
                    v-if="lists.list_data.length === 0"
                >
                    <img src="../assets/no-data-green.png" width="100" alt="" />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada daftar route management & lead time
                    </div>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <b-pagination-nav
                        v-if="lists.list_data.length > 0"
                        v-model="currentPage"
                        :number-of-pages="totalPage"
                        base-url="#"
                        first-number
                        align="right"
                        @input="getLists()"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Data route & Lead Time akan dihapus
                    </h5>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin menghapus data ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="deleteData()"
                            :disabled="isLoading"
                        >
                            <span
                                v-if="isLoading"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Data route & Lead Time sudah dihapus
                    </h5>
                </div>
                <div class="mt-4">
                    <div
                        class="btn btn-primary w-100 btn-lg"
                        @click="
                            modalSuccess = false;
                            getLists();
                        "
                    >
                        Tutup
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import ListLoader from "../components/ListLoader.vue";

export default {
    components: {
        ListLoader,
    },
    data() {
        return {
            modalConfirm: false,
            modalSuccess: false,
            price: null,
            pageStart: 0,
            pageEnd: 0,
            limit: 5,
            totalPage: 0,
            currentPage: 1,
            search: null,
            isLoader: false,
            lists: {
                list_data: [],
            },
            deleteId: null,
            isLoading: false,
        };
    },
    created() {
        this.getLists();
    },
    methods: {
        openModalConfirm(value) {
            this.deleteId = value;
            this.modalConfirm = true;
        },
        searchRoute() {
            this.currentPage = 1;
            this.getLists();
        },
        deleteData() {
            this.isLoading = true;

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/ho/routes/delete`,
                { route_id: this.deleteId },
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isLoading = false;
                    this.modalConfirm = false;
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.modalConfirm = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        onLimitChange() {
            this.currentPage = 1;
            this.getLists();
        },
        getLists() {
            let payload = {
                page: this.currentPage,
                search: this.search ? this.search : null,
                limit: this.limit ? this.limit : null,
            };
            this.isLoader = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/ho/routes`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.isLoader = false;
                    this.lists = res.data.data;
                    this.totalPage = res.data.data.total_page;
                    const totalItems = this.lists.list_data.length;
                    const totalData = res.data.data.total_data;
                    this.pageStart =
                        totalItems === 0
                            ? 0
                            : (this.currentPage - 1) * this.limit + 1;
                    this.pageEnd = Math.min(
                        Number(this.pageStart) + Number(this.limit) - 1,
                        totalData
                    );
                })
                .catch((err) => {
                    this.isLoader = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.width-m-100 {
    width: 100%;
    @media (min-width: 992px) {
        width: fit-content;
    }
}
.table-responsive-custom {
    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th,
        td {
            border: 1px solid #c1cdd7;
        }
        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
.modal-vue {
    position: fixed;
    z-index: 9999;
}
hr {
    color: #dee2e6 !important;
    margin: 1.5rem 0;
}
.btn-green:disabled {
    background: #e0e6eb !important;
    border: none !important;
    color: #849bae !important;
}
td {
    background-color: transparent !important;
}
th {
    background-color: #f3f5f7 !important;
}
.service {
    background-color: #cce7ff !important;
    border: 1px solid #99d0ff !important;
    color: #005299 !important;
}
::v-deep .page-link {
    background-color: #f3f5f7 !important;
}
::v-deep .page-item.active .page-link {
    background-color: #1291ff !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.outline-yellow {
    border: 1px solid #40bf76 !important;
    background-color: transparent;
    color: #40bf76 !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
.outline-yellow:focus {
    border: 1px solid #40bf76 !important;
    background-color: transparent;
    color: #40bf76 !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
</style>
