<template>
    <div class="padding-container">
        <div class="fw-bold">
            <router-link to="/crm/profile" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Back
            </router-link>
        </div>
        <div class="box mt-4">
            <div
                class="crm-avatar"
                :style="{ 'background-image': 'url(' + req.avatar + ')' }"
            >
                <!-- <div class="crm-avatar" :style="{ 'background-image': 'url(' + require('../../assets/user-image.jpg') + ')' }"> -->
                <div class="mt-4">
                    <label for="avatar" class="upload-button single-image crm">
                        <i class="fa fa-upload"></i>
                        <input
                            type="file"
                            id="avatar"
                            @change="avatarUpload()"
                        />
                    </label>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-12">
                    <label class="mb-0 fw-bold">Full Name</label>
                    <input
                        type="text"
                        class="form-control mt-2"
                        placeholder="Full Name"
                        v-model="req.name"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="mb-0 fw-bold">Gender</label>
                    <select
                        class="form-select form-control mt-2"
                        v-model="req.gender"
                    >
                        <option value="">Select Gender</option>
                        <option value="male">Pria</option>
                        <option value="female">Wanita</option>
                    </select>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="mb-0 fw-bold">Role</label>
                    <input
                        type="text"
                        class="form-control mt-2"
                        placeholder="Role"
                        :value="req.position"
                        disabled
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="mb-0 fw-bold">Phone Number</label>
                    <input
                        type="number"
                        class="form-control mt-2"
                        placeholder="Phone Number"
                        v-model="req.phone"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="mb-0 fw-bold">Email</label>
                    <input
                        type="email"
                        class="form-control mt-2"
                        placeholder="Email"
                        v-model="req.email"
                    />
                </div>
                <div class="col-md-12 mt-3">
                    <label class="mb-0 fw-bold">Address</label>
                    <textarea
                        cols="30"
                        rows="3"
                        class="form-control mt-2"
                        placeholder="Address"
                        v-model="req.address"
                    ></textarea>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="mb-0 fw-bold">Status</label>
                    <div class="mt-2">
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                disabled
                                id="true"
                                :value="true"
                                v-model="req.is_active"
                            />
                            <label class="form-check-label fw-bold" for="true"
                                >Aktif</label
                            >
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                disabled
                                id="false"
                                :value="false"
                                v-model="req.is_active"
                            />
                            <label class="form-check-label fw-bold" for="false"
                                >Tidak Aktif</label
                            >
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-4">
                    <div class="pull-right">
                        <router-link
                            to="/crm/profile"
                            class="btn btn-outline-yellow btn-lg px-5 me-3"
                            >Cancel</router-link
                        >
                        <button
                            class="btn btn-primary btn-lg px-5"
                            type="button"
                            disabled
                            v-if="is_update"
                        >
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Update
                        </button>
                        <button
                            class="btn btn-primary btn-lg px-5"
                            @click="updateData()"
                            v-else
                        >
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">Perubahan Data Berhasil!</h5>
                    <div class="mt-2">Selamat! Anda berhasil merubah data.</div>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/crm/profile"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Ok
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import $ from "jquery";

export default {
    components: {},
    name: "CrmProfile",
    data() {
        return {
            is_changing_avatar: false,
            req: {},
            is_update: false,
            modalSuccess: false,
        };
    },
    created() {
        this.getProfile();
    },
    methods: {
        avatarUpload() {
            var avatar = new FileReader();
            avatar.onload = function (e) {
                $(".crm-avatar").css(
                    "background-image",
                    "url(" + e.target.result + ")"
                );
            };
            avatar.readAsDataURL($("#avatar")[0].files[0]);
            this.is_changing_avatar = true;
        },
        getProfile() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/account`, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.req = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        updateData() {
            var avatar = $("#avatar")[0].files[0];
            var data = new FormData();

            data.append("name", this.req.name);
            data.append("gender", this.req.gender);
            data.append("phone", this.req.phone);
            data.append("email", this.req.email);
            data.append("address", this.req.address);
            data.append("is_active", this.req.is_active);

            if (this.is_changing_avatar) {
                data.append("avatar", avatar);
            }

            this.is_update = true;

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/account/edit`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_update = false;
                    this.modalSuccess = true;
                    this.is_changing_avatar = false;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_update = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style scoped>
.crm-avatar {
    width: 100px;
    height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    position: relative;
    border: 1px solid #3782e6;
}
.upload-button.crm {
    margin-top: 55px;
    margin-left: 28px !important;
    border: 1px solid #dee2e6;
}
</style>
