import moment from "moment";

function calculatePackingCost(type, dimensions) {
    const { P, L, T, K } = dimensions;
    const multiplier = K > 0 ? K : 1;
    switch (type) {
        case "wrappingPlastic":
            return Math.max((P * L * T) * 0.04, 5000) * multiplier;
        case "pallet":
            return Math.max(P * L * 15, 60000) * multiplier;
        case "kayu":
            return Math.max((P * L * T) * 0.6, 60000) * multiplier;
        case "bubbleWrap":
            return Math.max((P * L * T) * 0.3, 10000) * multiplier;
        case "kardus":
            return Math.max(((P + L + T) / 3) * 150, 10000) * multiplier;
        case "karungKecil":
            return 7500 * multiplier;
        case "karungSedang":
            return 10000 * multiplier;
        case "karungBesar":
            return 12500 * multiplier;
        default:
            return 0;
    }
}

function titleCase(str) {
    return str.toLowerCase().replace(/(^|\s)\S/g, (L) => L.toUpperCase());
}

function numbering(index, currentPage, limitPerPage) {
    let number = index + startIndex(currentPage, limitPerPage) + 1;
    return (number < 10 ? "0" : "") + number;
}

function startIndex(currentPage, limitPerPage) {
    return (currentPage - 1) * limitPerPage;
}

function dateFormat(date, format, dateSet = "DDMMYYYY") {
    return moment(date, dateSet).format(format);
}

function currency(num) {
    num = num || 0;

    const isNegative = num < 0;
    num = Math.abs(num);

    if (!Number.isInteger(num)) {
        num = parseInt(num);
    }

    var number_string = num
            .toString()
            .replace(/[^,\d]/g, "")
            .toString(),
        split = number_string.split(","),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{3}/gi);
    if (ribuan) {
        let separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
    }

    rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
    if (isNegative) {
        return "- Rp " + rupiah;
    } else {
        return "Rp " + (rupiah || "0");
    }
}

function decimalFormat(num) {
    return (
        "" +
        parseInt(num)
            .toFixed(0)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
    );
}

function customRound(num) {
    if (num >= 0) {
        return Math.round(num);
    } else {
        return num;
    }
}

function checkRole(roles) {
    let user_roles = localStorage.getItem("roles");
    if (user_roles == null) user_roles = [];
    let result = roles.some((el) => user_roles.includes(el));
    return result;
}

export default {
    titleCase,
    numbering,
    dateFormat,
    currency,
    checkRole,
    decimalFormat,
    customRound,
    calculatePackingCost,
};
