<template>
    <div class="padding-container">
        <div class="content-load" v-if="detail_loading"></div>
        <div id="loader" v-if="detail_loading"></div>
        <notifications group="foo" position="top right" />
        <div class="fw-bold">
            <router-link
                to="/order/process"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </router-link>
        </div>
        <div class="box-manifest mt-3">
            <div class="d-flex align-items-center mb-3">
                <h5 class="mb-0 fw-bold me-4" v-if="detailDeal.deal_code">
                    Deal ID - {{ detailDeal.deal_code }}
                </h5>
                <h5 class="mb-0 fw-bold me-4" v-else>Data Klaim Pesanan</h5>
                <div
                    class="mb-0 pb-0 capitalize d-flex justify-content-center rounded-pill px-3"
                    :class="{
                        'badge-walk-in': detailDeal.order_type === 'walk_in',
                        'badge-regular': detailDeal.order_type === 'reg',
                    }"
                >
                    {{
                        detailDeal.order_type === "reg"
                            ? "Regular"
                            : detailDeal.order_type === "walk_in"
                            ? "Walk In"
                            : ""
                    }}
                </div>
            </div>
            <h5 class="mb-0 fw-bold">Data Pengirim</h5>
            <div class="row mt-4">
                <div class="col-lg-6">
                    <label class="fw-bold mb-1">
                        Nama Pengirim
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nama pengirim"
                        v-model="req.sender_name"
                    />
                </div>
                <div class="col-lg-6">
                    <label class="fw-bold mb-1">
                        Nomor Handphone Pengirim
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span class="input-group-text">+62</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nomor pengirim"
                            @input="validatePhoneSender"
                            v-model="req.sender_phone"
                        />
                    </div>
                </div>
                <div class="col-lg-6 mt-3">
                    <label class="fw-bold mb-1">
                        Kota/Kabupaten Asal
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="regency"
                        :options="regencies"
                        label="name"
                        track-by="id"
                        placeholder="Cari kota/kabupaten asal"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-12 mt-2">
                    <label class="fw-bold mb-1">
                        Alamat Pengirim
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <textarea
                        cols="30"
                        rows="2"
                        class="form-control"
                        placeholder="Masukkan alamat pengirim"
                        v-model="req.sender_address"
                    ></textarea>
                </div>
            </div>
        </div>
        <div class="box-manifest mt-3">
            <h5 class="mb-0 fw-bold">Data Penerima</h5>
            <div class="row mt-4">
                <div class="col-lg-6">
                    <label class="fw-bold mb-1">
                        Nama Penerima
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nama penerima"
                        v-model="req.receiver_name"
                    />
                </div>
                <div class="col-lg-6">
                    <label class="fw-bold mb-1">
                        Nomor Handphone Penerima
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span class="input-group-text">+62</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nomor penerima"
                            @input="validatePhoneReceiver"
                            v-model="req.receiver_phone"
                        />
                    </div>
                </div>
                <div class="col-lg-6 mt-3">
                    <label class="fw-bold mb-1">
                        Provinsi
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="province"
                        :options="provinces"
                        label="name"
                        track-by="id"
                        placeholder="Pilih provinsi"
                        class="multi-form-custom"
                        :show-labels="false"
                        @input="changeProvince()"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-6 mt-3">
                    <label class="fw-bold mb-1">
                        Kota/kabupaten
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="receiver_regency"
                        :options="receiver_regencies"
                        label="name"
                        track-by="id"
                        placeholder="Pilih kota/kabupaten"
                        class="multi-form-custom"
                        @input="changeRegency()"
                        :show-labels="false"
                        :disabled="!province"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-6 mt-2">
                    <label class="fw-bold mb-1">
                        Kecamatan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="district"
                        :options="districts"
                        label="name"
                        track-by="id"
                        placeholder="Pilih kecamatan"
                        class="multi-form-custom"
                        @input="changeDistrict()"
                        :show-labels="false"
                        :disabled="!receiver_regency"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-6 mt-2">
                    <label class="fw-bold mb-1">
                        Kelurahan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="sub_district"
                        :options="sub_districts"
                        label="name"
                        track-by="id"
                        placeholder="Pilih Kelurahan"
                        class="multi-form-custom"
                        :show-labels="false"
                        :disabled="!district"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-6 mt-2">
                    <label class="fw-bold mb-1">
                        Kode Pos
                        <span class="text-danger fw-bold">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan kode pos"
                        v-model="req.receiver_zipcode"
                        @input="validateNumber()"
                    />
                </div>
                <div class="col-lg-12 mt-3">
                    <label class="fw-bold mb-1">
                        Alamat Penerima
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <textarea
                        cols="30"
                        rows="2"
                        class="form-control"
                        placeholder="Masukkan alamat lengkap penerima"
                        v-model="req.receiver_address"
                    ></textarea>
                </div>
            </div>
        </div>
        <div class="box-manifest mt-3">
            <h5 class="mb-0 fw-bold">
                Hasil Ukur - Timbang Barang <span class="text-danger">*</span>
            </h5>
            <div class="table-responsive-custom">
                <table class="table table-bordered mt-4">
                    <thead class="table-light">
                        <tr>
                            <th class="text-center" scope="col">No</th>
                            <th class="text-center" scope="col">Jumlah Koli</th>
                            <th class="text-center" scope="col">Deskripsi</th>
                            <th class="text-center" scope="col">
                                Dimensi Barang (Cm)
                            </th>
                            <th class="text-center" scope="col">Berat (Kg)</th>
                            <th class="text-center" scope="col">
                                Berat Volume (Kg)
                            </th>
                            <th class="text-center" scope="col">
                                Kubikasi (Kg3)
                            </th>
                            <th class="text-center" scope="col">
                                Perlindungan
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(item, index) in detailDeal.items"
                            :key="index"
                        >
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-center">
                                {{ item.qty }}
                            </td>
                            <td>{{ item.description }}</td>
                            <td class="text-center">
                                {{ item.dimension.width }} Cm X
                                {{ item.dimension.length }} Cm X
                                {{ item.dimension.height }} Cm
                            </td>
                            <td
                                class="text-center"
                                v-bind:class="{
                                    'bc-green':
                                        item.weight > item.weight_volume,
                                }"
                            >
                                {{ customRound(item.weight) }} Kg
                            </td>
                            <td
                                class="text-center"
                                v-bind:class="{
                                    'bc-green':
                                        item.weight_volume > item.weight,
                                }"
                            >
                                {{ customRound(item.weight_volume) }} Kg
                            </td>
                            <td class="text-center">
                                {{ customRound(item.cubic) }} Kg3
                            </td>
                            <td class="capitalize">
                                <div
                                    v-for="(handling, index) in item.protection"
                                    :key="index"
                                >
                                    <span class="tag gray-new radius pb-0">
                                        {{
                                            handling.packing.replace(/_/g, " ")
                                        }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody class="body">
                        <tr>
                            <td class="p-footer" colspan="4">
                                <div class="pull-right fw-bold">Total</div>
                            </td>
                            <td class="text-center fw-bold p-footer">
                                {{ customRound(totalWeight) }} Kg
                            </td>
                            <td class="text-center fw-bold p-footer">
                                {{ customRound(totalVolume) }} Kg
                            </td>
                            <td class="text-center fw-bold p-footer">
                                {{ customRound(totalCubic) }} Kg3
                            </td>
                            <td class="p-footer"></td>
                        </tr>
                        <tr>
                            <td class="p-footer" colspan="4">
                                <div class="pull-right fw-bold">
                                    Charge Weight (Kg)
                                </div>
                            </td>
                            <td
                                class="text-center bc-green p-footer"
                                colspan="4"
                            >
                                {{ customRound(chargeWeight) }} Kg
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <label class="fw-bold mb-1 mt-3">Catatan</label>
            <textarea
                class="form-control"
                v-model="req.note"
                placeholder="Masukkan catatan untuk barang"
            ></textarea>
        </div>
        <div class="box-manifest mt-3">
            <h5 class="mb-0 fw-bold">Pilih Jenis Layanan</h5>
            <label class="fw-bold mb-1 mt-3">
                Jenis Layanan <span class="text-danger">*</span></label
            >
            <select
                class="form-control form-select"
                v-model="req.service_type"
                disabled
            >
                <option value="" disabled>Pilih Jenis Layanan</option>
                <option value="pengiriman_barang">Pengiriman Barang</option>
                <option value="pengiriman_motor">Pengiriman Motor</option>
                <option value="pengiriman_mobil">Pengiriman Mobil</option>
                <option value="pengiriman_truk">Pengiriman Truk</option>
                <option value="sewa_container_20ft">
                    Sewa Kontainer 20 Feet
                </option>
                <option value="sewa_container_40ft">
                    Sewa Kontainer 40 Feet
                </option>
            </select>
        </div>
        <div class="box-manifest mt-3">
            <h5 class="fw-bold mb-4">
                Metode Pembayaran <span class="text-danger">*</span>
            </h5>
            <div class="row">
                <div
                    class="col-lg-6 cursor-pointer"
                    @click="selectedPayment = 'cash'"
                >
                    <div
                        class="box"
                        :class="{ selected: selectedPayment === 'cash' }"
                    >
                        <div class="d-flex align-items-center">
                            <input
                                type="radio"
                                class="me-3"
                                value="cash"
                                v-model="selectedPayment"
                            />
                            <img
                                src="../assets/money-active.svg"
                                alt="money"
                                class="me-3"
                                v-if="selectedPayment === 'cash'"
                            />
                            <img
                                v-else
                                src="../assets/money.svg"
                                alt="money"
                                class="me-3"
                            />
                            <div class="d-flex flex-column">
                                <p class="fw-bold mb-0">Tunai</p>
                                <p class="text-gray mb-0">Pembayaran tunai</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="col-lg-6 cursor-pointer"
                    @click="selectedPayment = 'va'"
                >
                    <div
                        class="box"
                        :class="{ selected: selectedPayment === 'va' }"
                    >
                        <div class="d-flex align-items-center">
                            <input
                                type="radio"
                                class="me-3"
                                value="va"
                                v-model="selectedPayment"
                            />
                            <img
                                src="../assets//va-active.svg"
                                alt="va active"
                                class="me-3"
                                v-if="selectedPayment === 'va'"
                            />
                            <img
                                src="../assets/va.svg"
                                alt="va"
                                class="me-3"
                                v-else
                            />
                            <div class="d-flex flex-column">
                                <p class="fw-bold mb-0">Virtual Account</p>
                                <p class="text-gray mb-0">
                                    Pembayaran VA dari banyak pilihan bank
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="col-lg-12 position-relative mt-2"
                    v-if="selectedPayment === 'va'"
                >
                    <label class="fw-bold mb-1 mt-3">
                        Bank
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div
                        class="form-control cursor-pointer"
                        :class="{ 'mb-4': !isDropdownOpen }"
                        @click="isDropdownOpen = !isDropdownOpen"
                    >
                        <div
                            class="d-flex justify-content-between align-items-center w-100"
                        >
                            <div class="d-flex align-items-center">
                                <img
                                    v-if="selectedBank"
                                    :src="selectedBank.asset"
                                    :alt="selectedBank.asset"
                                    width="50"
                                    class="me-2"
                                />
                                <p
                                    class="mb-0"
                                    :class="{
                                        'text-gray': !selectedBank,
                                    }"
                                >
                                    {{
                                        selectedBank
                                            ? selectedBank.label
                                            : "Pilih bank pembayaran"
                                    }}
                                </p>
                            </div>
                            <img
                                src="../assets/arrow-down-2.svg"
                                alt="arrow down 2"
                                :class="{ 'rotate-arrow': isDropdownOpen }"
                            />
                        </div>
                    </div>
                    <div
                        v-if="isDropdownOpen"
                        class="form-control my-2 position-absolute z-3"
                    >
                        <div
                            v-for="(channel, index) in channels"
                            :key="index"
                            class="d-flex justify-content-center flex-column py-2 cursor-pointer"
                            :class="{
                                'border-bottom': index !== channels.length - 1,
                            }"
                            @click="selectBank(channel)"
                        >
                            <div class="d-flex align-items-center">
                                <img
                                    :src="channel.asset"
                                    :alt="channel.asset"
                                    width="50"
                                    class="me-2"
                                />
                                {{ channel.label }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4" v-if="suggestion">
            <div class="col-lg-6"></div>
            <div class="col-lg-6">
                <div class="box selected">
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <div class="d-flex align-items-center">
                            <img
                                src="../assets/information.svg"
                                alt="information"
                                class="me-2"
                            />
                            <h6 class="mb-0">Informasi Harga Pengiriman</h6>
                        </div>
                        <h5 class="mb-0 text-suggestion">
                            {{ currency(suggestion) }}/Kg
                        </h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-lg-6"></div>
            <div class="col-lg-6">
                <div class="box-manifest">
                    <h5 class="mb-0 fw-bold">Total Biaya Pengiriman</h5>
                    <div class="d-flex align-items-center mt-3">
                        <div>Biaya Pengiriman</div>
                        <div class="ms-auto fw-bold d-flex align-items-center">
                            <a
                                v-if="!isDisabled"
                                href="javascript:void(0)"
                                @click="is_edit_service = !is_edit_service"
                                class="me-1"
                                v-bind:class="{
                                    'text-gray': this.is_edit_service == false,
                                    'text-primary':
                                        this.is_edit_service == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            <template v-if="!is_edit_service || isDisabled">
                                {{ currency(service_price) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="service_price"
                                    v-bind="format_number"
                                    v-autofocus
                                    class="form-control form-edit"
                                    @input="updateWidth('service')"
                                    :style="{
                                        width: moneyInputWidths.service + 'px',
                                    }"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-2">
                        <div>Biaya Packing</div>
                        <div class="ms-auto fw-bold d-flex align-items-center">
                            {{ currency(packing_price) }}
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-2">
                        <div>Biaya Jasa Tambahan</div>
                        <div class="ms-auto fw-bold d-flex align-items-center">
                            <a
                                v-if="!isDisabled"
                                href="javascript:void(0)"
                                @click="
                                    is_edit_additional = !is_edit_additional
                                "
                                class="me-1"
                                v-bind:class="{
                                    'text-gray':
                                        this.is_edit_additional == false,
                                    'text-primary':
                                        this.is_edit_additional == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            <template v-if="!is_edit_additional || isDisabled">
                                {{ currency(additional_price) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="additional_price"
                                    v-bind="format_number"
                                    v-autofocus
                                    @input="updateWidth('additional')"
                                    :style="{
                                        width:
                                            moneyInputWidths.additional + 'px',
                                    }"
                                    class="form-control form-edit"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-2">
                        <div class="text-yellow">Diskon</div>
                        <div
                            class="ms-auto fw-bold text-yellow d-flex align-items-center"
                        >
                            <a
                                v-if="!isDisabled"
                                href="javascript:void(0)"
                                @click="is_edit_discount = !is_edit_discount"
                                class="me-1"
                                v-bind:class="{
                                    'text-gray': this.is_edit_discount == false,
                                    'text-primary':
                                        this.is_edit_discount == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            -<template v-if="!is_edit_discount || isDisabled">
                                {{ currency(discount_price) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="discount_price"
                                    v-bind="format_number"
                                    v-autofocus
                                    @input="updateWidth('discount')"
                                    :style="{
                                        width: moneyInputWidths.discount + 'px',
                                    }"
                                    class="form-control form-edit yellow"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <hr />
                    <div class="d-flex align-items-center mt-2">
                        <div class="text-green fw-bold">Total Pembayaran</div>
                        <div class="ms-auto fw-bold text-green">
                            {{ currency(calculateTotalPayment) }}
                        </div>
                    </div>
                    <div class="mt-3">
                        <button
                            class="btn btn-primary w-100"
                            @click="modalSuccessConfirmation = true"
                            :disabled="isCreateOrderDisabled"
                        >
                            Buat Pesanan
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccessConfirmation">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">Pesanan Akan Diklaim</h5>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin mengklaim pesanan ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalSuccessConfirmation = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            @click="storeData()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_save"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_save"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">Pesanan Berhasil Diklaim</h5>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/order/process"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccessVa">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">Pesanan Berhasil dibuat</h5>
                    <div class="text-gray-dashboard">
                        Segera lakukan pembayaran melalui virtual account
                    </div>
                </div>
                <div class="mt-4">
                    <router-link
                        :to="'/order/cashier/va/' + receiptCode"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Api from "../api/Api";
import { Money } from "v-money";

export default {
    components: {
        Multiselect,
        Money,
    },
    name: "OrderProcessCreate",
    data() {
        return {
            selectedPayment: null,
            selectedBank: null,
            isDropdownOpen: false,
            moneyInputWidths: {
                service: 30,
                packing: 30,
                additional: 30,
                discount: 30,
            },
            format_number: {
                decimal: ",",
                thousands: ".",
                prefix: "Rp",
                suffix: "",
                precision: 0,
                masked: false,
                maxLength: 13,
                minimumValue: 0,
            },
            regencies: [],
            detail_loading: false,
            detailDeal: {
                sender_data: {},
                receiver_data: {},
                items: [],
            },
            regency: {
                id: "",
                name: "",
            },
            province: {
                id: "",
                name: "",
            },
            receiver_regency: {
                id: "",
                name: "",
            },
            district: {
                id: "",
                name: "",
            },
            sub_district: {
                id: "",
                name: "",
            },
            req: {
                sender_name: "",
                sender_phone: "",
                sender_regency: null,
                sender_address: "",
                receiver_name: "",
                receiver_phone: "",
                receiver_zipcode: "",
                receiver_address: "",
                service_type: "",
                note: "",
            },
            districts: [],
            sub_districts: [],
            receiver_regencies: [],
            provinces: [],
            service_price: 0,
            packing_price: 0,
            additional_price: 0,
            discount_price: 0,
            total_price: 0,
            is_edit_service: false,
            is_edit_picking: false,
            is_edit_additional: false,
            is_edit_discount: false,
            modalSuccessConfirmation: false,
            is_save: false,
            modalSuccess: false,
            channels: [],
            suggestion: null,
            modalSuccessVa: false,
            receiptCode: null,
        };
    },
    directives: {
        autofocus: {
            inserted: (el) => {
                el.focus();
            },
        },
    },
    created() {
        this.getRegency();
        this.getDetailDeal();
        this.getProvince();
        this.getChannels();
    },
    watch: {
        "province.id": function (newId) {
            if (newId) {
                this.getRegency();
            } else {
                this.regencies = [];
                this.receiver_regencies = [];
            }
        },
        "receiver_regency.id": function (newId) {
            if (newId) {
                this.getDistrict();
                this.checkAndCallGetSuggestion();
            }
        },
        "regency.id": function (newId) {
            if (newId) {
                this.checkAndCallGetSuggestion();
            }
        },
        "district.id": function (newId) {
            if (newId) {
                this.getSubDistrict();
            }
        },
        "req.service_type": function () {
            this.checkAndCallGetSuggestion();
        },
    },
    computed: {
        isDisabled() {
            return (
                !this.req.sender_name ||
                !this.req.sender_phone ||
                !this.regency ||
                !this.req.sender_address ||
                !this.req.receiver_name ||
                !this.req.receiver_phone ||
                !this.province ||
                !this.receiver_regency ||
                !this.district ||
                !this.sub_district ||
                !this.req.receiver_address ||
                !this.req.receiver_zipcode ||
                !this.selectedPayment ||
                (this.selectedPayment === "va" && !this.selectedBank)
            );
        },
        isCreateOrderDisabled() {
            return this.isDisabled || !this.service_price;
        },
        totalWeight() {
            return this.detailDeal.items.reduce(
                (total, item) => total + item.qty * Math.round(item.weight),
                0
            );
        },
        totalVolume() {
            return this.detailDeal.items.reduce(
                (total, item) =>
                    total + item.qty * Math.round(item.weight_volume),
                0
            );
        },
        totalCubic() {
            return this.detailDeal.items.reduce(
                (total, item) => total + item.qty * Math.round(item.cubic),
                0
            );
        },
        chargeWeight() {
            return Math.max(this.totalWeight, this.totalVolume);
        },
        calculateTotalPayment() {
            const servicePrice = Number(this.service_price) || 0;
            const packingPrice = Number(this.packing_price) || 0;
            const additionalPrice = Number(this.additional_price) || 0;
            const discountPrice = Number(this.discount_price) || 0;

            return (
                servicePrice + packingPrice + additionalPrice - discountPrice
            );
        },
    },
    methods: {
        checkAndCallGetSuggestion() {
            if (
                this.regency?.id &&
                this.receiver_regency?.id &&
                this.req.service_type
            ) {
                this.getSuggestion();
            }
        },
        selectBank(channel) {
            this.selectedBank = channel;
            this.isDropdownOpen = false;
        },
        getChannels() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/payment/channel`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.channels = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getSuggestion() {
            var payload = {
                origin_regency_id: this.regency?.id,
                destination_regency_id: this.receiver_regency?.id,
                service_type: this.req?.service_type,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/order/get_suggestion`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.suggestion = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        changeProvince() {
            this.receiver_regency = null;
            this.district = null;
            this.sub_district = null;
        },
        changeRegency() {
            this.district = null;
            this.sub_district = null;
        },
        changeDistrict() {
            this.sub_district = null;
        },
        updateWidth(type) {
            const values = {
                service: this.service_price,
                packing: this.packing_price,
                additional: this.additional_price,
                discount: this.discount_price,
            };

            const valueLength = String(values[type]).length;
            this.moneyInputWidths[type] = 30 + (valueLength - 1) * 10;
        },
        validateNumber() {
            this.req.receiver_zipcode = this.req.receiver_zipcode.replace(
                /[^0-9]/g,
                ""
            );
        },
        validatePhoneSender() {
            if (!this.req.sender_phone.startsWith("8")) {
                this.req.sender_phone =
                    "8" + this.req.sender_phone.replace(/[^0-9]/g, "");
            } else {
                this.req.sender_phone = this.req.sender_phone.replace(
                    /[^0-9]/g,
                    ""
                );
            }
        },
        validatePhoneReceiver() {
            if (!this.req.receiver_phone.startsWith("8")) {
                this.req.receiver_phone =
                    "8" + this.req.receiver_phone.replace(/[^0-9]/g, "");
            } else {
                this.req.receiver_phone = this.req.receiver_phone.replace(
                    /[^0-9]/g,
                    ""
                );
            }
        },
        getProvince() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/province`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.provinces = data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getRegency() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/regency?province_id=${this.province?.id}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.regencies = data;
                    this.receiver_regencies = data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getDistrict() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/district?regency_id=${this.receiver_regency?.id}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.districts = data;
                    if (this.district.id) {
                        this.getSubDistrict();
                    }
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getSubDistrict() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/subdistrict?district_id=${this.district?.id}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.sub_districts = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getDetailDeal() {
            this.detail_loading = true;
            const param = this.$route.query;
            var payload = {
                claim_id: param.claim_id,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/order/claims/detail`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.detailDeal = res.data.data;
                    // SENDER
                    this.req.sender_name = res.data.data.sender_data.name;
                    this.req.sender_phone = res.data.data.sender_data.phone;
                    if (res.data.data.sender_data.regency_id != null) {
                        this.regency.name =
                            res.data.data.sender_data.regency_name;
                        this.regency.id = res.data.data.sender_data.regency_id;
                    } else {
                        this.regency = null;
                    }
                    this.req.sender_address = res.data.data.sender_data.address;
                    // RECEIVER
                    this.req.receiver_name = res.data.data.receiver_data.name;
                    this.req.receiver_phone = res.data.data.receiver_data.phone;
                    if (res.data.data.receiver_data.province_id != null) {
                        this.province.name =
                            res.data.data.receiver_data.province_name;
                        this.province.id =
                            res.data.data.receiver_data.province_id;
                    } else {
                        this.province = null;
                    }
                    if (res.data.data.receiver_data.regency_id != null) {
                        this.receiver_regency.name =
                            res.data.data.receiver_data.regency_name;
                        this.receiver_regency.id =
                            res.data.data.receiver_data.regency_id;
                        // Fetch districts and sub-districts
                        this.getDistrict();
                    } else {
                        this.receiver_regency = null;
                    }
                    if (res.data.data.receiver_data.district_id != null) {
                        this.district.name =
                            res.data.data.receiver_data.district_name;
                        this.district.id =
                            res.data.data.receiver_data.district_id;
                        // Fetch sub-districts
                        this.getSubDistrict();
                    } else {
                        this.district = null;
                    }
                    if (res.data.data.receiver_data.subdistrict_id != null) {
                        this.sub_district.name =
                            res.data.data.receiver_data.subdistrict_name;
                        this.sub_district.id =
                            res.data.data.receiver_data.subdistrict_id;
                    } else {
                        this.sub_district = null;
                    }
                    this.req.receiver_zipcode =
                        res.data.data.receiver_data.zipcode;
                    this.req.receiver_address =
                        res.data.data.receiver_data.address;
                    this.req.service_type = res.data.data.service_type;
                    this.req.note = res.data.data.note;
                    this.packing_price = res.data.data.packing_price;

                    this.detail_loading = false;
                    this.calculateMaxValue();
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.detail_loading = false;
                });
        },
        storeData() {
            this.is_save = true;
            const param = this.$route.query;

            var data = {
                claim_id: param.claim_id,
                origin_regency_id: this.regency?.id,
                service_type: this.req.service_type,
                sender_name: this.req.sender_name,
                sender_phone: this.req.sender_phone,
                sender_address: this.req.sender_address,
                receiver_name: this.req.receiver_name,
                receiver_phone: this.req.receiver_phone,
                receiver_zipcode: this.req.receiver_zipcode,
                receiver_address: this.req.receiver_address,
                destination_province_id: this.province?.id,
                destination_regency_id: this.receiver_regency?.id,
                destination_district_id: this.district?.id,
                destination_subdistrict_id: this.sub_district?.id,
                service_price: this.service_price,
                packing_price: this.packing_price,
                additional_price: this.additional_price,
                discount_price: this.discount_price,
                note: this.req.note,
                payment_method: this.selectedPayment,
                charge_weight: this.chargeWeight,
            };
            if (this.selectedPayment === "va") {
                data.va = this.selectedBank?.va;
            }

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/order/claims-v2`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_save = false;
                    if (this.selectedPayment === "cash") {
                        this.modalSuccess = true;
                    } else {
                        this.receiptCode = res.data.data.receipt_code;
                        this.modalSuccessVa = true;
                    }
                    this.modalSuccessConfirmation = false;
                })
                .catch((err) => {
                    this.is_save = false;
                    this.modalSuccessConfirmation = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        calculateMaxValue() {
            this.service_price = this.detailDeal.price;
            this.packing_price = this.detailDeal.packing_price;
            this.additional_price = this.detailDeal.additional_price;
            this.discount_price = this.detailDeal.discount_price;
            this.total_price =
                this.service_price +
                this.packing_price +
                this.additional_price -
                this.discount_price;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-money.form-control {
    background-color: #f9fafb;
}
.table-responsive-custom {
    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th,
        td {
            border: 1px solid #c1cdd7;
        }
        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody.body {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
.badge-walk-in {
    background-color: #e0e6eb !important;
    border: 1px solid #c2cdd6 !important;
    color: #3e4e5c !important;
}
.badge-regular {
    background-color: #cce7ff !important;
    border: 1px solid #99d0ff !important;
    color: #005299 !important;
}
input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #a3b4c2;
    border-radius: 50%;
    background-color: transparent;
    position: relative;
    cursor: pointer;
}

input[type="radio"]:checked {
    border: 2px solid #329a5f;
    background-color: transparent;
}

input[type="radio"]:checked::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    background-color: #329a5f;
    border-radius: 50%;
}

.box.selected {
    border: 1px solid #329a5f;
    background-color: #d9f2e4;
}
.box.selected p {
    color: #329a5f !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
::v-deep .multiselect--disabled .multiselect__current,
::v-deep .multiselect--disabled .multiselect__select,
::v-deep .multiselect--disabled .multiselect__tags,
::v-deep .multiselect--disabled .multiselect__placeholder {
    background: #f3f5f7 !important;
    color: #495057 !important;
}
th {
    border-color: #c1cdd7;
    padding: 10px !important;
}
tr {
    border-color: #c1cdd7;
    padding: 10px !important;
}
td {
    padding: 10px !important;
    vertical-align: middle;
}
.table-light {
    background-color: #f7f7f7 !important;
    --bs-table-border-color: #f7f7f7 !important;
    --bs-table-bg: #f7f7f7 !important;
}
.text-suggestion {
    color: #329a5f !important;
}
td.bc-green {
    background-color: #d9f2e4;
    color: #329a5f;
    font-weight: 600 !important;
}
.table-responsive-custom table tbody tr td {
    font-weight: 300;
}
td.p-footer {
    padding: 5px !important;
}
.radius-select {
    border-radius: 7px;
    background: #f3f5f7;
    color: #000;
    font-weight: 500;
    padding: 7px 10px;
    border: 1px solid #d1d0d4;
}
.form-edit {
    border: unset !important;
    text-align: right;
    padding: 0px;
    font-weight: 600 !important;
    color: #000 !important;
    border-radius: unset !important;
}
.form-edit.yellow {
    color: #ff8600 !important;
}
.text-gray {
    color: #a3b4c2 !important;
}
.rotate-arrow {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}
textarea {
    resize: none;
}
</style>
