<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="$router.go(-1)"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h5 class="fw-bold">Detail Data Leads</h5>
                <div class="ms-auto">
                    <router-link
                        :to="'/data/leads/edit/' + this.$route.params.id"
                    >
                        <i class="fa fa-pencil me-1"></i>
                        Edit
                    </router-link>
                </div>
            </div>
            <div class="mt-4">
                <label class="fw-bold">Tags</label>
                <div class="mt-2">-</div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                        <tr class="border-bottom">
                            <td class="ps-0 w-80">Nama Customer</td>
                            <td class="w-30">:</td>
                            <td>-</td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-80">Email</td>
                            <td class="w-30">:</td>
                            <td>-</td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-80">Jabatan</td>
                            <td class="w-30">:</td>
                            <td>-</td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-80">Sumber Info</td>
                            <td class="w-30">:</td>
                            <td>-</td>
                        </tr>
                    </table>
                </div>
                <div class="col-md-6">
                    <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                        <tr class="border-bottom">
                            <td class="ps-0 w-200">Nomor Handphone</td>
                            <td class="w-30">:</td>
                            <td>-</td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-200">Perusahaan</td>
                            <td class="w-30">:</td>
                            <td>-</td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-200">No. Telp Perusahaan</td>
                            <td class="w-30">:</td>
                            <td>-</td>
                        </tr>
                    </table>
                </div>
                <div class="col-md-12">
                    <table class="w-100 ps-0 fw-bold" cellpadding="10">
                        <tr class="">
                            <td class="ps-0 w-80">Alamat</td>
                            <td class="w-30">:</td>
                            <td>-</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h5 class="fw-bold">Detail Aktivitas Follow Up</h5>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                        <tr class="border-bottom">
                            <td class="ps-0 w-80">Kategori</td>
                            <td class="w-30">:</td>
                            <td>-</td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-80">Aktivitas Terakhir</td>
                            <td class="w-30">:</td>
                            <td>-</td>
                        </tr>
                    </table>
                </div>
                <div class="col-md-6">
                    <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                        <tr class="border-bottom">
                            <td class="ps-0 w-200">Tgl. Order Terakhir</td>
                            <td class="w-30">:</td>
                            <td>-</td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-200">CRM Specialist</td>
                            <td class="w-30">:</td>
                            <td>-</td>
                        </tr>
                    </table>
                </div>
                <div class="col-md-12">
                    <table class="w-100 ps-0 fw-bold" cellpadding="10">
                        <tr class="">
                            <td class="ps-0 w-80">Prioritas</td>
                            <td class="w-30">:</td>
                            <td>-</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    name: "ProspectDetail",
    data() {
        return {};
    },
    created() {},
    methods: {},
    computed: {},
};
</script>
<style lang="scss" scoped>
.w-200 {
    width: 200px;
}
.w-80 {
    width: 150px;
}
.w-30 {
    width: 30px;
}
.border-top {
    border-color: #eee !important;
}
</style>
