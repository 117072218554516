<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="fw-bold">
            <router-link
                to="/tally/off-loading"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </router-link>
        </div>
        <div class="box mt-3">
            <div class="mb-3 d-flex justify-content-between align-items-center">
                <h5 class="fw-bold">
                    Daftar Barang - Driver {{ this.$route.query.driver_name }}
                </h5>
                <p class="mb-0">
                    Tanggal Off Loading :
                    {{ formatDate(this.$route.query.date_offload) }}
                </p>
            </div>
            <div class="d-flex align-items-center no-flex mt-4">
                <div class="me-4 m-mx-0 m-mt-2">
                    <div class="position-relative">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search border-search"
                                placeholder="Cari nama customer"
                                v-model="search"
                            />
                            <button
                                class="btn btn-primary px-4"
                                type="button"
                                @click="filterData()"
                            >
                                Cari
                            </button>
                        </div>
                        <span class="search-icon left">
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                </div>
                <div class="m-mx-0 m-mt-2 me-3">
                    <button
                        class="btn btn-outline-yellow btn-filter px-3"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-md-4 mb-2">
                        <label class="fw-bold mb-1">CRM Specialist</label>
                        <multiselect
                            v-model="specialist"
                            :options="specialists"
                            label="name"
                            track-by="id"
                            placeholder="Pilih CRM specialist"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-4 mb-2">
                        <label class="fw-bold mb-1">Status</label>
                        <multiselect
                            v-model="status"
                            :options="statuses"
                            label="name"
                            track-by="id"
                            placeholder="Pilih status"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-4 d-flex">
                        <div>
                            <label class="fw-bold mb-1 invisible">Status</label>
                            <button
                                class="btn btn-outline-yellow px-5 me-3"
                                @click="clearFilter()"
                                :disabled="status == null && specialist == null"
                            >
                                Clear
                            </button>
                        </div>
                        <div>
                            <label class="fw-bold mb-1 invisible">Status</label>
                            <button
                                class="btn btn-green px-5"
                                :disabled="status == null && specialist == null"
                                @click="filterData()"
                            >
                                Filter
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4">
                Menampilkan <span class="fw-bold">{{ lists.length }}</span> Data
            </div>
            <div class="mt-4" v-if="is_load_list">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Nama Customer
                                </th>
                                <th class="text-center" scope="col">
                                    Deskripsi Barang
                                </th>
                                <th class="text-center" scope="col">
                                    Kota Asal
                                </th>
                                <th class="text-center" scope="col">
                                    Kota Tujuan
                                </th>
                                <th class="text-center" scope="col">
                                    CRM Specialist
                                </th>
                                <th class="text-center" scope="col">Status</th>
                                <th class="text-center" scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in lists" :key="index">
                                <td class="text-center">{{ index + 1 }}</td>
                                <td>
                                    {{
                                        item.customer_name
                                            ? item.customer_name
                                            : "-"
                                    }}
                                </td>
                                <td>
                                    {{
                                        item.description
                                            ? item.description
                                            : "-"
                                    }}
                                </td>
                                <td>{{ item.origin ? item.origin : "-" }}</td>
                                <td>
                                    {{
                                        item.destination
                                            ? item.destination
                                            : "-"
                                    }}
                                </td>
                                <td>
                                    {{
                                        item.specialist ? item.specialist : "-"
                                    }}
                                </td>
                                <td>
                                    <span
                                        class="tag mb-0 pb-0 px-3 radius"
                                        v-bind:class="{
                                            'yellow-cro': item.status == false,
                                            'green-cro': item.status == true,
                                        }"
                                    >
                                        <template v-if="item.status == true">
                                            Sudah Diukur
                                        </template>
                                        <template v-else>
                                            Belum Diukur
                                        </template>
                                    </span>
                                </td>
                                <td class="text-center">
                                    <a
                                        href="javascript:void(0)"
                                        @click="goToDetail(item)"
                                    >
                                        <img
                                            src="../assets/action.png"
                                            width="18"
                                            alt=""
                                        />
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="lists.length == 0" class="text-center mt-5">
                    <img src="../assets/no-data-green.png" width="100" alt="" />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada daftar Barang
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import Api from "../api/Api";
import ListLoader from "../components/ListLoader.vue";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
        ListLoader,
    },
    name: "ListTally",
    props: ["roles"],
    data() {
        return {
            moment: moment,
            isFilter: false,
            search: "",
            managerRoles: ["tally"],
            specialist: null,
            specialists: [],
            status: null,
            statuses: [
                {
                    name: "Sudah Diukur",
                    id: true,
                },
                {
                    name: "Belum Diukur",
                    id: false,
                },
            ],
            is_load_list: false,
            lists: [],
        };
    },
    mounted() {
        this.getSpecialists();
        this.getLists();
    },
    methods: {
        formatDate(date) {
            const monthNames = [
                "Januari",
                "Februari",
                "Maret",
                "April",
                "Mei",
                "Juni",
                "Juli",
                "Agustus",
                "September",
                "Oktober",
                "November",
                "Desember",
            ];
            const [day, month, year] = date.split("/");
            return `${day} ${monthNames[parseInt(month, 10) - 1]} ${year}`;
        },
        goToDetail(item) {
            this.$router.push({
                name: "tally-detail",
                query: {
                    delivery_assignation_id: item.delivery_assignation_id,
                    driver_fleet_id: this.$route.query.driver_fleet_id,
                    date_offload: this.$route.query.date_offload,
                },
            });
        },
        clearFilter() {
            this.status = null;
            this.specialist = null;
            this.getLists();
        },
        filterData() {
            this.getLists();
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        getSpecialists() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/user`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.specialists = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getLists() {
            const param = this.$route.query;
            this.is_load_list = true;
            let payload = {
                search: this.search,
                driver_fleet_id: param.driver_fleet_id,
                date: param.date_offload,
            };
            if (this.specialist) {
                payload.specialist_id = this.specialist?.id;
            }
            if (this.status) {
                payload.status = this.status.id;
            }
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/calculate/items`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.lists = res.data.data;
                    this.is_load_list = false;
                })
                .catch((err) => {
                    this.is_load_list = false;
                    console.log(err);
                });
        },
    },
};
</script>

<style scoped>
.w-search {
    width: 280px !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f7f7f7 !important;
    --bs-table-border-color: #f7f7f7 !important;
    --bs-table-bg: #f7f7f7 !important;
}
</style>
