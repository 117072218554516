var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"padding-container"},[(_vm.isPageLoading)?_c('div',{staticClass:"content-load"}):_vm._e(),(_vm.isPageLoading)?_c('div',{attrs:{"id":"loader"}}):_vm._e(),_c('div',{staticClass:"fw-bold"},[_c('div',{staticClass:"text-gray-dashboard size-16 cursor-pointer",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"fa fa-angle-left me-2"}),_vm._v(" Kembali ")])]),_c('div',{staticClass:"box-manifest mt-4"},[_c('h5',{staticClass:"fw-bold mb-4"},[_vm._v("Buat Manifest")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"mb-4 col-lg-6"},[_vm._m(0),_c('multiselect',{staticClass:"multi-form-custom",attrs:{"options":_vm.regencies,"label":"name","track-by":"id","placeholder":"Cari dan pilih kota/kab. asal","show-labels":false},scopedSlots:_vm._u([{key:"caret",fn:function(){return [_c('img',{staticStyle:{"width":"20px","height":"20px","position":"absolute","left":"12px","top":"42%","transform":"translateY(-50%)"},attrs:{"src":_vm.originRegency
                                    ? _vm.magnifierActiveIcon
                                    : _vm.magnifierIcon,"alt":"icon"}})]},proxy:true}]),model:{value:(_vm.originRegency),callback:function ($$v) {_vm.originRegency=$$v},expression:"originRegency"}})],1),_c('div',{staticClass:"mb-4 col-lg-6"},[_vm._m(1),_c('multiselect',{staticClass:"multi-form-custom",attrs:{"options":_vm.regencies,"label":"name","track-by":"id","placeholder":"Cari dan pilih kota/kab. tujuan","show-labels":false},scopedSlots:_vm._u([{key:"caret",fn:function(){return [_c('img',{staticStyle:{"width":"20px","height":"20px","position":"absolute","left":"12px","top":"42%","transform":"translateY(-50%)"},attrs:{"src":_vm.destinationRegency
                                    ? _vm.magnifierActiveIcon
                                    : _vm.magnifierIcon,"alt":"icon"}})]},proxy:true}]),model:{value:(_vm.destinationRegency),callback:function ($$v) {_vm.destinationRegency=$$v},expression:"destinationRegency"}})],1),_c('div',{staticClass:"mb-4 col-lg-12"},[_vm._m(2),_c('div',{staticClass:"box"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center flex-wrap"},[_c('h5',{staticClass:"fw-bold mb-0"},[_vm._v("Daftar Resi")]),_c('div',{staticClass:"position-relative my-2"},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control form-control-lg pl-filter-search w-search border-search",attrs:{"type":"text","placeholder":"Cari kota tujuan/nomor resi","disabled":_vm.originRegency === null ||
                                        _vm.destinationRegency === null},domProps:{"value":(_vm.search)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleSearch()},"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}}),_c('button',{staticClass:"btn btn-search px-4",attrs:{"type":"button","disabled":_vm.originRegency === null ||
                                        _vm.destinationRegency === null},on:{"click":function($event){return _vm.handleSearch()}}},[_vm._v(" Cari ")])]),_c('span',{staticClass:"magnifier-icon left"},[_c('img',{attrs:{"src":_vm.originRegency === null ||
                                        _vm.destinationRegency === null ||
                                        _vm.search === null ||
                                        _vm.search === ''
                                            ? _vm.magnifierIcon
                                            : _vm.magnifierActiveIcon,"alt":"icon magnifier","width":"20","height":"20"}})])])]),(
                            _vm.originRegency !== null &&
                            _vm.destinationRegency !== null &&
                            _vm.filteredReceipts.length !== 0
                        )?_c('div',{staticClass:"overflow-auto"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center mt-4"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"me-3",attrs:{"src":require("../assets/arrow-right.svg"),"alt":"arrow-right"}}),_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox"},domProps:{"checked":_vm.selectAll},on:{"change":_vm.toggleSelectAll}}),_c('p',{staticClass:"mb-0 ms-3"},[_vm._v("Tandai Semua")])]),_c('div',{staticClass:"d-flex align-items-center"},[_c('p',{staticClass:"mb-0 me-3"},[_c('span',{staticClass:"fw-bold"},[_vm._v(_vm._s(_vm.pageStart)+"-"+_vm._s(_vm.pageEnd))]),_vm._v(" Data dari "),_c('span',{staticClass:"fw-bold"},[_vm._v(_vm._s(_vm.rows))]),_vm._v(" Data ")]),_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)]),_vm._l((_vm.paginatedReceipts),function(receipt,index){return _c('div',{key:index},[_c('div',{staticClass:"d-flex align-items-center mt-4"},[_c('img',{staticClass:"me-3 cursor-pointer",class:{
                                        'rotate-90': receipt.isExpanded,
                                    },attrs:{"src":require("../assets/arrow-right.svg"),"alt":"arrow-right"},on:{"click":function($event){return _vm.toggleReceipt(receipt)}}}),_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox"},domProps:{"checked":_vm.isReceiptSelected(receipt)},on:{"change":function($event){return _vm.toggleReceiptSelection(receipt)}}}),_c('p',{staticClass:"mb-0 ms-3 fw-bold"},[_vm._v(" "+_vm._s(receipt.receipt_code)+" ")])]),_c('div',{staticClass:"d-flex mt-2"},[_c('img',{staticClass:"me-3 invisible",attrs:{"src":require("../assets/arrow-right.svg"),"alt":"arrow-right"}}),_c('input',{staticClass:"form-check-input invisible",attrs:{"type":"checkbox"}}),_c('table',{staticClass:"w-100 mt-2 ms-3",attrs:{"cellpadding":"10"}},[_c('thead',{directives:[{name:"show",rawName:"v-show",value:(receipt.isExpanded),expression:"receipt.isExpanded"}],staticClass:"thead fw-bold"},[_vm._m(3,true)]),_c('tbody',{directives:[{name:"show",rawName:"v-show",value:(receipt.isExpanded),expression:"receipt.isExpanded"}]},_vm._l((receipt.items),function(item,index){return _c('tr',{key:index,staticClass:"border-bottom"},[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.qty)+" Koli ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.weight)+" Kg ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.weight_volume)+" Kg ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.cubic)+" Kg3 ")])])}),0),_c('tfoot',{staticClass:"fw-bold",class:{
                                            tfoot: receipt.isExpanded,
                                        }},[_c('tr',[_c('td',[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('img',{staticClass:"me-2",attrs:{"src":require("../assets/koli.svg"),"alt":"koli"}}),_vm._v(" "+_vm._s(receipt.total_qty)+" Koli ")])]),_c('td',[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('img',{staticClass:"me-2",attrs:{"src":require("../assets/berat.svg"),"alt":"berat"}}),_vm._v(" "+_vm._s(receipt.total_weight)+" Kg ")])]),_c('td',[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('img',{staticClass:"me-2",attrs:{"src":require("../assets/berat-volume.svg"),"alt":"berat volume"}}),_vm._v(" "+_vm._s(receipt.total_weight_volume)+" Kg ")])]),_c('td',[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('img',{staticClass:"me-2",attrs:{"src":require("../assets/kubikasi.svg"),"alt":"kubikasi"}}),_vm._v(" "+_vm._s(receipt.total_cubic)+" Kg3 ")])])])])])]),(index < _vm.paginatedReceipts.length - 1)?_c('hr'):_vm._e()])})],2):_c('div',[_vm._m(4),(_vm.paginatedReceipts.length !== 0)?_c('p',{staticClass:"text-gray text-center mt-5"},[_vm._v(" Masukkan kota asal dan kota tujuan terlebih dahulu ")]):_c('p',{staticClass:"text-gray text-center mt-5"},[_vm._v(" Resi tidak tersedia ")])])])])])]),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"mb-4 col-lg-6"},[_c('div',{staticClass:"box-manifest"},[_c('h5',{staticClass:"fw-bold mb-4"},[_vm._v("Nomor Resi Yang Dipilih")]),(_vm.selectedReceipts.length > 0)?_c('div',_vm._l((_vm.selectedReceipts),function(receipt,index){return _c('div',{key:receipt.receipt_code,staticClass:"d-flex justify-content-between align-items-center py-2",class:{
                            'border-bottom':
                                index < _vm.selectedReceipts.length - 1,
                        }},[_c('p',{staticClass:"mb-0 fw-bold"},[_vm._v(" "+_vm._s(receipt.receipt_code)+" ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('p',{staticClass:"mb-0 me-2"},[_vm._v(_vm._s(receipt.dest))]),_c('img',{staticClass:"cursor-pointer",attrs:{"src":require("../assets/trash.svg"),"alt":"trash"},on:{"click":function($event){return _vm.removeSelectedReceipt(receipt)}}})])])}),0):_c('div',[_vm._m(5),_c('p',{staticClass:"text-gray text-center mt-5"},[_vm._v(" Belum ada resi yang dipilih ")])])])]),_c('div',{staticClass:"mb-4 col-lg-6"},[_c('div',{staticClass:"box-manifest"},[_c('h5',{staticClass:"fw-bold"},[_vm._v("Ringkasan Manifest")]),_c('table',{staticClass:"w-100 mt-3",attrs:{"cellpadding":"10"}},[_c('tr',{staticClass:"border-bottom"},[_vm._m(6),_c('td',[_vm._v(":")]),_c('td',{staticClass:"fw-bold"},[_vm._v(" "+_vm._s(_vm.originRegency && _vm.destinationRegency ? _vm.originRegency.name + " - " + _vm.destinationRegency.name : "-")+" ")])]),_c('tr',{staticClass:"border-bottom"},[_vm._m(7),_c('td',[_vm._v(":")]),_c('td',{staticClass:"fw-bold"},[_vm._v(" "+_vm._s(_vm.selectedReceipts.length)+" Resi ")])]),_c('tr',[_vm._m(8),_c('td',[_vm._v(":")]),_c('td',{staticClass:"fw-bold"},[_c('DatePicker',{attrs:{"placeholder-text":"Atur perkiraan waktu tiba","disabled-past":"","disabled":_vm.selectedReceipts.length === 0,"no-icon":""},model:{value:(_vm.timeArrive),callback:function ($$v) {_vm.timeArrive=$$v},expression:"timeArrive"}})],1)])]),_c('button',{staticClass:"w-100 mt-3 btn",class:{
                        'btn-create':
                            _vm.selectedReceipts.length > 0 ||
                            _vm.timeArrive === null,
                        'btn-create-disabled':
                            _vm.selectedReceipts.length === 0,
                    },attrs:{"disabled":_vm.selectedReceipts.length === 0 || _vm.timeArrive === null},on:{"click":function($event){_vm.modalConfirm = true}}},[_vm._v(" Buat Manifest ")])])])]),(_vm.modalConfirm)?_c('div',{staticClass:"modal-vue"},[_c('div',{staticClass:"overlay"}),_c('div',{staticClass:"modal-body-chat vsm"},[_vm._m(9),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 mt-4"},[_c('a',{staticClass:"btn btn-outline-yellow w-100 btn-lg",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.modalConfirm = false}}},[_vm._v(" Kembali ")])]),_c('div',{staticClass:"col-lg-6 mt-4"},[_c('button',{staticClass:"btn btn-primary w-100 btn-lg",attrs:{"type":"button","disabled":_vm.isLoading},on:{"click":function($event){return _vm.createManifest()}}},[(_vm.isLoading)?_c('span',{staticClass:"spinner-border spinner-border-sm me-2"}):_vm._e(),_vm._v(" Iya ")])])])])]):_vm._e(),(_vm.modalSuccess)?_c('div',{staticClass:"modal-vue"},[_c('div',{staticClass:"overlay"}),_c('div',{staticClass:"modal-body-chat vsm"},[_vm._m(10),_c('div',{staticClass:"mt-4"},[_c('router-link',{staticClass:"btn btn-primary w-100 btn-lg",attrs:{"to":"/manifest"}},[_vm._v(" Tutup ")])],1)])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"mb-2"},[_vm._v("Kota/Kabupaten Asal Manifest "),_c('span',{staticClass:"text-yellow"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"mb-2"},[_vm._v("Kota/Kabupaten Tujuan Manifest "),_c('span',{staticClass:"text-yellow"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"mb-3"},[_vm._v("Pilih resi yang yang akan ditambahkan pada manifest "),_c('span',{staticClass:"text-yellow"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"border-bottom"},[_c('td',{staticClass:"text-center"},[_vm._v(" Koli ")]),_c('td',{staticClass:"text-center"},[_vm._v(" Berat (Kg) ")]),_c('td',{staticClass:"text-center"},[_vm._v(" Berat Volume (Kg) ")]),_c('td',{staticClass:"text-center"},[_vm._v(" Kubikasi (Kg3) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center mt-5"},[_c('img',{attrs:{"src":require("../assets/empty.svg"),"alt":"empty"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center"},[_c('img',{attrs:{"src":require("../assets/empty.svg"),"alt":"empty"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"pl-0"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"me-2",attrs:{"src":require("../assets/rute.svg"),"alt":"rute"}}),_vm._v(" Rute Manifest ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"pl-0"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"me-2",attrs:{"src":require("../assets/jumlah.svg"),"alt":"jumlah"}}),_vm._v(" Jumlah Resi ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"pl-0"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"me-2",attrs:{"src":require("../assets/arrive.svg"),"alt":"arrive"}}),_vm._v(" Waktu Tiba ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 text-center"},[_c('img',{attrs:{"src":require("../assets/info-yellow.png"),"width":"80","alt":""}}),_c('h5',{staticClass:"mb-0 fw-bold mt-4"},[_vm._v("Manifest Akan Dibuat")]),_c('div',{staticClass:"text-gray-dashboard"},[_vm._v(" Apakah Anda yakin ingin membuat manifest ini? ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 text-center"},[_c('img',{attrs:{"src":require("../assets/dashboard/checklis.png"),"width":"70","alt":""}}),_c('h5',{staticClass:"mb-0 fw-bold mt-4"},[_vm._v("Manifest Berhasil Dibuat")])])
}]

export { render, staticRenderFns }