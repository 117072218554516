import { render, staticRenderFns } from "./AssignmentList.vue?vue&type=template&id=3cc3e2cd&scoped=true"
import script from "./AssignmentList.vue?vue&type=script&lang=js"
export * from "./AssignmentList.vue?vue&type=script&lang=js"
import style0 from "./AssignmentList.vue?vue&type=style&index=0&id=3cc3e2cd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cc3e2cd",
  null
  
)

export default component.exports