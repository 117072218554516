<template>
    <div class="padding-container">
        <div class="content-load" v-if="isPageLoading"></div>
        <div id="loader" v-if="isPageLoading"></div>
        <div class="fw-bold">
            <div
                class="text-gray-dashboard size-16 cursor-pointer"
                @click="$router.go(-1)"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="box-manifest mt-4">
            <h5 class="fw-bold mb-4">Informasi Vendor</h5>
            <div class="row">
                <div class="col-lg-6 mb-4">
                    <label class="mb-2"
                        >Nama Vendor <span class="text-yellow">*</span></label
                    >
                    <multiselect
                        v-model="vendor"
                        :options="vendors"
                        label="name"
                        track-by="id"
                        placeholder="Cari nama vendor"
                        class="multi-form-custom magnifier"
                        :show-labels="false"
                        @input="getDetailVendor()"
                    >
                        <template v-slot:caret>
                            <img
                                :src="
                                    vendor ? magnifierActiveIcon : magnifierIcon
                                "
                                alt="icon"
                                style="
                                    width: 20px;
                                    height: 20px;
                                    position: absolute;
                                    left: 12px;
                                    top: 42%;
                                    transform: translateY(-50%);
                                "
                            />
                        </template>
                    </multiselect>
                </div>
                <div class="col-lg-6 mb-4" v-if="vendor && detailVendor.phone">
                    <label class="mb-2"
                        >Nomor Telepon <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        v-model="detailVendor.phone"
                        disabled
                    />
                </div>
                <div class="col-lg-12 mb-4" v-if="vendor && detailVendor.email">
                    <div class="row">
                        <div class="col-lg-6">
                            <label class="mb-2"
                                >Email <span class="text-yellow">*</span></label
                            >
                            <input
                                type="email"
                                class="form-control"
                                v-model="detailVendor.email"
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div
                    class="col-lg-12 mb-4"
                    v-if="vendor && detailVendor.address"
                >
                    <label class="mb-2"
                        >Alamat Vendor <span class="text-yellow">*</span></label
                    >
                    <textarea
                        class="form-control"
                        v-model="detailVendor.address"
                        disabled
                    ></textarea>
                </div>
            </div>
        </div>
        <div class="box-manifest mt-4">
            <h5 class="fw-bold mb-4">Rute</h5>
            <div v-if="!vendor">
                <div class="d-flex justify-content-center mt-5">
                    <img src="../assets/empty.svg" alt="empty" />
                </div>
                <p class="text-gray text-center mt-5">
                    Pilih vendor terlebih dahulu
                </p>
            </div>
            <div v-else>
                <div v-for="(route, index) in routes" :key="index" class="mb-4">
                    <div
                        class="d-flex justify-content-between header-sidebar align-items-center p-3"
                        :class="{
                            'bb-r': expandedSections.includes(index),
                        }"
                        @click="toggleSection(index)"
                    >
                        <h5 class="fw-bold mb-0">
                            Informasi Rute {{ index + 1 }}
                        </h5>
                        <div class="d-flex">
                            <button
                                v-if="index > 0"
                                class="btn outline-green me-3 d-flex align-items-center"
                                @click.stop="deleteRoute(index)"
                            >
                                <img
                                    src="../assets/delete.svg"
                                    alt="trash"
                                    class="me-2"
                                />
                                Hapus Bagian
                            </button>
                            <img
                                src="../assets/chevron-down.svg"
                                alt="chevron down"
                                :class="{
                                    'rotate-180 transition-transform':
                                        expandedSections.includes(index),
                                }"
                            />
                        </div>
                    </div>
                    <div
                        class="body-sidebar px-3 py-2"
                        v-show="!expandedSections.includes(index)"
                    >
                        <div class="row">
                            <div class="col-lg-12 my-4">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <label class="mb-2"
                                            >Jenis Vendor
                                            <span class="text-yellow"
                                                >*</span
                                            ></label
                                        >
                                        <multiselect
                                            v-model="route.vendorType"
                                            :options="vendorTypes"
                                            label="name"
                                            track-by="id"
                                            placeholder="Pilih jenis vendor"
                                            class="multi-form-custom"
                                            :show-labels="false"
                                            @input="
                                                handleVendorTypeChange(route)
                                            "
                                        ></multiselect>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-4">
                                <label class="mb-2"
                                    >Rute Asal
                                    <span class="text-yellow">*</span></label
                                >
                                <multiselect
                                    v-model="route.originRegency"
                                    :options="regencies"
                                    label="name"
                                    track-by="id"
                                    placeholder="Cari kota/kab. asal"
                                    class="multi-form-custom magnifier"
                                    :show-labels="false"
                                >
                                    <template v-slot:caret>
                                        <img
                                            :src="
                                                route.originRegency
                                                    ? magnifierActiveIcon
                                                    : magnifierIcon
                                            "
                                            alt="icon"
                                            style="
                                                width: 20px;
                                                height: 20px;
                                                position: absolute;
                                                left: 12px;
                                                top: 42%;
                                                transform: translateY(-50%);
                                            "
                                        />
                                    </template>
                                </multiselect>
                            </div>
                            <div class="col-lg-6 mb-4">
                                <label class="mb-2"
                                    >Rute Tujuan
                                    <span class="text-yellow">*</span></label
                                >
                                <multiselect
                                    v-model="route.destinationRegency"
                                    :options="regencies"
                                    label="name"
                                    track-by="id"
                                    placeholder="Cari kota/kab. tujuan"
                                    class="multi-form-custom magnifier"
                                    :show-labels="false"
                                >
                                    <template v-slot:caret>
                                        <img
                                            :src="
                                                route.destinationRegency
                                                    ? magnifierActiveIcon
                                                    : magnifierIcon
                                            "
                                            alt="icon"
                                            style="
                                                width: 20px;
                                                height: 20px;
                                                position: absolute;
                                                left: 12px;
                                                top: 42%;
                                                transform: translateY(-50%);
                                            "
                                        />
                                    </template>
                                </multiselect>
                            </div>
                            <div class="col-lg-6 mb-4">
                                <label class="mb-2"
                                    >Harga Pengiriman
                                    <span class="text-yellow">*</span></label
                                >
                                <div class="input-group mb-3">
                                    <span class="input-group-text">Rp</span>
                                    <input
                                        type="text"
                                        class="form-control b-l-0"
                                        placeholder="Masukkan harga pengiriman"
                                        :value="route.formattedPrice"
                                        @input="handlePriceInput(route, $event)"
                                    />
                                </div>
                            </div>
                            <div
                                class="col-lg-6 mb-4"
                                v-if="
                                    route.vendorType &&
                                    route.vendorType.id === 'darat'
                                "
                            >
                                <label class="mb-2"
                                    >Jenis Armada
                                    <span class="text-yellow">*</span></label
                                >
                                <multiselect
                                    v-model="route.fleet"
                                    :options="fleets"
                                    label="name"
                                    track-by="id"
                                    placeholder="Pilih jenis armada"
                                    class="multi-form-custom"
                                    :show-labels="false"
                                ></multiselect>
                            </div>
                            <div
                                class="col-lg-6 mb-4"
                                v-if="
                                    route.vendorType &&
                                    route.vendorType.id === 'laut'
                                "
                            >
                                <label class="mb-2"
                                    >Nama Kapal
                                    <span class="text-yellow">*</span></label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Masukkan nama kapal"
                                    v-model="route.shipName"
                                />
                            </div>
                            <div
                                class="col-lg-6 mb-4"
                                v-if="
                                    route.vendorType &&
                                    route.vendorType.id === 'udara'
                                "
                            >
                                <label class="mb-2"
                                    >Nama Pesawat
                                    <span class="text-yellow">*</span></label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Masukkan nama pesawat"
                                    v-model="route.planeName"
                                />
                            </div>
                            <div class="col-lg-6 mb-4">
                                <label class="mb-2">Lead Time</label>
                                <div class="input-group mb-3">
                                    <input
                                        type="text"
                                        class="form-control b-r-0"
                                        placeholder="Masukkan lead time"
                                        :value="route.formattedLeadTime"
                                        @input="
                                            handleLeadTimeInput(route, $event)
                                        "
                                    />
                                    <span class="input-group-text">Hari</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="w-100 outline-add btn" @click="addRoute">
                    <i class="pi pi-plus me-2"></i>Tambah Rute
                </button>
                <div class="d-flex justify-content-end mt-4">
                    <button
                        class="btn btn-primary px-5"
                        :disabled="!isFormValid"
                        @click="modalConfirm = true"
                    >
                        Simpan
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Informasi rute vendor ini akan disimpan
                    </h5>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="addData()"
                            :disabled="isLoading"
                        >
                            <span
                                v-if="isLoading"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Informasi rute vendor berhasil dibuat
                    </h5>
                </div>
                <router-link
                    to="/route"
                    href="javascript:void(0)"
                    class="btn btn-primary w-100 btn-lg mt-4"
                >
                    Tutup
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import Multiselect from "vue-multiselect";
import magnifierDisabled from "../assets/magnifier-disabled.svg";
import magnifier from "../assets/magnifier.svg";

export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            isLoading: false,
            hasCalledRegencies: false,
            hasCalledFleets: false,
            modalConfirm: false,
            modalSuccess: false,
            expandedSections: [],
            isPageLoading: false,
            magnifierIcon: magnifierDisabled,
            magnifierActiveIcon: magnifier,
            vendor: null,
            vendors: [],
            routes: [
                {
                    vendorType: null,
                    originRegency: null,
                    destinationRegency: null,
                    price: "",
                    fleet: null,
                    shipName: "",
                    planeName: "",
                    leadTime: "",
                    formattedPrice: "",
                    formattedLeadTime: "",
                },
            ],
            fleets: [],
            vendorTypes: [
                {
                    name: "Darat",
                    id: "darat",
                },
                {
                    name: "Laut",
                    id: "laut",
                },
                {
                    name: "Udara",
                    id: "udara",
                },
            ],
            regencies: [],
            detailVendor: {
                phone: null,
                email: null,
                address: null,
            },
        };
    },
    computed: {
        isFormValid() {
            return this.routes.every((route) => {
                const hasRequiredFields =
                    route.vendorType &&
                    route.originRegency &&
                    route.destinationRegency &&
                    route.price;

                if (!hasRequiredFields) return false;

                switch (route.vendorType.id) {
                    case "darat":
                        return !!route.fleet;
                    case "laut":
                        return !!route.shipName;
                    case "udara":
                        return !!route.planeName;
                    default:
                        return false;
                }
            });
        },
    },
    created() {
        this.getVendors();
    },
    methods: {
        formatNumber(value) {
            if (!value) return "";
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },

        unformatNumber(value) {
            if (!value) return "";
            return value.toString().replace(/\./g, "");
        },

        handlePriceInput(route, event) {
            const unformattedValue = this.unformatNumber(event.target.value);
            if (!isNaN(unformattedValue)) {
                route.price = unformattedValue;
                route.formattedPrice = this.formatNumber(unformattedValue);
            }
        },

        handleLeadTimeInput(route, event) {
            const unformattedValue = this.unformatNumber(event.target.value);
            if (!isNaN(unformattedValue)) {
                route.leadTime = unformattedValue;
                route.formattedLeadTime = this.formatNumber(unformattedValue);
            }
        },
        deleteRoute(index) {
            this.routes.splice(index, 1);
        },
        addRoute() {
            this.routes.push({
                vendorType: null,
                originRegency: null,
                destinationRegency: null,
                price: "",
                fleet: null,
                shipName: "",
                planeName: "",
                leadTime: "",
                formattedPrice: "",
                formattedLeadTime: "",
            });
        },
        getDetailVendor() {
            this.isPageLoading = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/vendors/detail`, {
                params: {
                    vendor_id: this.vendor.id,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.detailVendor = res.data.data;
                    if (!this.hasCalledRegencies) {
                        this.getRegencies();
                    }
                    this.isPageLoading = false;
                })
                .catch((err) => {
                    this.isPageLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getVendors() {
            this.isPageLoading = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/routes/list_vendor`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.vendors = res.data.data;
                    this.isPageLoading = false;
                })
                .catch((err) => {
                    this.isPageLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getFleets() {
            if (this.hasCalledFleets) return;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/fleet_type`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.fleets = res.data.data;
                    this.hasCalledFleets = true;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        addData() {
            this.isLoading = true;
            const routeData = this.routes.map((route) => ({
                vendor_id: this.vendor.id,
                vendor_type: route.vendorType.id,
                origin_regency_id: route.originRegency.id,
                destination_regency_id: route.destinationRegency.id,
                price: this.unformatNumber(route.price),
                leadtime: route.leadTime
                    ? this.unformatNumber(route.leadTime)
                    : null,
                vehicle:
                    route.vendorType.id === "darat"
                        ? route.fleet.name
                        : route.vendorType.id === "laut"
                        ? route.shipName
                        : route.vendorType.id === "udara"
                        ? route.planeName
                        : null,
            }));

            const data = {
                routes: routeData,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/routes/create`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isLoading = false;
                    this.modalSuccess = true;
                    this.modalConfirm = false;
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.modalConfirm = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        toggleSection(index) {
            if (this.expandedSections.includes(index)) {
                this.expandedSections = this.expandedSections.filter(
                    (i) => i !== index
                );
            } else {
                this.expandedSections.push(index);
            }
        },
        getRegencies() {
            if (this.hasCalledRegencies) return;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.regencies = res.data.data;
                    this.hasCalledRegencies = true;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        handleVendorTypeChange(route) {
            if (route.vendorType && route.vendorType.id === "darat") {
                this.getFleets();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.outline-add {
    background-color: transparent !important;
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
}
.header-sidebar {
    background-color: #f3f5f7;
    border: 1px solid #c1cdd7;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    cursor: pointer;
}
.body-sidebar {
    background-color: #ffffff;
    border: 1px solid #c1cdd7;
    border-top: none;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
.b-l-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.b-r-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
::v-deep .multi-form-custom.magnifier {
    .multiselect__tags {
        padding-left: 40px !important;
    }
    .multiselect__select {
        padding: 0;
    }
}
.outline-green {
    border: 1px solid #ff8600 !important;
    color: #ff8600 !important;
    background-color: transparent !important;
    border-radius: 8px;
}
</style>
