<template>
    <div class="chart-container mt-3" style="position: relative; height: 250px">
        <svg viewBox="0 0 200 200" class="w-100 h-100">
            <defs>
                <filter
                    id="shadow"
                    x="-20%"
                    y="-20%"
                    width="140%"
                    height="140%"
                >
                    <feDropShadow
                        dx="0"
                        dy="0"
                        stdDeviation="3"
                        flood-color="#000000"
                        flood-opacity="0.3"
                    />
                </filter>
            </defs>
            <path v-if="isAllZero" :d="getFullCirclePath(90)" fill="#F3F5F7" />
            <path
                v-else-if="hasOnlyOneValue"
                :d="getFullCirclePath(hoveredIndex === 0 ? 95 : 90)"
                :fill="nonZeroData[0].color"
                :filter="hoveredIndex === 0 ? 'url(#shadow)' : ''"
                :style="{ transition: 'all 0.3s ease' }"
                @mouseenter="hoveredIndex = 0"
                @mouseleave="hoveredIndex = null"
            />
            <template v-else>
                <path
                    v-for="(segment, index) in segments"
                    :key="`bg-${index}`"
                    :d="segment.path"
                    :fill="
                        hoveredIndex !== null && hoveredIndex !== index
                            ? '#F3F5F7'
                            : 'white'
                    "
                />
                <g v-for="(segment, index) in segments" :key="index">
                    <path
                        :d="
                            getArcPath(
                                segment.startAngle + 1,
                                segment.endAngle - 1,
                                hoveredIndex === index ? 95 : 90
                            )
                        "
                        :fill="
                            hoveredIndex !== null && hoveredIndex !== index
                                ? '#F3F5F7'
                                : segment.color
                        "
                        :filter="hoveredIndex === index ? 'url(#shadow)' : ''"
                        :style="{
                            transition: 'all 0.3s ease',
                        }"
                        @mouseenter="hoveredIndex = index"
                        @mouseleave="hoveredIndex = null"
                    />
                </g>
            </template>
            <circle cx="100" cy="100" r="60" fill="white" />
        </svg>
        <div
            style="
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                pointer-events: none;
            "
        >
            <span
                :style="{
                    fontSize: centerTextFontSize,
                    fontWeight: 'bold',
                    display: 'block',
                    color:
                        hoveredIndex === null
                            ? '#3E4E5C'
                            : nonZeroData[hoveredIndex]?.color || '#3E4E5C',
                }"
            >
                {{ formattedCenterTextValue }}
            </span>
            <span style="font-size: 14px; color: #666">
                {{ centerTextLabel }}
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name: "DonutChart",
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        customCenterTextLabel: {
            type: String,
            default: "Total",
        },
        centerTextValue: {
            type: [String, Number],
            default: null,
        },
        currency: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            hoveredIndex: null,
        };
    },
    computed: {
        nonZeroData() {
            return this.data.filter((item) => item.value > 0);
        },
        hasOnlyOneValue() {
            return this.nonZeroData.length === 1 && this.data.length === 2;
        },
        isAllZero() {
            return this.data.every((item) => item.value === 0);
        },
        total() {
            return this.nonZeroData.reduce((sum, item) => sum + item.value, 0);
        },
        segments() {
            if (this.isAllZero || this.hasOnlyOneValue) return [];
            let currentAngle = 0;
            return this.nonZeroData.map((item) => {
                const angle = (item.value / this.total) * 360;
                const startAngle = currentAngle;
                const endAngle = currentAngle + angle;
                const path = this.getArcPath(startAngle, endAngle, 85);
                currentAngle = endAngle;
                return { ...item, path, startAngle, endAngle };
            });
        },
        formattedCenterTextValue() {
            const value =
                this.hoveredIndex !== null
                    ? this.nonZeroData[this.hoveredIndex]?.value
                    : this.centerTextValue ?? this.total;
            if (this.currency) {
                const formatted = new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                }).format(value);
                return formatted;
            }
            return value;
        },
        centerTextFontSize() {
            const value = this.centerTextValue ?? this.total;
            return value >= 1000000 ? "15px" : "24px";
        },
        centerTextLabel() {
            if (this.hoveredIndex === null) return this.customCenterTextLabel;
            return (
                this.nonZeroData[this.hoveredIndex]?.label ||
                this.customCenterTextLabel
            );
        },
    },
    methods: {
        getFullCirclePath(radius) {
            return `M 100 100 m -${radius}, 0 a ${radius},${radius} 0 1,1 ${
                radius * 2
            },0 a ${radius},${radius} 0 1,1 -${radius * 2},0`;
        },
        getArcPath(startAngle, endAngle, radius = 85) {
            const rad = (angle) => ((angle - 90) * Math.PI) / 180;
            const [x1, y1] = [
                100 + radius * Math.cos(rad(startAngle)),
                100 + radius * Math.sin(rad(startAngle)),
            ];
            const [x2, y2] = [
                100 + radius * Math.cos(rad(endAngle)),
                100 + radius * Math.sin(rad(endAngle)),
            ];
            const largeArc = endAngle - startAngle <= 180 ? 0 : 1;
            return `M 100 100 L ${x1} ${y1} A ${radius} ${radius} 0 ${largeArc} 1 ${x2} ${y2} Z`;
        },
    },
};
</script>
<style scoped>
.chart-container {
    display: inline-block;
}
</style>
