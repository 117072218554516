var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"padding-container"},[_c('div',{staticClass:"fw-bold"},[_c('div',{staticClass:"text-gray-dashboard size-16 cursor-pointer",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"fa fa-angle-left me-2"}),_vm._v(" Kembali ")])]),_c('div',{staticClass:"box mt-4 m-mt-0 position-relative"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('h5',{staticClass:"fw-bold"},[_vm._v("Notifikasi")]),(_vm.notif_all.length !== 0)?_c('a',{staticClass:"text-primary",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.readNotif()}}},[_c('u',[_vm._v("Tandai semua telah dibaca")])]):_vm._e()]),_vm._l((_vm.notif_all),function(notif){return _c('a',{key:notif.id,attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.readNotif(notif.id, notif.meta.path)}}},[_c('div',{staticClass:"p-3 mt-2"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"me-3"},[_c('img',{staticClass:"notif-avatar-sidebar",attrs:{"src":notif.status === 'pending'
                                    ? '../assets/bell-unread.png'
                                    : '../assets/bell-read.png',"alt":"notification bell"}})]),_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"size-16 fw-semibold",class:notif.status === 'pending'
                                        ? 'title-unread'
                                        : 'text-gray'},[_vm._v(" "+_vm._s(notif.title)+" ")]),_c('div',{staticClass:"ms-auto text-gray"},[_vm._v(" "+_vm._s(notif.created_at)+" ")])]),_c('div',{class:notif.status === 'pending'
                                    ? 'subtitle-unread'
                                    : 'text-gray'},[_vm._v(" "+_vm._s(notif.subtitle)+" ")])])])])])}),(_vm.notif_all.length === 0)?_c('div',{staticClass:"mt-4"},[_c('center',[_c('img',{attrs:{"src":require("../assets/no-notif.png"),"width":"120","alt":""}}),_c('div',{staticClass:"mt-3 text-gray-dashboard"},[_vm._v(" Belum ada notifikasi ")])])],1):_vm._e(),_c('div',{staticClass:"mt-4"},[(_vm.notif_all.length > 0)?_c('b-pagination-nav',{attrs:{"number-of-pages":_vm.total_page,"base-url":"#","first-number":"","align":"right"},on:{"input":_vm.changePage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }