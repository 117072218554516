<template>
    <div class="padding-container">
        <div class="content-load" v-if="isPageLoading"></div>
        <div id="loader" v-if="isPageLoading"></div>
        <div
            class="d-flex flex-wrap justify-content-between align-items-center"
        >
            <div class="mb-3 mw-80">
                <h5 class="fw-bold">
                    Halo {{ user.name }} 👋,
                    <span>Selamat Datang Kembali!</span>
                </h5>
                <p class="text-gray mb-0">
                    Menampilkan data tanggal
                    <span
                        v-if="
                            dateRangeFilter &&
                            dateRangeFilter.from &&
                            dateRangeFilter.to
                        "
                        class="fw-bold text-primary"
                    >
                        {{
                            formatDateRange(
                                dateRangeFilter.from,
                                dateRangeFilter.to
                            )
                        }}
                    </span>
                    <span class="fw-bold" v-else>{{
                        date.format("DD MMMM YYYY")
                    }}</span>
                </p>
            </div>
            <DateRangePicker
                v-model="dateRangeFilter"
                :onFilter="filterData"
                :onReset="resetData"
            />
        </div>
        <div class="row my-3">
            <div class="col-lg-8 my-3">
                <div class="box h-100">
                    <p>Aktivitas Follow Up</p>
                    <div class="row">
                        <div class="col-lg-6 my-2">
                            <div class="box-follow-up">
                                <div class="d-flex justify-content-between">
                                    <div class="d-flex flex-column">
                                        <p class="mb-2">Follow Up Hari Ini</p>
                                        <router-link
                                            to="/followup/today"
                                            class="d-flex"
                                        >
                                            <h5 class="me-2 mb-0 fw-bold">
                                                {{
                                                    datas?.activities
                                                        ?.followup_today
                                                }}
                                                Customer
                                            </h5>
                                            <img
                                                src="../../assets/arrow-follow-up.svg"
                                                alt="arrow follow up"
                                            />
                                        </router-link>
                                    </div>
                                    <img
                                        src="../../assets/warning-dashboard.svg"
                                        alt="warning dashboard"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 my-2">
                            <div class="box-follow-up">
                                <div class="d-flex justify-content-between">
                                    <div class="d-flex flex-column">
                                        <p class="mb-2">Butuh Follow Up</p>
                                        <router-link
                                            to="/followup/all"
                                            class="d-flex"
                                        >
                                            <h5 class="me-2 mb-0 fw-bold">
                                                {{
                                                    datas?.activities
                                                        ?.need_followup
                                                }}
                                                Customer
                                            </h5>
                                            <img
                                                src="../../assets/arrow-follow-up.svg"
                                                alt="arrow follow up"
                                            />
                                        </router-link>
                                    </div>
                                    <img
                                        src="../../assets/warning-dashboard.svg"
                                        alt="warning dashboard"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 my-3">
                <div class="box h-100">
                    <p>Butuh Request Penjemputan</p>
                    <div class="d-flex align-items-center">
                        <img
                            src="../../assets/driver-dashboard.svg"
                            alt="driver dashboard"
                            class="me-3"
                        />
                        <router-link to="/deal-order" class="d-flex">
                            <h5
                                class="mb-0 border-bottom border-black me-2 fw-bold text-black"
                            >
                                {{ datas?.need_request_pickup }} Deal ID
                            </h5>
                            <img
                                src="../../assets/arrow-dashboard-2.svg"
                                alt="arrow dashboard 2"
                            />
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 my-3">
                <div
                    class="box-yellow-dashboard h-100"
                    :class="{ 'box-shadow': hasFilter }"
                >
                    <div class="d-flex align-items-center mb-3">
                        <img
                            src="../../assets/total-item-dashboard.svg"
                            alt="total item dashboard"
                            class="me-3"
                        />
                        <router-link to="/deal-order" class="d-flex w-100">
                            <h5
                                class="fw-bold me-3 text-black border-bottom border-black mb-0"
                            >
                                {{ currency(datas?.deal_order?.amount) }}
                            </h5>
                            <img
                                src="../../assets/arrow-dashboard-2.svg"
                                alt="arrow dashboard 2"
                            />
                        </router-link>
                    </div>
                    <p>Deal Order</p>
                    <p class="mb-0">
                        <span class="text-gray">dari </span>&nbsp;
                        {{ datas?.deal_order?.total }} Resi
                    </p>
                </div>
            </div>
            <div class="col-lg-4 my-3">
                <div
                    class="box-green-dashboard h-100"
                    :class="{ 'box-shadow': hasFilter }"
                >
                    <router-link
                        to="/lost-order"
                        class="d-flex align-items-center mb-3"
                    >
                        <img
                            src="../../assets/lost-dashboard.svg"
                            alt="lost dashboard"
                            class="me-3"
                        />
                        <div class="d-flex w-100">
                            <h5
                                class="fw-bold me-3 text-black border-bottom border-black mb-0"
                            >
                                {{ datas?.lost_order }} Customer
                            </h5>
                            <img
                                src="../../assets/arrow-dashboard-2.svg"
                                alt="arrow dashboard 2"
                            />
                        </div>
                    </router-link>
                    <p>Lost Order</p>
                </div>
            </div>
            <div class="col-lg-4 my-3">
                <div
                    class="box-blue-dashboard h-100"
                    :class="{ 'box-shadow': hasFilter }"
                >
                    <div class="d-flex align-items-center mb-3">
                        <img
                            src="../../assets/prediction-dashboard.svg"
                            alt="prediction dashboard"
                            class="me-3"
                        />
                        <div class="d-flex w-100">
                            <h5 class="fw-bold me-3 text-black mb-0">
                                {{ currency(datas?.total_prediction) }}
                            </h5>
                        </div>
                    </div>
                    <p>Prediksi</p>
                </div>
            </div>
            <div class="col-lg-4 my-3">
                <div
                    class="box h-100 d-flex flex-column justify-content-center"
                    :class="{ 'box-shadow': hasFilter }"
                >
                    <div class="d-flex">
                        <img
                            src="../../assets/pack-dashboard.svg"
                            alt="pack dashboard"
                            class="me-3"
                        />
                        <div
                            class="d-flex flex-column justify-content-center w-100"
                        >
                            <p class="mb-2">Total Barang</p>
                            <h5 class="fw-bold mb-0">
                                {{ datas?.items?.total_item }} Koli
                            </h5>
                        </div>
                    </div>
                    <hr />
                    <div class="d-flex">
                        <img
                            src="../../assets/weight-dashboard.svg"
                            alt="weight dashboard"
                            class="me-3"
                        />
                        <div
                            class="d-flex flex-column justify-content-center w-100"
                        >
                            <p class="mb-2">Total Berat Barang</p>
                            <h5 class="fw-bold mb-0">
                                {{ datas?.items?.total_weight }} Kg
                            </h5>
                        </div>
                    </div>
                    <hr />
                    <div class="d-flex">
                        <img
                            src="../../assets/cubic-dashboard.svg"
                            alt="cubic dashboard"
                            class="me-3"
                        />
                        <div
                            class="d-flex flex-column justify-content-center w-100"
                        >
                            <p class="mb-2">Total Kubikasi</p>
                            <h5 class="fw-bold mb-0">
                                {{ datas?.items?.total_cubic }} M3
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 my-3">
                <div
                    class="box h-100 d-flex flex-column justify-content-center"
                    :class="{ 'box-shadow': hasFilter }"
                >
                    <p class="mb-3">Order Process</p>
                    <div
                        class="order-process-bar"
                        v-if="
                            datas?.order_process?.incoming === 0 &&
                            datas?.order_process?.manifest.receipt === 0 &&
                            datas?.order_process?.success === 0
                        "
                    >
                        <div class="h-100 bg-empty w-100">
                            <span class="order-process-label">0%</span>
                        </div>
                    </div>
                    <div class="order-process-bar" v-else>
                        <div
                            v-if="datas?.order_process?.incoming !== 0"
                            class="h-100 order-in"
                            :style="{
                                width: `${calculatePercentage(
                                    datas?.order_process?.incoming,
                                    datas?.order_process?.incoming +
                                        datas?.order_process?.manifest.receipt +
                                        datas?.order_process?.success
                                )}%`,
                            }"
                        >
                            <span class="order-process-label"
                                >{{
                                    calculatePercentage(
                                        datas?.order_process?.incoming,
                                        datas?.order_process?.incoming +
                                            datas?.order_process?.manifest
                                                .receipt +
                                            datas?.order_process?.success
                                    )
                                }}%</span
                            >
                        </div>
                        <div
                            v-if="datas?.order_process?.manifest.receipt !== 0"
                            class="h-100 order-manifest"
                            :style="{
                                width: `${calculatePercentage(
                                    datas?.order_process?.manifest.receipt,
                                    datas?.order_process?.incoming +
                                        datas?.order_process?.manifest.receipt +
                                        datas?.order_process?.success
                                )}%`,
                            }"
                        >
                            <span class="order-process-label"
                                >{{
                                    calculatePercentage(
                                        datas?.order_process?.manifest.receipt,
                                        datas?.order_process?.incoming +
                                            datas?.order_process?.manifest
                                                .receipt +
                                            datas?.order_process?.success
                                    )
                                }}%</span
                            >
                        </div>
                        <div
                            v-if="datas?.order_process?.success !== 0"
                            class="h-100 order-done"
                            :style="{
                                width: `${calculatePercentage(
                                    datas?.order_process?.success,
                                    datas?.order_process?.incoming +
                                        datas?.order_process?.manifest.receipt +
                                        datas?.order_process?.success
                                )}%`,
                            }"
                        >
                            <span class="order-process-label"
                                >{{
                                    calculatePercentage(
                                        datas?.order_process?.success,
                                        datas?.order_process?.incoming +
                                            datas?.order_process?.manifest
                                                .receipt +
                                            datas?.order_process?.success
                                    )
                                }}%</span
                            >
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mt-3">
                        <div class="d-flex align-items-center">
                            <img
                                src="../../assets/order-in-dashboard.svg"
                                alt="order in dashboard"
                                class="me-2"
                            />
                            <p class="mb-0">Order Masuk</p>
                        </div>
                        <router-link
                            to="/order/cashier"
                            class="d-flex align-items-center"
                        >
                            <h5 class="mb-0 text-link me-2">
                                {{ datas?.order_process?.incoming }} Resi
                            </h5>
                            <img
                                src="../../assets/arrow-dashboard-blue.svg"
                                alt="arrow dashboard blue"
                            />
                        </router-link>
                    </div>
                    <hr />
                    <div class="d-flex justify-content-between mt-3">
                        <div class="d-flex align-items-center">
                            <img
                                src="../../assets/order-manifest-dashboard.svg"
                                alt="order manifest dashboard"
                                class="me-2"
                            />
                            <p class="mb-0">Manifest</p>
                        </div>
                        <div to="#" class="d-flex align-items-center">
                            <h5 class="mb-0 text-primary me-2">
                                {{ datas?.order_process?.manifest.total }}
                                Manifest ({{
                                    datas?.order_process?.manifest.receipt
                                }}
                                Resi)
                            </h5>
                        </div>
                    </div>
                    <hr />
                    <div class="d-flex justify-content-between mt-3">
                        <div class="d-flex align-items-center">
                            <img
                                src="../../assets/order-done-dashboard.svg"
                                alt="order done dashboard"
                                class="me-2"
                            />
                            <p class="mb-0">Selesai</p>
                        </div>
                        <h5 class="mb-0 text-primary me-2">
                            {{ datas?.order_process?.success }} Resi
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 my-3">
                <div class="box h-100">
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <p class="mb-0 fw-bold">Jadwal Keberangkatan</p>
                        <router-link
                            to="/route"
                            class="d-flex align-items-center"
                            v-if="datas?.schedules?.length !== 0"
                        >
                            <p class="mb-0 text-link me-2">Lihat Detail</p>
                            <img
                                src="../../assets/arrow-link-dashboard.svg"
                                alt="arrow link dashboard"
                            />
                        </router-link>
                    </div>
                    <div
                        class="table-responsive-custom table-scrollable mt-4"
                        v-if="datas?.schedules?.length !== 0"
                    >
                        <table class="table table-bordered">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">No</th>
                                    <th class="text-center" scope="col">
                                        Rute
                                    </th>
                                    <th class="text-center" scope="col">
                                        Nama Vendor
                                    </th>
                                    <th class="text-center" scope="col">
                                        Jenis Vendor
                                    </th>
                                    <th class="text-center" scope="col">
                                        Jadwal Terdekat
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(
                                        schedule, index
                                    ) in datas?.schedules"
                                    :key="index"
                                >
                                    <td class="text-center">{{ index + 1 }}</td>
                                    <td>
                                        {{ schedule.origin_regency }} -
                                        {{ schedule.destination_regency }}
                                    </td>
                                    <td class="text-center">
                                        {{ schedule.vendor_name }}
                                    </td>
                                    <td class="text-center">
                                        <span
                                            v-if="
                                                schedule.vendor_type === 'darat'
                                            "
                                            class="air-badge rounded-pill px-3 py-1"
                                            >Udara</span
                                        >
                                        <span
                                            v-else-if="
                                                schedule.vendor_type === 'laut'
                                            "
                                            class="sea-badge rounded-pill px-3 py-1"
                                            >Laut</span
                                        >
                                        <span v-else>-</span>
                                    </td>
                                    <td class="text-center">
                                        {{
                                            schedule.nearest_schedule
                                                ? schedule.nearest_schedule
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else>
                        <div class="d-flex justify-content-center mt-5">
                            <img src="../../assets/empty.svg" alt="empty" />
                        </div>
                        <p class="text-gray text-center mt-5">
                            Belum ada data jadwal keberangkatan
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 my-3">
                <div class="box h-100">
                    <p class="mb-3">Leaderboards Cabang</p>
                    <div
                        class="row align-items-end px-3 px-md-4 px-lg-5 justify-content-center"
                    >
                        <div
                            class="col-lg-4 order-md-2 my-2"
                            v-if="datas?.leaderboards[1]"
                        >
                            <div
                                class="box-leaderboard box-leaderboard-shadow rounded position-relative overflow-visible"
                            >
                                <img
                                    src="../../assets/sale-first.svg"
                                    class="position-absolute img-info-leaderboard"
                                    alt="Sale First"
                                />
                                <p
                                    class="text-blue-dashboard text-center fw-bold"
                                >
                                    Penjualan Terbanyak!
                                </p>
                                <div class="d-flex my-2 justify-content-center">
                                    <img
                                        src="../../assets/klik-user-empty.svg"
                                        alt="klik user empty"
                                    />
                                </div>
                                <p class="text-center">
                                    {{ datas?.leaderboards[1].name }}
                                </p>
                                <h5
                                    class="text-blue-dashboard text-center fw-bold"
                                >
                                    {{ currency(datas?.leaderboards[1].total) }}
                                </h5>
                            </div>
                        </div>

                        <div
                            class="col-lg-4 order-md-1 my-2"
                            v-if="datas?.leaderboards[0]"
                        >
                            <div
                                class="box-leaderboard rounded position-relative overflow-visible"
                            >
                                <img
                                    src="../../assets/sale-second.svg"
                                    class="position-absolute img-info-leaderboard"
                                    alt="Second"
                                />
                                <div class="d-flex my-2 justify-content-center">
                                    <img
                                        src="../../assets/klik-user-empty.svg"
                                        alt="klik user empty"
                                    />
                                </div>
                                <p class="text-center">
                                    {{ datas?.leaderboards[0].name }}
                                </p>
                                <h5
                                    class="text-blue-dashboard text-center fw-bold"
                                >
                                    {{ currency(datas?.leaderboards[0].total) }}
                                </h5>
                            </div>
                        </div>

                        <div
                            class="col-lg-4 order-md-3 my-2"
                            v-if="datas?.leaderboards[2]"
                        >
                            <div
                                class="box-leaderboard rounded position-relative overflow-visible"
                            >
                                <img
                                    src="../../assets/sale-third.svg"
                                    class="position-absolute img-info-leaderboard"
                                    alt="Sale Third"
                                />
                                <div class="d-flex my-2 justify-content-center">
                                    <img
                                        src="../../assets/klik-user-empty.svg"
                                        alt="klik user empty"
                                    />
                                </div>
                                <p class="text-center">
                                    {{ datas?.leaderboards[2].name }}
                                </p>
                                <h5
                                    class="text-blue-dashboard text-center fw-bold"
                                >
                                    {{ currency(datas?.leaderboards[2].total) }}
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-end justify-content-center">
                        <div
                            class="col-12 my-2 order-md-4"
                            v-for="(
                                leaderboard, index
                            ) in datas?.leaderboards.slice(3)"
                            :key="index"
                        >
                            <div class="box-leaderboard-long rounded">
                                <div
                                    class="d-flex align-items-center leaderboard-content"
                                >
                                    <p class="mb-0 p-leaderboard px-4">
                                        {{ index + 4 }}th
                                    </p>
                                    <div class="vertical-divider"></div>
                                    <div
                                        class="d-flex w-100 justify-content-between align-items-center p-leaderboard leaderboard-details"
                                    >
                                        <div
                                            class="d-flex align-items-center leaderboard-user-info"
                                        >
                                            <img
                                                src="../../assets/klik-user-empty.svg"
                                                alt="klik user empty"
                                                width="50"
                                                class="me-3"
                                            />
                                            <p class="mb-0">
                                                {{ leaderboard.name }}
                                            </p>
                                        </div>
                                        <h5 class="mb-0">
                                            {{ currency(leaderboard.total) }}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import Api from "../../api/Api";
import "moment/locale/id";
import DateRangePicker from "../../components/DateRangePicker.vue";

moment.locale("id");

export default {
    components: {
        DateRangePicker,
    },
    data() {
        return {
            dateRangeFilter: {
                from: null,
                to: null,
            },
            isPageLoading: true,
            date: moment(),
            datas: null,
            hasFilter: false,
        };
    },
    created() {
        this.getDatas();
        const userFromLocalStorage = localStorage.getItem("user");

        if (userFromLocalStorage) {
            this.user = JSON.parse(userFromLocalStorage);
        }
    },
    methods: {
        filterData(dateRangeFilter) {
            this.dateRangeFilter = dateRangeFilter;
            this.hasFilter = true;
            this.getDatas();
        },
        resetData() {
            this.dateRangeFilter.from = null;
            this.dateRangeFilter.to = null;
            this.hasFilter = false;
            this.getDatas();
        },
        calculatePercentage(value, total) {
            if (total === 0) return 0;
            return Math.round((value / total) * 100);
        },
        formatDateRange(fromDate, toDate) {
            if (!fromDate || !toDate) return "";

            const from = moment(fromDate);
            const to = moment(toDate);

            return `${from.format("D")} ${from.format(
                "MMMM YYYY"
            )} - ${to.format("D")} ${to.format("MMMM YYYY")}`;
        },
        getDatas() {
            this.isPageLoading = true;

            let payload;

            if (
                this.dateRangeFilter &&
                this.dateRangeFilter.from &&
                this.dateRangeFilter.to
            ) {
                payload = {
                    start_date: moment(this.dateRangeFilter.from).format(
                        "YYYY-MM-DD"
                    ),
                    end_date: moment(this.dateRangeFilter.to).format(
                        "YYYY-MM-DD"
                    ),
                };
            } else {
                payload = {
                    start_date: this.date.format("YYYY-MM-DD"),
                    end_date: this.date.format("YYYY-MM-DD"),
                };
            }

            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/dashboard/crm_specialist`,
                {
                    params: payload,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.datas = res.data.data;
                    this.isPageLoading = false;
                })
                .catch((err) => {
                    this.isPageLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.dot-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dot.active {
    background-color: #006dcc;
}
.left-arrow {
    position: relative;
    left: 5px;
}
.right-arrow {
    position: relative;
    right: 5px;
}
.box-target {
    padding: 20px 0px;
    background-color: #fff;
    border-radius: 1rem;
    border: 1px solid #99d0ff;
}
.padding-target {
    padding: 0px 25px;
}

.chart-arrow {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
}
.box-shadow {
    box-shadow: 1px 1px 10px 0px rgba(18, 145, 255, 0.3);
}
.btn-reset {
    background-color: transparent !important;
    border: 1px solid #849bae !important;
    color: #849bae !important;
}
.btn-reset-active {
    background-color: transparent !important;
    border: 1px solid #ff8600 !important;
    color: #ff8600 !important;
}
.btn-compare {
    background-color: #e0e6eb !important;
    border: none !important;
    color: #849bae !important;
}
.btn-compare-active {
    background-color: #40bf76 !important;
    border: none !important;
    color: #ffffff !important;
}
.btn-filter-dashboard {
    background-color: transparent;
    border: 1px solid #40bf76;
    color: #40bf76;
}
.btn-filter-dashboard:hover {
    background-color: transparent;
    border: 1px solid #40bf76;
    color: #40bf76;
}
.btn-filter-dashboard-active {
    border: none !important;
    background-color: #40bf76 !important;
    color: #ffffff !important;
}
.img-info-leaderboard {
    width: 50px;
    top: -20px;
    right: -15px;
}
.text-blue-dashboard {
    color: #006dcc;
}
.box-leaderboard {
    background-color: #ebf6ff;
    padding: 15px 15px;
}
.box-leaderboard-long {
    background-color: #f5faff;
}

.p-leaderboard {
    padding: 15px;
}

.vertical-divider {
    height: 70px;
    width: 1px;
    background-color: #d9f2e4;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .leaderboard-content {
        flex-direction: column !important;
        text-align: center;
    }

    .vertical-divider {
        display: none;
    }

    .p-leaderboard {
        padding: 15px 0;
    }

    .leaderboard-user-info {
        flex-direction: column !important;
        align-items: center !important;
    }

    .leaderboard-user-info img {
        margin-bottom: 10px !important;
        margin-left: 0 !important;
    }

    .leaderboard-details {
        flex-direction: column !important;
        align-items: center !important;
        width: 100% !important;
    }
}
.box-leaderboard-shadow {
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
}
.air-badge {
    background-color: #faf8d1;
    border: 1px solid #f1eb74;
    color: #8b850e;
}
.sea-badge {
    background-color: #cce7ff;
    border: 1px solid #99d0ff;
    color: #005299;
}
.order-process-bar {
    height: 40px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    position: relative;
}

.order-process-label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #3e4e5c;
    padding: 0 10px;
    left: 0;
}

.order-in {
    background-color: #e0e6eb;
    position: relative;
}

.order-manifest {
    background-color: #ffb766;
    position: relative;
}

.order-done {
    background-color: #8cd9ad;
    position: relative;
}

.table-responsive-custom {
    &.table-scrollable {
        max-height: 410px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th {
            border: 1px solid #c1cdd7;
            background-color: #f3f5f7;
        }

        td {
            border: 1px solid #c1cdd7;
            background-color: #f9fafb;
        }

        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
@media (max-width: 800px) {
    .d-flex.w-100:not(:last-child) {
        border-bottom: 1px solid #c1cdd7;
        padding-bottom: 1rem;
    }
}
hr {
    border-top: 1px solid #c1cdd7 !important;
}
hr.vertical-divider {
    height: 100%;
    border: 1px solid #c1cdd7;
}
.text-black {
    color: #212529 !important;
}
.col-md-5.col-md-5-custom {
    width: 36.666667% !important;
}
.col-md-7.col-md-7-custom {
    width: 63.333333% !important;
}
@media only screen and (max-width: 800px) {
    .col-md-5.col-md-5-custom {
        width: 100% !important;
    }
    .col-md-7.col-md-7-custom {
        width: 100% !important;
    }
}
.dashboard-container {
    padding: 48px 20px 40px 20px;
}
.box-yellow-dashboard {
    border: 1px solid #c1cdd7;
    border-bottom: 3px solid #8cd9ad;
    background-color: #ffffff;
    border-radius: 16px;
    padding: 15px 15px;
}
.box-green-dashboard {
    border: 1px solid #c1cdd7;
    border-bottom: 3px solid #ffb766;
    background-color: #ffffff;
    border-radius: 16px;
    padding: 15px 15px;
}
.box-blue-dashboard {
    border: 1px solid #c1cdd7;
    border-bottom: 3px solid #66b8ff;
    background-color: #ffffff;
    border-radius: 16px;
    padding: 15px 15px;
}

.box-orange {
    border: 1px solid #99d0ff;
    background-color: #cce5ff;
    border-radius: 16px;
    padding: 15px 15px;
}

.box-follow-up {
    background-color: #fff2e5;
    border-radius: 16px;
    padding: 15px 15px;

    p {
        color: #ff9e33;
    }

    h5 {
        color: #cc6c00;
        border-bottom: 1px solid #cc6c00;
    }
}

.box-danger {
    background-color: #1291ff;
    border-radius: 16px;
    padding: 15px 15px;
}
@media (min-width: 1024px) {
    .mw-80 {
        max-width: 65%;
    }
}
.bg-empty {
    background-color: #e0e6eb;
}
</style>
