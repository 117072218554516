<template>
    <div class="padding-container">
        <div class="content-load" v-if="isPageLoading"></div>
        <div id="loader" v-if="isPageLoading"></div>
        <div
            class="d-flex flex-wrap justify-content-between align-items-center"
        >
            <div class="mb-3">
                <h5 class="fw-bold">
                    Halo {{ user.name }} 👋,
                    <span>Selamat Datang Kembali!</span>
                </h5>
                <p class="text-gray mb-0">
                    Menampilkan data tanggal
                    <span v-if="hasDateRange" class="fw-bold text-primary">
                        {{ formatDateRange(dateRange.from, dateRange.to) }}
                    </span>
                    <span class="fw-bold" v-else>{{
                        date.format("DD MMMM YYYY")
                    }}</span>
                </p>
            </div>
            <DateRangePicker
                v-model="dateRange"
                :onFilter="filterData"
                :onReset="resetData"
            />
        </div>
        <div class="row my-3">
            <div class="col-lg-4 my-3">
                <div class="box-orange h-100">
                    <p class="fw-bold">Ukur - Timbang Barang</p>
                    <div class="box-danger">
                        <div class="d-flex">
                            <img
                                src="../../assets/bell-dashboard.svg"
                                alt="bell dashboard"
                                class="me-3"
                            />
                            <div class="d-flex flex-column">
                                <p class="text-white mb-2">
                                    Perlu Ukur - Timbang Barang
                                </p>
                                <router-link
                                    to="/tally/off-loading"
                                    class="d-flex align-items-center"
                                >
                                    <h5 class="text-white border-bottom me-2">
                                        {{ datas.need_to_scale }} Barang
                                    </h5>
                                    <img
                                        src="../../assets/arrow-dashboard.svg"
                                        alt="arrow dashboard"
                                        width="20"
                                        height="20"
                                    />
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 my-3">
                <div
                    class="box h-100 d-flex"
                    :class="{ 'box-shadow': hasDateRange }"
                >
                    <div
                        class="d-flex flex-column flex-lg-row justify-content-between align-items-center gap-3 w-100"
                    >
                        <div class="d-flex w-100">
                            <img
                                src="../../assets/pack-dashboard.svg"
                                alt="pack dashboard"
                                class="me-3"
                            />
                            <div class="d-flex flex-column">
                                <p class="mb-2">Total Barang</p>
                                <h5 class="fw-bold">
                                    {{ datas.item.total_item }} Koli
                                </h5>
                            </div>
                        </div>
                        <hr class="d-none d-md-block vertical-divider" />
                        <div class="d-flex w-100">
                            <img
                                src="../../assets/weight-dashboard.svg"
                                alt="weight dashboard"
                                class="me-3"
                            />
                            <div class="d-flex flex-column">
                                <p class="mb-2">Total Berat Barang</p>
                                <h5 class="fw-bold">
                                    {{ datas.item.total_weight }} Kg
                                </h5>
                            </div>
                        </div>
                        <hr class="d-none d-md-block vertical-divider" />
                        <div class="d-flex w-100">
                            <img
                                src="../../assets/cubic-dashboard.svg"
                                alt="cubic dashboard"
                                class="me-3"
                            />
                            <div class="d-flex flex-column">
                                <p class="mb-2">Total Kubikasi</p>
                                <h5 class="fw-bold">
                                    {{ datas.item.total_cubic }} M3
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 my-3">
                <div class="box h-100" :class="{ 'box-shadow': hasDateRange }">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex flex-column justify-content-center">
                            <p v-if="hasDateRange">Total Ukur-Timbang</p>
                            <p v-else>Total Ukur-Timbang Hari Ini</p>
                            <h5 class="text-black me-2 fw-bold mb-3">
                                {{
                                    datas.total_scale.pending +
                                    datas.total_scale.success
                                }}
                                Barang
                            </h5>
                            <div class="d-flex align-items-center mb-3">
                                <img
                                    src="../../assets/item-pending-dashboard.svg"
                                    alt="item pending dashboard"
                                    class="me-3"
                                />
                                <div class="d-flex flex-column">
                                    <p class="mb-0">
                                        {{ datas.total_scale.pending }} Barang
                                    </p>
                                    <p class="text-gray mb-0">Pending</p>
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <img
                                    src="../../assets/item-success-dashboard.svg"
                                    alt="item success dashboard"
                                    class="me-3"
                                />
                                <div class="d-flex flex-column">
                                    <p class="mb-0">
                                        {{ datas.total_scale.success }} Barang
                                    </p>
                                    <p class="text-gray mb-0">Telah Selesai</p>
                                </div>
                            </div>
                        </div>
                        <donut-chart
                            :data="pickupData"
                            customCenterTextLabel="Barang"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-5 my-3">
                <div
                    class="box h-100 d-flex flex-column justify-content-center"
                    :class="{ 'box-shadow': hasDateRange }"
                >
                    <div class="d-flex">
                        <img
                            src="../../assets/in-dashboard.svg"
                            alt="in dashboard"
                            class="me-3"
                        />
                        <div class="d-flex flex-column justify-content-center">
                            <p class="mb-2">Total Barang Diukur</p>
                            <h5 class="fw-bold mb-0">
                                {{ datas.calculate_count.total_calculate }}
                                Barang
                            </h5>
                        </div>
                    </div>
                    <hr />
                    <div class="d-flex">
                        <img
                            src="../../assets/out-dashboard.svg"
                            alt="out dashboard"
                            class="me-3"
                        />
                        <div class="d-flex flex-column justify-content-center">
                            <p class="mb-2">Total Barang Selesai Diklaim</p>
                            <h5 class="fw-bold mb-0">
                                {{ datas.calculate_count.total_claimed }} Barang
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 my-3">
                <div class="box h-100" :class="{ 'box-shadow': hasDateRange }">
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <p class="mb-0 fw-bold" v-if="!hasDateRange">
                            Daftar Driver - Off Loading Hari Ini
                        </p>
                        <p class="mb-0 fw-bold" v-else>
                            Daftar Driver - Off Loading
                        </p>
                        <router-link
                            v-if="!hasDateRange"
                            to="/tally/off-loading"
                            class="d-flex align-items-center"
                        >
                            <p class="mb-0 text-link me-2">Lihat Detail</p>
                            <img
                                src="../../assets/arrow-link-dashboard.svg"
                                alt="arrow link dashboard"
                            />
                        </router-link>
                    </div>
                    <div
                        class="table-responsive-custom table-scrollable mt-4"
                        v-if="datas.list_offloads.length !== 0"
                    >
                        <table class="table table-bordered">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">No</th>
                                    <th class="text-center" scope="col">
                                        Nama Driver
                                    </th>
                                    <th class="text-center" scope="col">
                                        Jumlah Tujuan
                                    </th>
                                    <th class="text-center" scope="col">
                                        Total Estimasi Berat Barang
                                    </th>
                                    <th class="text-center" scope="col">
                                        Status Ukur-Timbang
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(data, index) in datas.list_offloads"
                                    :key="index"
                                >
                                    <td class="text-center">{{ index + 1 }}</td>
                                    <td>
                                        {{ data.name }}
                                    </td>
                                    <td class="text-center">
                                        {{ data.total_delivery }}
                                    </td>
                                    <td class="text-center">
                                        {{ data.weight }} Kg
                                    </td>
                                    <td class="text-center">
                                        <span
                                            class="tag mb-0 pb-0 px-3 radius"
                                            v-bind:class="{
                                                'yellow-cro':
                                                    data.status === 'pending',
                                                'green-cro':
                                                    data.status === 'done',
                                            }"
                                        >
                                            <template
                                                v-if="data.status === 'done'"
                                            >
                                                Selesai
                                            </template>
                                            <template v-else>
                                                Pending
                                            </template>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else>
                        <div class="d-flex justify-content-center mt-5">
                            <img src="../../assets/empty.svg" alt="empty" />
                        </div>
                        <p class="text-gray text-center mt-5">
                            Daftar Driver - Off Loading Tidak Tersedia
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DonutChart from "../../components/DonutChart.vue";
import moment from "moment";
import Api from "../../api/Api";
import "moment/locale/id";
import DateRangePicker from "../../components/DateRangePicker.vue";

moment.locale("id");

export default {
    components: {
        DonutChart,
        DateRangePicker,
    },
    data() {
        return {
            dateRange: {
                from: null,
                to: null,
            },
            date: moment(),
            datas: {
                need_to_scale: null,
                item: {
                    total_item: null,
                    total_weight: null,
                    total_cubic: null,
                },
                total_scale: {
                    pending: null,
                    success: null,
                },
                calculate_count: {
                    total_calculate: null,
                    total_claimed: null,
                },
                list_offloads: [],
            },
        };
    },
    computed: {
        hasDateRange() {
            return this.dateRange && this.dateRange.from && this.dateRange.to;
        },
        pickupData() {
            return [
                {
                    label: "Belum Diukur - Timbang",
                    value: this.datas.total_scale.pending || 0,
                    color: "#FF8600",
                },
                {
                    label: "Telah Selesai Diukur - Timbang",
                    value: this.datas.total_scale.success || 0,
                    color: "#40BF76",
                },
            ];
        },
    },
    created() {
        this.getDatas();
        const userFromLocalStorage = localStorage.getItem("user");

        if (userFromLocalStorage) {
            this.user = JSON.parse(userFromLocalStorage);
        }
    },
    methods: {
        goToListDriver(item) {
            this.$router.push({
                name: "tally-driver",
                query: {
                    driver_fleet_id: item.driver_fleet_id,
                    date_offload: item.date_offload,
                    driver_name: item.driver_name,
                },
            });
        },
        formatDateRange(fromDate, toDate) {
            if (!fromDate || !toDate) return "";

            const from = moment(fromDate);
            const to = moment(toDate);

            return `${from.format("D")} ${from.format(
                "MMMM YYYY"
            )} - ${to.format("D")} ${to.format("MMMM YYYY")}`;
        },
        filterData(dateRange) {
            this.dateRange = dateRange;
            this.getDatas();
        },
        resetData() {
            this.dateRange.from = null;
            this.dateRange.to = null;
            this.getDatas();
        },
        getDatas() {
            this.isPageLoading = true;

            let payload;

            if (this.dateRange && this.dateRange.from && this.dateRange.to) {
                payload = {
                    start_date: moment(this.dateRange.from).format(
                        "YYYY-MM-DD"
                    ),
                    end_date: moment(this.dateRange.to).format("YYYY-MM-DD"),
                };
            } else {
                payload = {
                    start_date: this.date.format("YYYY-MM-DD"),
                    end_date: this.date.format("YYYY-MM-DD"),
                };
            }

            Api.get(`${process.env.VUE_APP_SERVICE_URL}/dashboard/tally`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.datas = res.data.data;
                    this.isPageLoading = false;
                })
                .catch((err) => {
                    this.isPageLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.box-shadow {
    box-shadow: 1px 1px 10px 0px rgba(18, 145, 255, 0.3);
}
.table-responsive-custom {
    &.table-scrollable {
        max-height: 410px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th {
            border: 1px solid #c1cdd7;
            background-color: #f3f5f7;
        }

        td {
            border: 1px solid #c1cdd7;
            background-color: #f9fafb;
        }

        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
.vertical-divider {
    height: 100%;
    border: 1px solid #c1cdd7;
    margin: 0;
}
@media (max-width: 800px) {
    .d-flex.w-100:not(:last-child) {
        border-bottom: 1px solid #c1cdd7;
        padding-bottom: 1rem;
    }
}
hr {
    border-top: 1px solid #c1cdd7 !important;
}
hr.vertical-divider {
    height: 100%;
    border: 1px solid #c1cdd7;
}
.text-black {
    color: #212529 !important;
}
.col-md-5.col-md-5-custom {
    width: 36.666667% !important;
}
.col-md-7.col-md-7-custom {
    width: 63.333333% !important;
}
@media only screen and (max-width: 800px) {
    .col-md-5.col-md-5-custom {
        width: 100% !important;
    }
    .col-md-7.col-md-7-custom {
        width: 100% !important;
    }
}
.dashboard-container {
    padding: 48px 20px 40px 20px;
}

.box-orange {
    border: 1px solid #ffcf99;
    background-color: #fff2e5;
    border-radius: 16px;
    padding: 15px 15px;
}
.box-danger {
    background-color: #ff8600;
    border-radius: 16px;
    padding: 15px 15px;
}
</style>
