<template>
    <div class="padding-container">
        <div class="box">
            <h5 class="fw-bold">
                Daftar Penugasan Driver -
                {{ moment(date).format("DD MMMM YYYY") }}
            </h5>
            <div class="d-flex align-items-center no-flex mt-4">
                <div class="me-4 m-mx-0 m-mt-2">
                    <div class="position-relative">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search-leads border-search"
                                placeholder="Cari nama driver"
                                v-model="search"
                            />
                            <button
                                class="btn btn-primary px-4"
                                type="button"
                                @click="searchData()"
                            >
                                Cari
                            </button>
                        </div>
                        <span class="search-icon left">
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                </div>
                <div class="m-mx-0 m-mt-2 me-3">
                    <button
                        class="btn btn-outline-black btn-filter px-4"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                    <div
                        class="dropdown-menu compare-data mt-3 show"
                        v-if="isFilter"
                    >
                        <div>
                            <date-picker
                                v-model="date"
                                type="date"
                                class="compare-data"
                                format="YYYY-MM"
                                inline
                            >
                            </date-picker>
                        </div>
                        <div class="d-flex p-3 pb-2">
                            <div class="ms-auto">
                                <div class="d-flex">
                                    <div class="me-2">
                                        <button
                                            class="btn btn-outline-yellow px-4 btn-sm"
                                            @click="resetFilter()"
                                            :disabled="date == ''"
                                        >
                                            Reset
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            class="btn btn-green-second px-4 btn-sm"
                                            :disabled="date == ''"
                                            @click="filter()"
                                        >
                                            Simpan
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center mt-4">
                <div>
                    Menampilkan
                    <span class="fw-bold">{{ lists.length }}</span>
                    Data
                </div>
            </div>
            <div class="mt-4" v-if="is_my_list_ready">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Nama Driver
                                </th>
                                <th class="text-center" scope="col">
                                    Tanggal Penugasan Driver
                                </th>
                                <th class="text-center" scope="col">
                                    Dalam Proses
                                </th>
                                <th class="text-center" scope="col">Selesai</th>
                                <th class="text-center" scope="col">
                                    Jumlah Tujuan
                                </th>
                                <th class="text-center" scope="col">
                                    Persentase
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in lists" :key="index">
                                <td class="text-center">
                                    {{ index + 1 }}
                                </td>
                                <td class="">
                                    <a
                                        href="javascript:void(0)"
                                        @click="goTodetail(item)"
                                    >
                                        {{
                                            item.driver_name
                                                ? item.driver_name
                                                : "-"
                                        }}
                                    </a>
                                    <!-- <a
                                        :href="
                                            `/assignment/detail?id=` +
                                            item.driver_fleet_id +
                                            `&date=` +
                                            moment(date).format(`DD-MMMM-YYYY`)
                                        "
                                        class="text-link"
                                    >
                                        {{
                                            item.driver_name
                                                ? item.driver_name
                                                : "-"
                                        }}
                                    </a> -->
                                </td>
                                <td class="text-center">
                                    {{
                                        item.assigned_driver
                                            ? item.assigned_driver
                                            : "-"
                                    }}
                                </td>
                                <td class="text-center">
                                    <a
                                        href="javascript:void(0)"
                                        class="dropdown-toggle ms-2"
                                        id="statusRefund"
                                        data-bs-toggle="dropdown"
                                        data-bs-auto-close="true"
                                        aria-expanded="false"
                                    >
                                        <span
                                            class="tag yellow-cro radius mb-0"
                                        >
                                            {{ item.process }}
                                        </span>
                                    </a>
                                    <ul
                                        class="dropdown-menu"
                                        aria-labelledby="statusRefund"
                                    >
                                        <li>
                                            <div class="dropdown-item">
                                                <div
                                                    class="d-flex align-items-center"
                                                >
                                                    <div class="w-floating">
                                                        Penjemputan
                                                    </div>
                                                    :
                                                    <small
                                                        class="tag yellow-cro mb-0 pb-0 radius ms-3"
                                                    >
                                                        <div
                                                            class="d-flex align-items-center"
                                                        >
                                                            <img
                                                                src="../../assets/car.png"
                                                                class="me-1"
                                                                width="14"
                                                                alt=""
                                                            />
                                                            {{
                                                                item.process_pickup
                                                            }}
                                                        </div>
                                                    </small>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="dropdown-item">
                                                <div
                                                    class="d-flex align-items-center"
                                                >
                                                    <div class="w-floating">
                                                        Dooring
                                                    </div>
                                                    :
                                                    <small
                                                        class="tag yellow-cro mb-0 pb-0 radius ms-3"
                                                    >
                                                        <div
                                                            class="d-flex align-items-center"
                                                        >
                                                            <img
                                                                src="../../assets/door.png"
                                                                class="me-1"
                                                                width="12"
                                                                alt=""
                                                            />
                                                            {{
                                                                item.process_dooring
                                                            }}
                                                        </div>
                                                    </small>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                                <td class="text-center">
                                    <a
                                        href="javascript:void(0)"
                                        class="dropdown-toggle ms-2"
                                        id="statusRefund"
                                        data-bs-toggle="dropdown"
                                        data-bs-auto-close="true"
                                        aria-expanded="false"
                                    >
                                        <span class="tag green-cro radius mb-0">
                                            {{ item.completed }}
                                        </span>
                                    </a>
                                    <ul
                                        class="dropdown-menu"
                                        aria-labelledby="statusRefund"
                                    >
                                        <li>
                                            <div class="dropdown-item">
                                                <div
                                                    class="d-flex align-items-center"
                                                >
                                                    <div class="w-floating">
                                                        Penjemputan
                                                    </div>
                                                    :
                                                    <small
                                                        class="tag green-cro mb-0 pb-0 radius ms-3"
                                                    >
                                                        <div
                                                            class="d-flex align-items-center"
                                                        >
                                                            <img
                                                                src="../../assets/car-green.png"
                                                                class="me-1"
                                                                width="14"
                                                                alt=""
                                                            />
                                                            {{
                                                                item.completed_pickup
                                                            }}
                                                        </div>
                                                    </small>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="dropdown-item">
                                                <div
                                                    class="d-flex align-items-center"
                                                >
                                                    <div class="w-floating">
                                                        Dooring
                                                    </div>
                                                    :
                                                    <small
                                                        class="tag green-cro mb-0 pb-0 radius ms-3"
                                                    >
                                                        <div
                                                            class="d-flex align-items-center"
                                                        >
                                                            <img
                                                                src="../../assets/door-green.png"
                                                                class="me-1"
                                                                width="12"
                                                                alt=""
                                                            />
                                                            {{
                                                                item.completed_dooring
                                                            }}
                                                        </div>
                                                    </small>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                                <td class="text-center">
                                    {{ item.total ? item.total : "0" }}
                                </td>
                                <td class="text-center">
                                    {{ item.percentage }}%
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="lists.length == 0" class="text-center mt-section">
                    <img
                        src="../../assets/no-data-green.png"
                        width="100"
                        alt=""
                    />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada daftar penugasan driver
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import ListLoader from "../../components/ListLoader.vue";

export default {
    components: {
        DatePicker,
        ListLoader,
    },
    name: "AssignmentList",
    data() {
        return {
            moment: moment,
            isFilter: false,
            search: "",
            lists: [],
            is_my_list_ready: false,
            date: new Date(new Date()),
        };
    },
    created() {
        this.getLists();
    },
    methods: {
        goTodetail(item) {
            sessionStorage.setItem("dataParams", JSON.stringify(item));
            this.$router.push({ name: "assignment-detail" });
        },
        resetFilter() {
            this.isFilter = false;
            this.date = new Date(new Date());
            this.getLists();
        },
        filter() {
            this.isFilter = false;
            this.getLists();
        },
        getLists() {
            let payload = {
                search: this.search,
            };
            if (this.date) {
                payload.date = moment(this.date).format("DD/MM/YYYY");
            }
            this.is_my_list_ready = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/pickup/assigned`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.lists = res.data.data;
                    this.is_my_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_my_list_ready = false;
                });
        },
        searchData() {
            this.getLists();
            this.currentPage = 0;
        },
    },
    computed: {},
};
</script>
<style lang="scss" scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.dropdown-item:focus,
.dropdown-item:hover {
    background: unset;
    color: #000;
}
.w-floating {
    width: 100px;
}
</style>
