<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="$router.go(-1)"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h5 class="fw-bold mb-0">Hasil Lost</h5>
            </div>
            <div class="row mt-4">
                <div class="col-lg-12 mt-3" v-show="isRole(managerRoles)">
                    <label class="fw-bold mb-1"
                        >CRM Specialist
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="specialist"
                        :options="specialists"
                        label="name"
                        track-by="id"
                        placeholder="Pilih CRM specialist"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-12 mt-3">
                    <label class="fw-bold mb-1"
                        >Keterangan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <textarea
                        cols="30"
                        rows="3"
                        class="form-control"
                        placeholder="Masukkan deskripsi"
                        v-model="description"
                    ></textarea>
                </div>
                <div class="col-lg-12 mt-4">
                    <div class="d-flex">
                        <div class="ms-auto">
                            <button
                                @click="$router.go(-1)"
                                href="javascript:void(0)"
                                class="btn btn-outline-yellow btn-lg px-5 me-3 m-w-100"
                            >
                                Batal
                            </button>
                            <button
                                class="btn btn-primary btn-lg px-5 me-3 m-mt-1 m-w-100"
                                @click="modalSuccessConfirmation = true"
                            >
                                Simpan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessConfirmation"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessConfirmation">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Data Lost Akan Ditambahkan
                    </h5>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalSuccessConfirmation = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            @click="storeData()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_store"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_store"
                            ></span>
                            Simpan
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Data Lost Berhasil Ditambahkan
                    </h5>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/lost-order"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Ok
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
    },
    props: ["roles"],
    name: "lostCreate",
    data() {
        return {
            managerRoles: ["crm-manager"],
            specialist: null,
            specialists: [],
            description: "",
            modalSuccessConfirmation: false,
            modalSuccess: false,
            is_store: false,
        };
    },
    created() {
        this.getSpecialists();
    },
    methods: {
        getSpecialists() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/user`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.specialists = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        storeData() {
            this.is_store = true;

            var data = {
                followup_id: this.$route.params.id,
                description: this.description,
                specialist_id: this.specialist?.id,
            };

            Api.post(`${process.env.VUE_APP_SERVICE_URL}/result/lost`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.is_store = false;
                    this.modalSuccessConfirmation = false;
                    this.modalSuccess = true;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_store = false;
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                    this.modalSuccessConfirmation = false;
                });
        },
    },
};
</script>

<style scoped>
.text-main-color {
    color: #24516a !important;
}
.text-gray-dashboard {
    color: #61616a;
}
</style>
