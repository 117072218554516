<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="box-manifest">
            <div class="mb-3">
                <h5 class="fw-bold">Daftar Klaim Pesanan</h5>
            </div>
            <div class="row gy-3 mt-3">
                <div class="col-12 col-lg-5">
                    <div class="position-relative">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search border-search"
                                placeholder="Cari nama customer"
                                v-model="search"
                            />
                            <button
                                class="btn btn-primary px-4"
                                type="button"
                                @click="filterData()"
                            >
                                Cari
                            </button>
                        </div>
                        <span
                            class="search-icon left position-absolute top-50 start-0 translate-middle-y ms-3"
                        >
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                </div>
                <div class="col-12 col-lg-3 col-xl-2">
                    <button
                        class="btn btn-outline-yellow btn-filter transparent w-100"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-lg-4 my-2">
                        <label class="fw-bold mb-1">Kota Asal</label>
                        <multiselect
                            v-model="origin"
                            :options="origins"
                            label="name"
                            track-by="id"
                            placeholder="Cari dan pilih kota asal"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-lg-8 mb-2">
                        <label class="fw-bold mb-1">Kota Tujuan</label>
                        <div class="row">
                            <div class="col-lg-6 my-2">
                                <multiselect
                                    v-model="destination"
                                    :options="destinations"
                                    label="name"
                                    track-by="id"
                                    placeholder="Cari dan pilih kota tujuan"
                                    class="multi-form-custom"
                                    :show-labels="false"
                                >
                                </multiselect>
                            </div>
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-6 my-2">
                                        <button
                                            class="btn btn-outline-yellow px-5 w-100"
                                            @click="clearFilter()"
                                            :disabled="
                                                origin == null &&
                                                destination == null
                                            "
                                        >
                                            Clear
                                        </button>
                                    </div>
                                    <div class="col-lg-6 my-2">
                                        <button
                                            class="btn btn-green px-5 w-100"
                                            :disabled="
                                                origin == null &&
                                                destination == null
                                            "
                                            @click="filterData()"
                                        >
                                            Filter
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gy-3 align-items-center mt-3">
                <div class="col-12 col-lg-6">
                    <div class="text-center text-lg-start">
                        Menampilkan
                        <span class="fw-bold"
                            >{{ pageStart }}-{{ pageEnd }}</span
                        >
                        Data dari
                        <span class="fw-bold">{{ lists.total_data }}</span>
                        Data
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div
                        class="d-flex flex-column flex-lg-row align-items-center justify-content-lg-end"
                    >
                        <div class="mb-2 mb-lg-0 me-lg-2">
                            Baris per halaman
                        </div>
                        <select
                            class="form-control form-select w-select outline-yellow"
                            style="max-width: 100px"
                            v-model="limit"
                            @change="onLimitChange"
                        >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="list_loading">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Nama Customer
                                </th>
                                <th class="text-center" scope="col">Deal ID</th>
                                <th class="text-center" scope="col">
                                    Jenis Pesanan
                                </th>
                                <th class="text-center" scope="col">
                                    Kota Asal
                                </th>
                                <th class="text-center" scope="col">
                                    Kota Tujuan
                                </th>
                                <th class="text-center" scope="col">Catatan</th>
                                <th class="text-center" scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in lists.list_data"
                                :key="index"
                            >
                                <td class="text-center">
                                    {{ index + 1 }}
                                </td>
                                <td>
                                    {{
                                        item.customer_name
                                            ? item.customer_name
                                            : "-"
                                    }}
                                </td>
                                <td>
                                    {{ item.deal_code ? item.deal_code : "-" }}
                                </td>
                                <td>
                                    <span
                                        class="mb-0 pb-0 capitalize d-flex justify-content-center rounded-pill"
                                        :class="{
                                            'badge-walk-in':
                                                item.order_type === 'walk_in',
                                            'badge-regular':
                                                item.order_type === 'regular',
                                        }"
                                    >
                                        {{
                                            item.order_type === "regular"
                                                ? "Regular"
                                                : item.order_type === "walk_in"
                                                ? "Walk In"
                                                : "-"
                                        }}
                                    </span>
                                </td>
                                <td>
                                    {{
                                        item.origin_regency
                                            ? item.origin_regency
                                            : "-"
                                    }}
                                </td>
                                <td>
                                    {{
                                        item.dest_regency
                                            ? item.dest_regency
                                            : "-"
                                    }}
                                </td>
                                <td>
                                    {{ item.note ? item.note : "-" }}
                                </td>
                                <td class="text-center" style="width: 100px">
                                    <button
                                        class="btn btn-green btn-sm w-100"
                                        @click="goToClaim(item)"
                                        v-if="!item.is_claim"
                                    >
                                        Claim Pesanan
                                        <i
                                            class="pi pi-arrow-up-right ms-1 size-12"
                                        ></i>
                                    </button>
                                    <button
                                        class="btn btn-primary btn-sm w-100"
                                        @click="goToDetail(item)"
                                        v-if="item.is_claim"
                                    >
                                        Lihat Detail
                                        <i class="pi pi-eye ms-1 size-12"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    v-if="lists.list_data.length == 0"
                    class="text-center mt-5"
                >
                    <img src="../assets/no-data-green.png" width="100" alt="" />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada daftar pesanan
                    </div>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <div class="d-flex align-items-center">
                        <b-pagination-nav
                            v-if="lists.list_data.length > 0"
                            v-model="currentPage"
                            :number-of-pages="totalPage"
                            base-url="#"
                            first-number
                            align="right"
                            @input="getLists()"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Api from "../api/Api";
import ListLoader from "../components/ListLoader.vue";

export default {
    components: {
        Multiselect,
        ListLoader,
    },
    name: "OrderProcess",
    data() {
        return {
            isFilter: false,
            search: "",
            limit: 5,
            pageStart: 0,
            pageEnd: 0,
            totalPage: 0,
            currentPage: 1,
            lists: {
                list_data: [],
            },
            origin: null,
            origins: [],
            destination: null,
            destinations: [],
            list_loading: false,
        };
    },
    computed: {
        displayedRowsStart() {
            return (this.currentPage - 1) * this.limit + 1;
        },
        displayedRowsEnd() {
            return Math.min(
                this.currentPage * this.limit,
                this.lists.total_data
            );
        },
    },
    created() {
        this.getLists();
        this.getRegency();
    },
    methods: {
        onLimitChange() {
            this.currentPage = 1;
            this.getLists();
        },
        goToClaim(item) {
            this.$router.push({
                name: "order-process-create",
                query: {
                    claim_id: item.id,
                },
            });
        },
        goToDetail(item) {
            this.$router.push({
                name: "order-process-detail",
                query: {
                    order_id: item.order_id,
                },
            });
        },
        clearFilter() {
            this.origin = null;
            this.destination = null;
            this.getLists();
        },
        filterData() {
            this.getLists();
        },
        getLists() {
            let payload = {
                search: this.search,
                limit: this.limit,
                page: this.currentPage,
            };
            if (this.origin) {
                payload.origin_regency_id = this.origin?.id;
            }
            if (this.destination) {
                payload.destination_regency_id = this.destination?.id;
            }
            this.list_loading = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/order/claims`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.lists = res.data.data;
                    this.totalPage = res.data.data.total_page;
                    const totalItems = this.lists.list_data.length;
                    const totalData = res.data.data.total_data;
                    this.pageStart =
                        totalItems === 0
                            ? 0
                            : (this.currentPage - 1) * this.limit + 1;
                    this.pageEnd = Math.min(
                        Number(this.pageStart) + Number(this.limit) - 1,
                        totalData
                    );
                    this.list_loading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.list_loading = false;
                });
        },
        getRegency() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.origins = data;
                    this.destinations = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
.badge-walk-in {
    background-color: #e0e6eb !important;
    border: 1px solid #c2cdd6 !important;
    color: #3e4e5c !important;
}
.badge-regular {
    background-color: #cce7ff !important;
    border: 1px solid #99d0ff !important;
    color: #005299 !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.table-responsive-custom {
    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th,
        td {
            border: 1px solid #c1cdd7;
        }
        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
td {
    background-color: transparent !important;
}
th {
    background-color: #f3f5f7 !important;
}
::v-deep .page-link {
    background-color: #f3f5f7 !important;
}
::v-deep .page-item.active .page-link {
    background-color: #1291ff !important;
}
.outline-yellow {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
.outline-yellow:focus {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
.btn-filter.transparent {
    background: transparent !important;
}
</style>
